export interface Attributes {
  width?: string | number;
  height?: string | number;
}

export enum AttachmentType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  OTHER = "OTHER",
}
export enum AttachmentSubType {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
}

export interface Attachment<TData = any> extends Attributes {
  id?: number;
  file_name?: string;
  timestamp?: string;
  edited_timestamp?: string;
  name?: string;
  url?: string;
  mime_type?: string;
  size?: string;
  type?: AttachmentType;
  subtype?: AttachmentSubType;
  data?: TData;
}
