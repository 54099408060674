import { useSetState } from "react-use";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import defaults from "app-defaults";

export interface UsePaginationResult {
  page: number;
  perPage: number;
  next: () => void;
  prev: () => void;
  setPage: (p: number | undefined) => void;
  setPerPage: (l: number | undefined) => void;
}

export interface UsePaginationArgs {
  defaultPerPage?: number;
  defaultPage?: number;
}

export function useQueryPagination({
  defaultPerPage = defaults.pagination.perPage,
  defaultPage = 1,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, per_page }, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, defaultPage),
    per_page: withDefault(NumberParam, defaultPerPage),
  });

  return {
    page: page,
    perPage: per_page,
    next: () => (page ? setQueryParams({ page: page + 1 }) : undefined),
    prev: () => (page ? setQueryParams({ page: page - 1 }) : undefined),
    setPage: (p) => setQueryParams({ page: p }),
    setPerPage: (pp) => {
      setQueryParams({ per_page: pp, page: 1 });
    },
  };
}

export function usePagination({
  defaultPerPage = defaults.pagination.perPage,
  defaultPage = 1,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, per_page }, setState] = useSetState({
    page: defaultPage,
    per_page: defaultPerPage,
  });

  return {
    page: page,
    perPage: per_page,
    next: () => (page ? setState({ page: page + 1 }) : undefined),
    prev: () => (page ? setState({ page: page - 1 }) : undefined),
    setPage: (p) => setState({ page: p }),
    setPerPage: (l) => {
      setState({ per_page: l, page: 1 });
    },
  };
}
