import React from "react";
import { useQuery } from "react-query";
import * as ReactDOMServer from "react-dom/server";

import * as dateFNS from "date-fns";

import { Flex, WhiteSpace, QueryTable, Card, Icon, Collapse, Grid, Button } from "components";
import { apiDateFormat, makeBEM, printElement, withCurrency } from "utils";
import api, { querykeys } from "api";
import models from "models";
import { useScreenBreakPoint } from "hooks";

import { ordersTableColumns } from "../config";

import { RawMonthlyInvoice } from "./RawMonthlyInvoice";

export interface MonthlyInvoiceItemProps {
  // maxItems: number | null;
  data: models.MonthlyInvoice;
  collapsed: boolean;
  onHeaderClick: React.HTMLProps<HTMLDivElement>["onClick"];
  onRowClick: React.Dispatch<models.Order>;
}

const c = makeBEM("monthly-invoice-item");

export const MonthlyInvoiceItem = ({
  // maxItems,
  data,
  onHeaderClick,
  onRowClick,
  collapsed,
}: MonthlyInvoiceItemProps) => {
  const date = new Date(data.year || 0, (data.month || 1) - 1);

  const queryParams = {
    date_from: dateFNS.format(date, apiDateFormat),
    date_to: dateFNS.format(dateFNS.endOfMonth(date), apiDateFormat),
  };
  const query = useQuery(querykeys.orders.many(queryParams), () => api.orders.getList(queryParams), {
    enabled: !collapsed,
  });

  const tableColumns = React.useMemo(() => ordersTableColumns({ small: true }), []);

  const print = () => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <RawMonthlyInvoice data={data} items={query.data?.results || []} />
    );
    printElement(htmlString);
  };

  const onTablet = useScreenBreakPoint("tablet");

  return (
    <>
      <Card>
        <Flex
          className={c("collapse-header")}
          align="center"
          justify="space-between"
          gap="1rem"
          onClick={onHeaderClick}
        >
          <h3 className={c("collapse-date")}>{dateFNS.format(date, "MMMM yyyy")}</h3>
          <Grid className="flex-1" cols={onTablet ? "1fr" : "repeat(3, 1fr)"} content="right" gap="0.5rem" items="end">
            <div>
              <b>Gifts sent: </b>
              <span>{data.gifts_count}</span>
            </div>
            <div>
              <b>Total fee: </b>
              <span>{withCurrency(data.total_fee)}</span>
            </div>
            <div>
              <b>Total spent: </b>
              <span>{withCurrency(data.total_paid)}</span>
            </div>
          </Grid>
          <Icon size="1em" type="droparrow" style={{ transform: collapsed ? "rotate(180deg)" : undefined }} />
        </Flex>
        <Collapse collapsed={collapsed}>
          <WhiteSpace v="1rem" />
          <QueryTable
            query={query}
            columns={tableColumns}
            variants={["highlight-hover", "dense"]}
            rowKey="operation_id"
            onRow={(record) => ({ onClick: () => onRowClick(record) })}
            scroll={{ y: 400, x: true }}
          />
          <WhiteSpace v="1rem" />
          <Flex justify={"end"}>
            <Button disabled={!query.isSuccess} onClick={() => query.isSuccess && print()}>
              Print invoice
            </Button>
          </Flex>
        </Collapse>
      </Card>
    </>
  );
};
