import { VerticalTabsPanelProps } from "components/VerticalTabs/VerticalTabsPanel";
import { AccountForm, PaymentMethodTab, BusinessForm } from "features/settings/components";

export const settingsTabPanels: VerticalTabsPanelProps[] = [
  {
    tabKey: "account",
    title: "Account Settings",
    subtitle: "Setup Your Account Details",
    icon: "person",
  },
  {
    tabKey: "business",
    title: "Business Details",
    subtitle: "Enter Your Business Details",
    icon: "nav",
  },
  {
    tabKey: "payment",
    title: "Add a payment method",
    subtitle: "Dont worry! Nothing will be taken until you send a prsnt!",
    icon: "credit-card",
  },
];

export const settingsTabContents = [
  {
    tabKey: "account",
    content: <AccountForm />,
  },
  {
    tabKey: "business",
    content: <BusinessForm />,
  },
  {
    tabKey: "payment",
    content: <PaymentMethodTab />,
  },
];
