import { Card, WhiteSpace } from "components";
import models from "models";
import { makeBEM } from "utils";

import humanImageSrc from "features/send-gift/assets/human-sitting.svg";
import { useSendGiftContext } from "features/send-gift/hooks";

export interface UserTypeChoiceProps {
  setInChoice: React.Dispatch<boolean>;
}

const c = makeBEM("send-gift-choice");

export const UserTypeChoice = ({ setInChoice }: UserTypeChoiceProps) => {
  const { setContextValue } = useSendGiftContext();

  const cardClickHandler = (name: models.UserContactType) => () => {
    setContextValue({ contactType: name });
    if (name === "clients") setContextValue({ sendMethodType: models.SendMethodType.email });
    setInChoice(false);
  };

  return (
    <>
      <div className={c("container")}>
        <h1 className="text-center">Who are you sending a prsnt to?</h1>
        <WhiteSpace v="2rem" />
        <div className={c("grid")}>
          <Card onClick={cardClickHandler("employees")} className={c("card", ["employees"])}>
            <WhiteSpace v="2rem" />
            <h2>Employees</h2>

            <img className={c("card-image")} src={humanImageSrc} alt="" />
          </Card>
          <Card onClick={cardClickHandler("clients")} className={c("card", ["clients"])}>
            <WhiteSpace v="2rem" />
            <h2>Clients or customers</h2>

            <img className={c("card-image")} src={humanImageSrc} alt="" />
          </Card>
        </div>
      </div>
    </>
  );
};
