import { SelectCheckboxHeader, CheckboxHeaderProps, renderSelectCheckbox, renderTitleSubtitle } from "components";
import { UseSetActions } from "hooks";
import models from "models";
import { TableColumnBuilder, withCurrency } from "utils";

interface SendGiftProductsTableColumnsArgs extends Pick<UseSetActions<any>, "has"> {}

export const sendGiftProductsTableColumns = ({ has }: SendGiftProductsTableColumnsArgs) =>
  new TableColumnBuilder<models.Product>()
    // .add("", ["featured_image", "url"], renderCheckImage(has, "Product", 80, 80))
    .add("Name", "title")
    .add("Description", "description")
    .add("Brand", ["brand", "title"])
    .add("Value", "price", withCurrency)
    .add("Price", "cost", withCurrency)
    .getColumns();

interface RecipientsTableColumnsArgs extends CheckboxHeaderProps, Pick<UseSetActions<any>, "has"> {
  groupName: string;
}

export const recipientsTableColumns = ({ groupName, has, ...checkHeaderProps }: RecipientsTableColumnsArgs) =>
  new TableColumnBuilder<models.UserContact>()
    .add(<SelectCheckboxHeader {...checkHeaderProps} />, "", renderSelectCheckbox(has), { width: 10 })
    .add("Name", "", (_, user) => `${user.first_name || ""} ${user.last_name || ""}`)
    .add("Email", "email")
    .add("Phone", "phone")
    .add(groupName, "group", renderTitleSubtitle("name", "description"))
    .getColumns();
