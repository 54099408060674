import { ProductSendConfigPost } from "./products";
import { SendMethodType } from "./send-gift";

export interface PaymentMethod {
  id?: string;
  type?: PaymentMethodType;
  default?: boolean;
  data?: PaymentMethodCardData;
  customer_id?: string;
}

export enum PaymentMethodType {
  card = "card",
}

export interface PaymentMethodCardData {
  brand?: PaymentMethodBrand | (string & {});
  country?: string | null;
  exp_month?: number;
  exp_year?: number;
  fingerprint?: string | null;
  funding?: string;
  last4?: string;

  // RECYCLE
  // generated_from: null;
  // networks: {
  //   available: string[];
  //   // preferred: null;
  // };
  // three_d_secure_usage: {
  //   supported: true;
  // };
  // wallet: null;
}

export enum PaymentMethodBrand {
  visa = "visa",
  mastercard = "mastercard",
  amex = "amex",
  discover = "discover",
  diners = "diners",
  jcb = "jcb",
  unionpay = "unionpay",
}
// amex, diners, discover, jcb, mastercard, unionpay, visa

export interface SetupGiftPayload {
  products: ProductSendConfigPost[];
  comment?: string;
  video?: number;
  contacts: number[];
  payment_method: string;
  notification_method?: SendMethodType;
  product_characteristic_choices?: number[];
}

export interface SetupGiftResponse {
  success?: boolean;
  payment_intent?: {
    id?: string;
    client_secret?: string;
  };
  public_key?: string;
}

export interface PaymentIntentSetupResponse {
  client_secret?: string;
  id?: string;
}
