import React from "react";
import { useUpdateEffect } from "react-use";
import { useQuery } from "react-query";

import models from "models";
import { useHashSet, useModalState, usePagination } from "hooks";
import { Button, Collapse, Container, Flex, QueryTable, SearchInput, WhiteSpace } from "components";
import { capitalized } from "utils";
import { atoms } from "atoms";

import { recipientsTableColumns } from "features/send-gift/config";
import { TableCardHeader } from "features/common/components";
import { useSendGiftContext } from "features/send-gift/hooks";
import { usePageTemplate } from "features/users/hooks";
import { AddUserModal, UploadCSVModal, GroupSelect } from "features/users/components";

import { StepContext } from "../contexts";
import { QuickSendForm } from "./QuickSendForm";

export interface RecipientsTableProps {}

export const RecipientsTable = (_props: RecipientsTableProps) => {
  const { selectedUsers, contactType, quickSendContact, setContextValue } = useSendGiftContext();
  const { setCanGoNext } = React.useContext(StepContext);
  const [selected, selectedActions] = useHashSet<number, models.UserContact>((u) => u.id || 0);

  const template = usePageTemplate();

  const addModal = useModalState(atoms.add);
  const csvModal = useModalState(atoms.csv);

  const [quickSendHasValues, setQuickSendHasValues] = React.useState(false);

  React.useEffect(() => {
    setContextValue((prev) => ({
      selectedUsers: { ...prev.selectedUsers, [template.contactType]: selected },
    }));
  }, [template.contactType, selected, setContextValue]);

  React.useEffect(() => {
    if (!selectedUsers[template.contactType].every(selectedActions.has)) {
      selectedActions.addMany(...selectedUsers[template.contactType]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.contactType, selectedUsers]);

  React.useEffect(() => {
    setCanGoNext(!!selectedUsers[contactType].length);
  }, [contactType, selectedUsers, setCanGoNext]);

  const [search, setSearch] = React.useState("");
  const pagination = usePagination();
  const [group, setGroup] = React.useState<string>();

  const queryParams = {
    page: pagination.page,
    per_page: pagination.perPage,
    group,
    search,
  };

  useUpdateEffect(() => pagination.setPage(1), [search]);

  const query = useQuery(template.querykeys.users.many(queryParams), () => template.usersApi.get(queryParams));
  const users = query.data?.results || [];

  const areAllSelected = users.every(selectedActions.has);
  const hasSomeSelected = users.some(selectedActions.has);
  const selectAll = () => selectedActions.addMany(...users);
  const removeAll = () => selectedActions.removeMany(...users);

  const tableColumns = recipientsTableColumns({
    groupName: capitalized(template.groupName.singular),
    areAllSelected,
    hasSomeSelected,
    ...selectedActions,
    selectAll,
    removeAll,
  });

  return (
    <>
      <Collapse time={250} collapsed={!!selectedUsers[contactType].length}>
        <QuickSendForm onHasValuesChange={(v) => v !== quickSendHasValues && setQuickSendHasValues(v)} />
        <WhiteSpace v="2rem" />
      </Collapse>
      <Collapse time={500} collapsed={quickSendHasValues || !!quickSendContact}>
        <Flex justify="end">
          <Button bordered onClick={addModal.open}>
            Add database
          </Button>
        </Flex>
        <WhiteSpace v="2.5rem" />

        <TableCardHeader
          count={query.data?.count}
          title={capitalized(template.name.plural)}
          filters={(isSmallScreen) => (
            <>
              <SearchInput value={search} onChange={setSearch} />

              <Container size={!isSmallScreen ? 200 : undefined} fixed={!isSmallScreen}>
                <GroupSelect
                  isClearable
                  placeholder={capitalized(template.groupName.singular)}
                  value={group}
                  onChange={(v) => (v === undefined || typeof v === "string") && setGroup(v)}
                />
              </Container>
            </>
          )}
        />
        <WhiteSpace v="1rem" />

        <QueryTable
          pagination={pagination}
          query={query}
          columns={tableColumns}
          variants={["highlight-hover"]}
          onRow={(user) => ({
            onClick: () => selectedActions.toggle(user),
          })}
        />
        <AddUserModal open={addModal.isOpen} onClose={addModal.close} onCsvClick={csvModal.open} />
        <UploadCSVModal open={csvModal.isOpen} onClose={csvModal.close} />
      </Collapse>
    </>
  );
};
