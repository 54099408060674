import React from "react";

import { FormInstance } from "rc-field-form";

import models from "models";
import { noop } from "utils";

interface AutoGiftEditContextState {
  contactType: models.UserContactType;
  selectedProduct: models.ProductSendConfig | undefined;
  sendMethodType: models.SendMethodType;
}

export interface AutoGiftEditContextValue extends AutoGiftEditContextState {
  setContextValue: React.Dispatch<
    Partial<AutoGiftEditContextState> | ((prev: AutoGiftEditContextState) => Partial<AutoGiftEditContextState>)
  >;
  form?: FormInstance;
}

export const deafultAutoGiftEditContextValue: AutoGiftEditContextValue = {
  contactType: "clients",
  selectedProduct: undefined,
  sendMethodType: models.SendMethodType.email,
  setContextValue: noop,
};

export const AutoGiftEditContext = React.createContext<AutoGiftEditContextValue>(deafultAutoGiftEditContextValue);
