import {
  loadStripe,
  PaymentMethod,
  PaymentMethodCreateParams,
  Stripe as StripeInstance, StripeCardElement, StripeError
} from "@stripe/stripe-js";

import models from "models";
import { RecursivePartial } from "types";

import { apiAxios } from "./axios";

export const stripe = {
  clientSecret: "",
  setupKeys: async () => {
    const { data: setupKeys } = await apiAxios.get<models.StripeSetupKeys>("/orders/setup-keys/");
    stripe.clientSecret = setupKeys.client_secret || "";
    return setupKeys;
  },
  load: async () => {
    const setupKeys = await stripe.setupKeys();
    return await loadStripe(setupKeys.public_key || "");
  },
  addCard: async (
    stripeInstance: StripeInstance,
    cardElement: StripeCardElement | null,
    billingDetails: PaymentMethodCreateParams.BillingDetails
  ): Promise<{ error?: StripeError, paymentMethod?: RecursivePartial<PaymentMethod> }> => {
    if (!cardElement) return {};
    const { paymentMethod, error: createPaymentError } = await stripeInstance.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });
    if (createPaymentError) return { error: createPaymentError };

    await stripe.setupKeys();

    const { error: confimError } = await stripeInstance.confirmCardSetup(stripe.clientSecret, {
      payment_method: paymentMethod?.id,
    });

    if (confimError) return { error: confimError };
    if (paymentMethod) return { paymentMethod };
    return {}
  },
};
