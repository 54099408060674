import React, { useContext } from "react";
import { Form } from "ebs-design";

import {
  Button,
  Card,
  Collapse,
  Flex,
  Grid,
  Icon,
  InputFormField,
  InputPhoneFormField,
  WhiteSpace,
  WithInfoIcon,
} from "components";
import { capitalized, makeBEM } from "utils";
import { useScreenBreakPoint } from "hooks";

import { usePageTemplate } from "features/users/hooks";

import { useSendGiftContext } from "../hooks";
import { StepContext } from "../contexts";

export interface QuickSendFormProps {
  onHasValuesChange: React.Dispatch<boolean>;
}

const bem = makeBEM("send-gift-quick-form");

export const QuickSendForm: React.FC<QuickSendFormProps> = ({ onHasValuesChange }) => {
  const { contactType, setContextValue, quickSendForm: form } = useSendGiftContext();
  const { setCurrentStep } = useContext(StepContext);
  const template = usePageTemplate();

  const onMobile = useScreenBreakPoint("tablet");

  const [collapsed, setCollapsed] = React.useState(!Object.values(form?.getFieldsValue()).filter((_) => _).length);

  const getFields = (nameFields: React.ReactNode, restFields: React.ReactNode, btn: React.ReactNode) =>
    onMobile ? (
      <>
        {nameFields}
        <Collapse collapsed={collapsed} time={500}>
          <div className={bem("grid")}>{restFields}</div>
          {btn}
        </Collapse>
      </>
    ) : (
      <>
        <div className={bem("grid", { "for-clients": contactType === "clients" })}>
          {nameFields}
          {restFields}
        </div>
        {btn}
      </>
    );

  return (
    <>
      <Flex gap="0.5rem" align="center" onClick={() => setCollapsed(false)}>
        <h2>Quick send</h2>
        {onMobile && collapsed && <Button light color="secondary" auto icon={<Icon type="droparrow" />} />}
      </Flex>
      <WhiteSpace v="1rem" />

      <Card style={{ padding: "1.5rem" }}>
        <WithInfoIcon>This recipient will be saved after you send the gift</WithInfoIcon>
        <WhiteSpace v="1rem" />

        <Form
          form={form}
          onValuesChange={(_, values) => {
            const hasValues = !!Object.values(values).filter((_) => _).length;
            onHasValuesChange(hasValues);
            collapsed && setCollapsed(false);
          }}
          onFinish={(values) => {
            setContextValue({ quickSendContact: values });
            setCurrentStep((prev) => prev + 1);
          }}
        >
          {getFields(
            <Grid cols="1fr 1fr" className="form-field-input-group">
              <InputFormField
                placeholder="First name"
                label="First name"
                name="first_name"
                /* className="placeholder-center" */
              />
              <InputFormField
                placeholder="Last name"
                label="Last name"
                name="last_name"
                /* className="placeholder-center" */
              />
            </Grid>,
            <>
              <InputFormField
                placeholder="Email"
                label="Email"
                name="email"
                type="email"
                rules={[{ type: "email", message: "Please enter a valid email", validateTrigger: ["onSubmit"] }]}
                /* className="placeholder-center" */
              />
              {contactType === "employees" && (
                <InputPhoneFormField
                  placeholder="Phone (optional)"
                  label="Phone"
                  name="phone" /* className="placeholder-center" */
                />
              )}
              <InputFormField
                placeholder={`${capitalized(template.groupName.singular)} (optional)`}
                label={capitalized(template.groupName.singular)}
                name="group"
                /* className="placeholder-center" */
              />
            </>,
            <>
              <WhiteSpace v="1rem" />
              <Form.Field>
                {(_, __, form) => {
                  const btn = (
                    <Button
                      disabled={!["first_name", "last_name", "email"].every(form.getFieldValue)}
                      block={onMobile}
                      iconRight
                      icon={<Icon type="back" className="rotate-180" />}
                    >
                      Continue
                    </Button>
                  );
                  return onMobile ? (
                    btn
                  ) : (
                    <Flex align="center" justify="end" gap="1rem">
                      <hr className="flex-1" />
                      {btn}
                      {/* <hr className="flex-1" /> */}
                    </Flex>
                  );
                }}
              </Form.Field>
            </>
          )}
        </Form>
      </Card>
    </>
  );
};
