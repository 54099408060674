import { Form, Loader, useForm } from "ebs-design";

import { AlertErrors, Button, Flex, Icon, InputFormField, WhiteSpace } from "components";
import { makeBEM } from "utils";

import { useCompanySettings } from "features/settings/hooks";

const c = makeBEM("settings");

export const BusinessForm = () => {
  const [form] = useForm();

  const { profileQuery, submitHandler, mutation } = useCompanySettings(form);

  return (
    <Form form={form} onFinish={submitHandler}>
      <AlertErrors error={profileQuery.error} />
      {profileQuery.isFetching && <Loader loading />}
      {!profileQuery.isError && !profileQuery.isFetching && (
        <>
          <h3>Enter Your Company Details</h3>
          <WhiteSpace v="2rem" />
          <div>Company name</div>
          <InputFormField name="name" label="Company name" required />
          <div>Address line 1</div>
          <InputFormField name={["address", "line_1"]} label="Address line 1" required />
          <div className={c("responsive-grid-2")}>
            <div>
              <div>Country</div>
              <InputFormField name={["address", "country"]} required label="Country" />
            </div>
            <div>
              <div>Region</div>
              <InputFormField name={["address", "region"]} required label="Region" />
            </div>

            <div>
              <div>County</div>
              <InputFormField name={["address", "subregion"]} required label="County" />
            </div>
            <div>
              <div>City</div>
              <InputFormField name={["address", "city"]} required label="City" />
            </div>
          </div>
          <WhiteSpace v="1rem" />
          <div>
            <div>Postcode/Zipcode</div>
            <InputFormField name={["address", "zip_code"]} required label="Postcode/Zipcode" />
          </div>
          <WhiteSpace v="3rem" />
          <hr />
          <WhiteSpace v="1rem" />
          <Flex justify="end">
            <Button icon={<Icon type="edit" />} loading={mutation.isLoading}>
              Save
            </Button>
          </Flex>
        </>
      )}
    </Form>
  );
};
