import React from "react";
import { UseMutationResult } from "react-query";

import { Modal, Form, useForm } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, InputFormField, Button, WhiteSpace, Icon, WithOptional } from "components";
import models from "models";

export interface GroupModalProps extends ModalProps {
  title: string;
  submitBtnText: string;
  mutation: UseMutationResult<any, any, any>;
  data?: models.Group;
}

export const GroupModal = ({ title, submitBtnText, data, mutation, ...props }: GroupModalProps) => {
  const [form] = useForm();

  React.useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const submitHandler = (values: models.Group) =>
    mutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
      },
    });

  return (
    <Modal className="modal-z-index-2" size="small" {...props}>
      <Modal.Content>
        <h2>{title}</h2>
        <WhiteSpace v="2rem" />
        <Form form={form} onFinish={submitHandler}>
          <div>Name</div>
          <InputFormField name="name" label="Name" required />
          <WithOptional>Description</WithOptional>
          <InputFormField name="description" label="Description" textarea />

          <Flex justify="end" gap="1rem">
            <Button color="secondary" bordered onClick={props.onClose} type="button">
              Cancel
            </Button>
            <Button loading={mutation.isLoading} icon={<Icon type="edit" />}>
              {submitBtnText}
            </Button>
          </Flex>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
