import { Card, Flex, Text, WhiteSpace } from "components";
import models from "models";

export interface AddedUserCardProps {
  user?: models.UserContact;
}
export const AddedUserCard = ({ user }: AddedUserCardProps) => {
  return (
    <Card style={{ background: "#C9F7F530" }}>
      <Flex align="center">
        <div className="flex-1">
          <p>
            {user?.first_name} {user?.last_name}
          </p>
          <Text color="gray">
            {user?.email} -{" "}
            <Text el="span" fw="bold">
              {user?.group?.name}
            </Text>
          </Text>
        </div>
        <WhiteSpace h="1rem" />
        <Text el="h4" color="#1BC5BD">
          Added
        </Text>
      </Flex>
    </Card>
  );
};
