import React from "react";
import cn from "classnames";

import models from "models";
import { ArrayUtils, makeBEM, withCurrency } from "utils";

import { Image } from "./Image";
import { Icon } from "./Icon";

export interface ProductCardProps extends React.HTMLAttributes<HTMLDivElement> {
  selectable?: boolean;
  selected?: boolean;
  data: models.Product;
  choice?: models.ProductCharacteristicChoice;
}

const bem = makeBEM("product-card");

export const ProductCard: React.FC<ProductCardProps> = ({
  selectable,
  selected,
  className,
  data,
  choice,
  children,
  ...props
}) => {
  const prices =
    data.characteristic_types
      ?.map((c) => c.choices)
      .flat(1)
      .map((c) => c?.price || 0) || [];

  const min = Math.min(...prices);
  const max = Math.max(...prices);

  const price = choice ? (
    withCurrency(choice?.price)
  ) : ArrayUtils.isEmpty(prices) ? (
    withCurrency(data.cost)
  ) : (
    <>
      {withCurrency(min)} - {withCurrency(max)}
    </>
  );

  return (
    <div className={cn(className, bem(null, { selectable, selected }))} {...props}>
      <div className={bem("redeem-types")}>
        {data.types?.includes(models.ProductReedemType.store) && <Icon strokeWidth={1} type="store" />}
        {data.types?.length === 2 && <div className={bem("redeem-types-divider")} />}
        {data.types?.includes(models.ProductReedemType.delivery) && <Icon strokeWidth={1} type="delivery" />}
      </div>
      <div className={bem("image")}>
        <Image src={data.featured_image?.url || data.image?.url} />
      </div>
      <div className={bem("info-container")}>
        <div>
          <h5 className={bem("title")}>{data.title}</h5>
          <div className={bem("price")}>{price}</div>
        </div>
        <div className={bem("brand")}>
          <div className={bem("brand-title")}>{data.brand?.title}</div>
          <div className={bem("brand-image")}>
            <Image src={data.brand?.image?.url} />
          </div>
        </div>
      </div>
    </div>
  );
};
