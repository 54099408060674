import React from "react";

export const Camera: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.6 26.6665H6.4C5.06667 26.6665 4 25.5998 4 24.2665V11.0665C4 9.73317 5.06667 8.6665 6.4 8.6665H25.4667C26.8 8.6665 27.8667 9.73317 27.8667 11.0665V24.1332C28 25.4665 26.9333 26.6665 25.6 26.6665Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M15.8665 22.2667C18.4438 22.2667 20.5332 20.1773 20.5332 17.6C20.5332 15.0227 18.4438 12.9333 15.8665 12.9333C13.2892 12.9333 11.1998 15.0227 11.1998 17.6C11.1998 20.1773 13.2892 22.2667 15.8665 22.2667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M23.8668 14.6666C24.824 14.6666 25.6001 13.8906 25.6001 12.9333C25.6001 11.976 24.824 11.2 23.8668 11.2C22.9095 11.2 22.1334 11.976 22.1334 12.9333C22.1334 13.8906 22.9095 14.6666 23.8668 14.6666Z"
        fill={fill}
      />
      <path
        d="M21.3333 8.66659H10.6666C10.6666 6.79992 12.1333 5.33325 14 5.33325H18.1333C19.8666 5.33325 21.3333 6.79992 21.3333 8.66659Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
