import React from "react";
import { useMutation, useQuery } from "react-query";

import { useNotify } from "ebs-design";

import api, { querykeys } from "api";
import models from "models";
import { WhiteSpace, QueryList, MutationConfirmModal } from "components";
import { notifyErrors } from "utils";

import { AddPaymentMethodForm, PaymentMethodCard } from "features/payments/components";

export const PaymentMethodTab = () => {
  const notify = useNotify();

  const [deleteModalData, setDeleteModalData] = React.useState<models.PaymentMethod>();

  const query = useQuery(querykeys.paymentMethods.many(), api.paymentMethods.getList);
  const deleteMutation = useMutation(() => api.paymentMethods.delete(deleteModalData?.id || ""), {
    onError: (error) => notifyErrors(notify, error),
    onSuccess: () => query.refetch(),
  });

  return (
    <>
      <AddPaymentMethodForm onSuccess={() => query.refetch()} />
      <WhiteSpace v="2rem" />
      <hr />
      <WhiteSpace v="1rem" />
      <QueryList
        query={query}
        getItems={(data) => data?.filter((paymentMethod) => paymentMethod.type === models.PaymentMethodType.card)}
        renderItems={(items?: models.PaymentMethod[]) =>
          items?.map((paymentMethod) => (
            <PaymentMethodCard
              className="my-3"
              data={paymentMethod.data}
              onRemoveClick={() => setDeleteModalData(paymentMethod)}
            />
          ))
        }
        emptyText="No cards were added"
      />
      <MutationConfirmModal
        open={!!deleteModalData}
        mutation={deleteMutation}
        onClose={() => setDeleteModalData(undefined)}
      >
        Are you sure to remove card with number <b>****** {deleteModalData?.data?.last4}</b> ?
      </MutationConfirmModal>
    </>
  );
};
