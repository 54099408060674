import React from "react";

export const Logout: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M19.8667 20.1333V25.3333C19.8667 26.8 18.6667 28 17.2001 28H7.33342C5.86675 28 4.66675 26.8 4.66675 25.3333V6.66667C4.66675 5.2 5.86675 4 7.33342 4H17.2001C18.6667 4 19.8667 5.2 19.8667 6.66667V9.46667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M23.3335 12.2666L27.3335 16.2666L23.3335 20.2666"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M15.4668 16.2666H27.3335"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
