import React from "react";

export const Dashboard: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <g>
          <rect height="8" style={{ fill: fill }} width="6" rx="1" />
          <rect height="4" style={{ fill: fill }} width="6" rx="1" y="10" />
          <rect
            height="8"
            style={{ fill: fill }}
            width="6"
            rx="1"
            x="8"
            y="6"
          />
          <rect height="4" style={{ fill: fill }} width="6" rx="1" x="8" />
        </g>
      </g>
    </svg>
  );
};
