/** API */
export const API_POST_CONTENT_TYPE = "application/json";

export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

/** Text */
export const NO_AVAILABLE_ERRORS_MESSAGE = "Something went wrong, please contact support";

/**  */
export const PHONE_PREFIX = "44";
export const LOCALE = "en-uk";
export const MONTH_DAY_FORMAT = "MMMM dd";

export const USERS_UPLOAD_ACCEPT =
  "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv";
