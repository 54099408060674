import React, { useState } from "react";
import cn from "classnames";

import { makeBEM } from "utils";
import { useElementBoxBreakPoint, useScrollTopPosition } from "hooks";

export interface HorizontalTabPanelsContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  noTopbar?: boolean;
}

const bem = makeBEM("horizontal-tab-panels-conatiner");

export const HorizontalTabPanelsContainer: React.FC<HorizontalTabPanelsContainerProps> = ({
  className,
  children,
  noTopbar,
  ...props
}) => {
  const scrolled = !useScrollTopPosition(20);

  const [div, setDiv] = useState<HTMLDivElement | null>(null);

  return (
    <div
      ref={setDiv}
      className={cn(
        className,
        bem(null, {
          scrolled,
          laptop: useElementBoxBreakPoint(div, "laptop"),
          tablet: useElementBoxBreakPoint(div, "tablet"),
          mobile: useElementBoxBreakPoint(div, "mobile"),
          "no-topbar": noTopbar,
        })
      )}
      {...props}
    >
      {children}
    </div>
  );
};
