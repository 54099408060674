import React, { CSSProperties } from "react";
import cn from "classnames";

import { ColorMapKey, getColor } from "components";

export interface TextProps extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  /**
   * Rendered html element
   */
  el?: keyof React.ReactHTML;
  color?: ColorMapKey;
  /**
   * font weight
   */
  fw?: CSSProperties["fontWeight"];
  size?: number | string;
  /**
   * for cursor: pointer
   */
  pointer?: boolean;
  align?: CSSProperties["textAlign"];

  button?: boolean;
}

export const Text: React.FC<TextProps> = ({
  el = "div",
  color,
  fw,
  size,
  children,
  pointer,
  align,
  style,
  button,
  className,
  ...props
}) => {
  return React.createElement(el, {
    style: {
      color: getColor(color),
      fontWeight: fw,
      fontSize: size,
      cursor: pointer ? "pointer" : undefined,
      textAlign: align,
      ...style,
    },
    children,
    className: cn(className, { "text-button": button }),
    tabIndex: button ? 0 : undefined,
    ...props,
  });
};
