import React from "react";

export const StatusRedeemed: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M20.3999 10.4001H17.9999C17.0666 10.4001 16.3999 9.33342 16.7999 8.53342L17.8666 6.40008C19.1999 3.60008 23.3333 4.53342 23.3333 7.60008C23.3333 9.20008 21.9999 10.4001 20.3999 10.4001Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12.9333 10.4001H15.3333C16.2667 10.4001 16.9333 9.33342 16.5333 8.53342L15.4667 6.40008C14.1333 3.60008 10 4.53342 10 7.60008C10 9.20008 11.3333 10.4001 12.9333 10.4001Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M24.8 14.0001H8.53328C7.73328 14.0001 7.19995 13.3334 7.19995 12.6668V11.8668C7.19995 11.0668 7.86662 10.5334 8.53328 10.5334H24.8C25.6 10.5334 26.1333 11.2001 26.1333 11.8668V12.5334C26.1333 13.3334 25.6 14.0001 24.8 14.0001Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M9.19995 14H24V21.2C24 22.6667 22.8 23.8667 21.3333 23.8667H11.8666C10.4 23.8667 9.19995 22.6667 9.19995 21.2V14Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M20.4 24.1333L25.0666 22.8C25.7333 22.6667 26.4 22.8 26.9333 23.0667L27.7333 23.6C28.1333 23.8667 28.1333 24.5333 27.6 24.8L19.3333 28C18.4 28.4 17.3333 28.4 16.2666 28.1333L7.59998 25.2L9.33331 18.9333"
        fill="none"
      />
      <path
        d="M20.4 24.1333L25.0666 22.8C25.7333 22.6667 26.4 22.8 26.9333 23.0667L27.7333 23.6C28.1333 23.8667 28.1333 24.5333 27.6 24.8L19.3333 28C18.4 28.4 17.3333 28.4 16.2666 28.1333L7.59998 25.2L9.33331 18.9333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3333 23.2C13.3333 23.2 15.7333 23.8667 18 24.2667C19.0666 24.4 19.7333 24.2667 20.2666 24.1333C20.7999 24 21.0666 23.3333 20.8 22.9333C20.4 22.1333 19.7333 21.4667 18.8 21.2L9.19995 19.0667"
        fill="none"
      />
      <path
        d="M13.3333 23.2C13.3333 23.2 15.7333 23.8667 18 24.2667C19.0666 24.4 19.7333 24.2667 20.2666 24.1333C20.7999 24 21.0666 23.3333 20.8 22.9333C20.4 22.1333 19.7333 21.4667 18.8 21.2L9.19995 19.0667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M4 16.8L9.46667 17.7334L9.2 18.9334L7.6 25.2L7.2 26.4H4V16.8Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
