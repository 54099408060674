import React from "react";
import { useQuery } from "react-query";

import { Modal } from "ebs-design";

import {
  Drawer,
  FilterButton,
  Flex,
  Icon,
  NoFiltersMatchMessage,
  ProductCharacteristicChoices,
  ProductFilters,
  ProductListGroup,
  QueryList,
  WhiteSpace,
} from "components";
import api, { querykeys } from "api";
import { useModalState, useProductFilters } from "hooks";
import { makeBEM } from "utils";
import { atoms } from "atoms";
import models from "models";

import { useSendGiftContext } from "features/send-gift/hooks";

import { StepContext } from "../contexts";
import { createProductPropsGetter } from "../utils";

const bem = makeBEM("send-gift");

export const SelectPrsntTab = () => {
  const { selectedProducts, setContextValue, hasInitialProducts } = useSendGiftContext();
  const { setCanGoNext } = React.useContext(StepContext);

  const query = useQuery(querykeys.products.many(), () => api.companies.products.grouped());

  const productFilters = useModalState(atoms.productFilters);

  const { productFilterProps, filteredProducts } = useProductFilters(query.data, productFilters.close);

  const characteristicsModal = useModalState<models.Product>(atoms.characteristics);

  React.useEffect(() => {
    setCanGoNext(!!selectedProducts.length);
  }, [selectedProducts.length, setCanGoNext]);

  return (
    <>
      <WhiteSpace v="1rem" />
      <Flex gap="0.5rem" style={{ padding: "0 1rem" }}>
        <Icon type="info" />
        {hasInitialProducts
          ? "You already have preselected products, you can skip this step"
          : "Select one or more products to continue"}
      </Flex>

      <WhiteSpace v="2rem" />
      <Flex className={bem("filter-toggle")} justify="end">
        <FilterButton onClick={productFilters.open} />
      </Flex>
      <Drawer open={productFilters.isOpen} onClose={productFilters.close}>
        <ProductFilters {...productFilterProps} />
      </Drawer>
      <WhiteSpace v="1rem" />

      <QueryList
        query={query}
        emptyText={<NoFiltersMatchMessage />}
        getItems={() => filteredProducts}
        renderItems={(items) => (
          <Flex direction="column" gap="3.5rem" align="stretch">
            {items.map((category) => (
              <ProductListGroup
                data={category}
                getProductProps={createProductPropsGetter(
                  selectedProducts,
                  (selectedProducts) => setContextValue({ selectedProducts }),
                  characteristicsModal.openWith
                )}
              />
            ))}
          </Flex>
        )}
      />
      <Modal size="small" open={characteristicsModal.isOpen} onClose={characteristicsModal.close}>
        <Modal.Content>
          <ProductCharacteristicChoices
            characteristics={characteristicsModal.data?.characteristic_types || []}
            onChoiceClick={(choice) => {
              setContextValue({
                selectedProducts: [
                  ...selectedProducts,
                  { product: characteristicsModal.data!, product_characteristic_choices: choice },
                ],
              });
              characteristicsModal.close();
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
