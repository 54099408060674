import { Flex, Icon, renderCheckbox, renderTitleSubtitle, Text, WhiteSpace } from "components";
import { TableColumnBuilder } from "utils";
import models from "models";
import { Label } from "ebs-design";

interface UsersTableColumnsArgs {
  onEditClick: React.Dispatch<models.UserContact>;
  onDeleteClick: React.Dispatch<models.UserContact>;
  onSendGiftClick: React.Dispatch<models.UserContact>;
  groupName: string;
  contactType: models.UserContactType;
}

export const usersTableColumns = ({
  onDeleteClick,
  onEditClick,
  onSendGiftClick,
  groupName,
  contactType,
}: UsersTableColumnsArgs) =>
  new TableColumnBuilder<models.UserContact>()
    .add("Name", "", (_, user) => `${user.first_name || ""} ${user.last_name || ""}`)
    .add("Email", "email")
    .buildWith((builder) => {
      contactType === "employees" &&
        builder
          .add("Phone", "phone")
          .add("Status", "target", (value: any) =>
            value ? (
              <Label type="fill" status="success" text="Registered" />
            ) : (
              <Label type="fill" status="danger" text="Not registered" />
            )
          );
    })
    .add(groupName, "group", renderTitleSubtitle("name", "description"))
    .buildWith(
      (builder) => contactType === "employees" && builder.add("Birthday gifts", "send_birthday_gift", renderCheckbox)
    )
    .add("Christmas Day gifts", "send_special_day_gift", renderCheckbox)
    .add(
      "Actions",
      "id",
      (_, record) => (
        <Text color="primary">
          <Flex align="center" justify="flex-end">
            <Icon type="status-redeemed" className="cursor-pointer" onClick={() => onSendGiftClick(record)} />
            <WhiteSpace h="1rem" inline />
            <Icon type="edit" className="cursor-pointer" onClick={() => onEditClick(record)} />
            <WhiteSpace h="1rem" inline />
            <Icon type="delete" className="cursor-pointer" onClick={() => onDeleteClick(record)} />
          </Flex>
        </Text>
      ),
      { align: "right" }
    )
    .getColumns();

interface GroupsTableColumnsArgs {
  onEditClick: React.Dispatch<any>;
  onDeleteClick: React.Dispatch<any>;
  groupName: string;
}

export const groupsTableColumns = ({ onEditClick, onDeleteClick, groupName }: GroupsTableColumnsArgs) =>
  new TableColumnBuilder()
    .add(groupName, "", renderTitleSubtitle("name", "description", true))
    .add("People", "contacts_count", undefined, { width: 10 })
    .add(
      "",
      "id",
      (_, record: any) => (
        <Text className="nowrap" color="primary">
          <Flex align="center" justify="flex-end">
            <Icon type="edit" className="cursor-pointer" onClick={() => onEditClick(record)} />
            <WhiteSpace inline h="1rem" />
            <Icon type="delete" className="cursor-pointer" onClick={() => onDeleteClick(record)} />
          </Flex>
        </Text>
      ),
      { width: 10 }
    )
    .getColumns();

export const importExampleHref = (contactType: models.UserContactType) =>
  new URL(`./api/companies/${contactType}/import-example/`, process.env.REACT_APP_BASE_API).href;
