import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useUpdateEffect } from "react-use";

import { useNotify } from "ebs-design";

import { MutationConfirmModal, PageContainer, WhiteSpace } from "components";
import { useModalState, useQueryPagination, useSearch } from "hooks";
import models from "models";
import { notifyErrors } from "utils";
import { atoms } from "atoms";

import {
  AddUserModal,
  EditUserModal,
  UsersCardHeader,
  UploadCSVModal,
  GroupsModal,
  UsersTable,
} from "features/users/components";
import { TemplateConfig } from "features/users/types";
import { PageTemplateContext } from "features/users/contexts";

export interface PageTemplateProps {
  template: TemplateConfig;
}

export const PageTemplate = ({ template }: PageTemplateProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const addModal = useModalState(atoms.add);
  const groupsModal = useModalState(atoms.groups);
  const csvModal = useModalState(atoms.csv);

  const [search, setSearch] = useSearch();
  const [group, setGroup] = React.useState<string>();
  const pagination = useQueryPagination();

  const queryParams = {
    page: pagination.page,
    per_page: pagination.perPage,
    group,
    search,
  };

  useUpdateEffect(() => pagination.setPage(1), [search]);

  const query = useQuery(template.querykeys.users.many(queryParams), () => template.usersApi.get(queryParams));

  const patchMutation = useMutation(
    (newUser: models.UserContact) => template.usersApi.patch(editModal.data?.id || 0, newUser || {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(template.querykeys.users.many());
        notify.success({ title: "", description: "User updated successfully" });
        editModal.reset();
      },
      onError: (error) => notifyErrors(notify, error),
    }
  );

  const deleteMutation = useMutation(() => template.usersApi.delete(deleteModal.data?.id || 0), {
    onSuccess: () => {
      queryClient.invalidateQueries(template.querykeys.users.many());
      notify.success({ title: "", description: "User Deleted successfully" });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const editModal = useModalState<models.UserContact>(atoms.edit);
  const deleteModal = useModalState<models.UserContact>(atoms.delete);

  return (
    <PageTemplateContext.Provider value={template}>
      <PageContainer>
        <UsersCardHeader
          count={query.data?.count}
          search={search}
          setSearch={setSearch}
          onAddNewClick={addModal.open}
          onGroupsClick={groupsModal.open}
          onCsvClick={csvModal.open}
          group={group}
          setGroup={setGroup}
        />
        <WhiteSpace v="1rem" />
        <UsersTable
          {...{ query, setEditModalData: editModal.openWith, setDeleteModalData: deleteModal.openWith, pagination }}

          // CANCELED
          // onGroupsClick={groupsModal.open}
        />
        <AddUserModal open={addModal.isOpen} onClose={addModal.close} onCsvClick={csvModal.open} />
        <EditUserModal
          mutation={patchMutation}
          data={editModal.data}
          open={editModal.isOpen}
          onClose={editModal.close}
        />
        <UploadCSVModal open={csvModal.isOpen} onClose={csvModal.close} />
        <GroupsModal open={groupsModal.isOpen} onClose={groupsModal.close} />
        <MutationConfirmModal open={deleteModal.isOpen} onClose={deleteModal.close} mutation={deleteMutation}>
          <h3>
            Are you sure to delete {deleteModal.data?.first_name} {deleteModal.data?.last_name}?
          </h3>
        </MutationConfirmModal>
      </PageContainer>
    </PageTemplateContext.Provider>
  );
};
