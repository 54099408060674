import React from "react";

import { useControlledState } from "hooks";
import { noop } from "utils";

export type SetActiveTabAction = React.Dispatch<React.SetStateAction<any>>;

export interface TabContextValue {
  active: any;
  setActive: SetActiveTabAction;
  activeTabClassName: string;
}

export const TabContext = React.createContext<TabContextValue>({
  active: undefined,
  setActive: noop,
  activeTabClassName: "active",
});

export interface TabProviderProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "onChange" | "value"> {
  defaultActive?: any;
  activeTabClassName?: string;
  value?: any;
  onChange?: React.Dispatch<any>;
}

export const TabProvider: React.FC<TabProviderProps> = ({
  defaultActive,
  value,
  onChange,
  activeTabClassName = "active",
  ...props
}) => {
  const [active, setActive] = useControlledState(
    value,
    onChange,
    defaultActive
  );

  return (
    <TabContext.Provider value={{ active, setActive, activeTabClassName }}>
      <div {...props}></div>
    </TabContext.Provider>
  );
};
