import React from "react";

export const Help: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="16" cy="16" r="15" stroke={stroke} />
      <path
        d="M15.1654 20.064C15.0214 20.064 14.9014 20.016 14.8054 19.92C14.7094 19.824 14.6614 19.704 14.6614 19.56V18.6C14.6934 17.752 14.9094 17.024 15.3094 16.416C15.7094 15.808 16.2934 15.112 17.0614 14.328C17.6854 13.688 18.1414 13.16 18.4294 12.744C18.7174 12.328 18.8614 11.872 18.8614 11.376C18.8614 10.64 18.5494 10.056 17.9254 9.62396C17.3174 9.19196 16.6054 8.97596 15.7894 8.97596C13.8854 8.97596 12.7654 9.93596 12.4294 11.856C12.3494 12.208 12.1414 12.384 11.8054 12.384H10.6534C10.4934 12.384 10.3654 12.336 10.2694 12.24C10.1734 12.144 10.1254 12.016 10.1254 11.856C10.1574 10.976 10.4054 10.168 10.8694 9.43196C11.3494 8.67996 12.0214 8.07996 12.8854 7.63196C13.7494 7.18396 14.7414 6.95996 15.8614 6.95996C17.0454 6.95996 18.0374 7.16796 18.8374 7.58396C19.6534 7.98396 20.2534 8.51196 20.6374 9.16796C21.0214 9.80796 21.2134 10.496 21.2134 11.232C21.2134 12.048 21.0294 12.752 20.6614 13.344C20.2934 13.936 19.7414 14.624 19.0054 15.408C18.3974 16.048 17.9414 16.592 17.6374 17.04C17.3334 17.472 17.1574 17.96 17.1094 18.504C17.0774 18.952 17.0614 19.288 17.0614 19.512C16.9814 19.88 16.7814 20.064 16.4614 20.064H15.1654ZM14.9734 24C14.8134 24 14.6774 23.952 14.5654 23.856C14.4694 23.744 14.4214 23.608 14.4214 23.448V21.984C14.4214 21.824 14.4694 21.696 14.5654 21.6C14.6774 21.488 14.8134 21.432 14.9734 21.432H16.5334C16.6934 21.432 16.8294 21.488 16.9414 21.6C17.0534 21.696 17.1094 21.824 17.1094 21.984V23.448C17.1094 23.608 17.0534 23.744 16.9414 23.856C16.8294 23.952 16.6934 24 16.5334 24H14.9734Z"
        fill={fill}
      />
    </svg>
  );
};
