import { useQuery } from "react-query";

import { Card, Icon, Text, Loader } from "components";
import { makeBEM } from "utils";
import api, { querykeys } from "api";

const c = makeBEM("dashboard");

export const DashboardStats = () => {
  const query = useQuery(querykeys.orders.stats(), api.orders.stats);
  const stats = query.data;
  return (
    <>
      {query.isLoading ? (
        <Loader size="md" spread />
      ) : (
        <div className={c("stats")}>
          <Card className={c("stats-card")}>
            <Icon className={c("stats-card-icon")} type="status-redeemed" size="2rem" />
            <p className="flex-1 mx-4">Rewards Sent</p>
            <Text el="h4" color="primary">
              {stats?.send || 0}
            </Text>
          </Card>
          <Card className={c("stats-card")}>
            <Icon className={c("stats-card-icon")} type="friends" size="2rem" />
            <div className="flex-1 mx-4">
              <p>Customers and Clients</p>
              <Text color="gray">{stats?.companies} Group(s)</Text>
            </div>
            <Text el="h4" color="primary">
              {stats?.customers || 0}
            </Text>
          </Card>
          <Card className={c("stats-card")}>
            <Icon className={c("stats-card-icon")} type="friends" size="2rem" />
            <div className="flex-1 mx-4">
              <p>Employees</p>
              <Text color="gray">{stats?.teams} Team(s)</Text>
            </div>
            <Text el="h4" color="primary">
              {stats?.employees || 0}
            </Text>
          </Card>
        </div>
      )}
    </>
  );
};
