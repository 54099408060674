import React from "react";

export const Message: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M21.3333 20.8333H20.9429L20.8482 21.212L19.7038 25.7894L13.6456 20.9428L13.5086 20.8333H13.3333H7.33325C6.50482 20.8333 5.83325 20.1617 5.83325 19.3333V7.33325C5.83325 6.50482 6.50482 5.83325 7.33325 5.83325H24.6666C25.495 5.83325 26.1666 6.50483 26.1666 7.33325V19.3333C26.1666 20.1617 25.495 20.8333 24.6666 20.8333H21.3333Z"
        stroke={stroke}
      />
    </svg>
  );
};
