import { Button, Form } from "ebs-design";

import { Card, Flex, InputFormField, Text, Upload, WhiteSpace } from "components";
import api from "api";
import models from "models";
import { makeBEM, videoOrCommentRule } from "utils";

import { useAutoGiftEditContext } from "features/auto-gifts/hooks";

const c = makeBEM("auto-gifts-edit");

/**
 * @deprecated
 */
export const Message = () => {
  const { form: messageForm, sendMethodType, setContextValue } = useAutoGiftEditContext();

  return (
    <>
      <Card className={c("message-card")}>
        <Card className={c("inner-message-card")}>
          <Form
            form={messageForm}
            onValuesChange={() => {
              /** Temporary hack to trigger rerender */
              setContextValue({});
              messageForm?.validateFields();
            }}
          >
            <Flex align="center" justify="space-between" wrap>
              <h2>Set a message</h2>
              <Text color="gray">Via {sendMethodType === models.SendMethodType.mobile ? "prsnt app" : "email"}</Text>
            </Flex>
            <WhiteSpace v="1rem" />
            <InputFormField
              name="message"
              label="Message"
              textarea
              rules={sendMethodType === models.SendMethodType.mobile ? [videoOrCommentRule] : [{ required: true }]}
            />
            {sendMethodType === models.SendMethodType.mobile && (
              <Form.Field name="video" initialValue={[]}>
                <Upload accept="video/*" name="files" customRequest={api.attachments.post}>
                  <Button>Upload Video</Button>
                </Upload>
              </Form.Field>
            )}
          </Form>
        </Card>
      </Card>
    </>
  );
};
