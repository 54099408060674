import React from "react";
import api, { querykeys } from "api";
import { TemplateConfig } from "features/users/types";

export const PageTemplateContext = React.createContext<TemplateConfig>({
  usersApi: api.companies.createUsersApi("clients"),
  querykeys: {
    users: querykeys.companies.users.clients,
    groups: querykeys.companies.groups.companies,
  },
  name: { plural: "", singular: "" },
  groupName: { plural: "", singular: "" },
  contactType: "clients",
});
