import React from "react";
import { contrastTextColor, makeBEM, generateColor } from "utils";

export interface AvatarProps {
  name?: string;
  src?: string;
}

const c = makeBEM("avatar");

export const Avatar = ({ name, src }: AvatarProps) => {
  const [isError, setIsError] = React.useState(false);

  const showImage = !isError && !!src;
  const color = generateColor((name || "a").charCodeAt(0));

  React.useLayoutEffect(() => setIsError(false), [src]);

  return (
    <div
      className={c("wrapper")}
      style={{ background: showImage ? undefined : color }}
    >
      {showImage ? (
        <img src={src} alt="Profile" onError={() => setIsError(true)} />
      ) : (
        <div
          className={c("letter")}
          style={{ color: contrastTextColor(color) }}
        >
          {name?.[0] || ""}
        </div>
      )}
    </div>
  );
};
