/**
 * @example
 * const arr: (string | number)[] = [1, 2, 3];
 *
 * function someFunc(a: number[]) {
 *   // ...
 * }
 *
 * someFunc(arr) // ERROR
 *
 * if (isArrayOf(arr, Number())) {
 *   someFunc(arr) // good
 * }
 */
export function isArrayOf<T>(arr: any, sample: T): arr is T[] {
  return arr instanceof Array && arr.every((item) => typeof item === typeof sample);
}

export function wrapInArr<T>(what: T | readonly T[] | undefined) {
  return what instanceof Array ? what : what !== undefined ? [what] : [];
}

type ArrayPredicate<T, Return = void> = (value: T, idx: number, arr: T[]) => Return;
export class ArrayUtils<T> {
  private _arr: T[];
  constructor(arr: T[]) {
    this._arr = arr;
  }
  first() {
    return ArrayUtils.first(this._arr);
  }
  last() {
    return ArrayUtils.last(this._arr);
  }
  isEmpty() {
    return ArrayUtils.isEmpty(this._arr);
  }

  // TODO inherit from array prototype somehow
  find(...args: Parameters<Array<T>["find"]>) {
    return this._arr.find(...args);
  }

  //
  intersect(otherArr: T[], predicate: ArrayPredicate<T, any>) {
    return otherArr.filter((...args) => this._arr.find((...args2) => predicate(...args) === predicate(...args2)));
  }

  // Methods for chaining
  filter(...args: Parameters<Array<T>["filter"]>) {
    this._arr = this._arr.filter(...args);
    return this;
  }

  intersectWith(...args: Parameters<ArrayUtils<T>["intersect"]>) {
    this._arr = this.intersect(...args);
    return this;
  }

  //
  asArray(clone?: boolean) {
    return clone ? [...this._arr] : this._arr;
  }

  //
  static isEmpty(arr: unknown[]) {
    return !arr.length;
  }
  static first<T>(arr: T[]) {
    return arr[0];
  }
  static last<T>(arr: T[]) {
    return arr[arr.length - 1];
  }
  static wrap<T>(value: T | readonly T[] | undefined) {
    return value instanceof Array ? value : value !== undefined ? [value] : [];
  }
}
