import React from "react";

export const StatusSent: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M20.8 10.6668H17.7333C16.5333 10.6668 15.7333 9.33343 16.2666 8.26677L17.6 5.46677C19.2 2.0001 24.5333 3.2001 24.5333 7.06677C24.4 9.06677 22.8 10.6668 20.8 10.6668Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.2 10.6668H14.2666C15.4666 10.6668 16.2666 9.33343 15.7333 8.26677L14.4 5.46677C12.8 2.0001 7.59998 3.2001 7.59998 7.06677C7.59998 9.06677 9.19998 10.6668 11.2 10.6668Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M26.6667 15.0667H5.33333C4.53333 15.0667 4 14.4 4 13.7334V12.1334C4 11.3334 4.66667 10.8 5.33333 10.8H26.6667C27.4667 10.8 28 11.4667 28 12.1334V13.7334C28 14.5334 27.3333 15.0667 26.6667 15.0667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M6.66663 15.0667H25.3333V24.9333C25.3333 26.4 24.1333 27.6 22.6666 27.6H9.33329C7.86663 27.6 6.66663 26.4 6.66663 24.9333V15.0667Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M23.7334 29.7333C26.9734 29.7333 29.6 27.1067 29.6 23.8667C29.6 20.6266 26.9734 18 23.7334 18C20.4933 18 17.8667 20.6266 17.8667 23.8667C17.8667 27.1067 20.4933 29.7333 23.7334 29.7333Z"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M17.7333 23.8667H24.9333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M22.1333 21.0667L24.9333 23.8666L22.1333 26.6667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
