import { useMutation, useQuery, useQueryClient } from "react-query";

import { useNotify, useForm } from "ebs-design";

import api, { querykeys } from "api";
import models from "models";
import { normalizedPhone, notifyErrors } from "utils";

export function useProfileSettings() {
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const mutation = useMutation(api.users.profile.patch, {
    onError: (error) => notifyErrors(notify, error),
    onSuccess: () =>
      notify.success({
        title: "",
        description: "Settings updated successfully",
      }),
  });

  const query = useQuery(querykeys.profile, api.users.profile.get, {
    onSuccess: form.setFieldsValue,
  });

  const submitHandler = (values: models.User) => {
    mutation.mutate(
      { ...values, photo_id: values.photo?.id || null, phone: normalizedPhone(values.phone) },
      {
        onSuccess: (u) => {
          form.setFieldsValue(u);
          queryClient.invalidateQueries(querykeys.loggedUser);
        },
      }
    );
  };

  return {
    query,
    mutation,
    submitHandler,
    form,
  };
}
