import React from "react";

export const Catalogue: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 192 193" height="1em" width="1em" {...svgProps}>
      <path
        fill={stroke}
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M177.118 57.261h-27.157a32.443 32.443 0 009.623-10.678 32.563 32.563 0 004.077-13.804v-.192c0-16.932-12.178-27.187-24.216-29.85C127.606.12 112.566 4.214 105.677 19.19L96.013 39.56l-9.655-20.345C79.45 4.213 64.42.128 52.573 2.738 40.525 5.4 28.365 15.655 28.365 32.588a1.5 1.5 0 000 .191 32.558 32.558 0 004.073 13.813 32.438 32.438 0 009.627 10.687H14.9a12.904 12.904 0 00-9.114 3.798A12.992 12.992 0 002 70.222v12.22a12.992 12.992 0 003.786 9.144 12.904 12.904 0 009.114 3.8h7.376v72.502a23.178 23.178 0 006.754 16.335A23.02 23.02 0 0045.31 191h101.38a23.02 23.02 0 0016.28-6.777 23.179 23.179 0 006.754-16.335v-72.53h7.376c6.637 0 12.9-6.292 12.9-12.943V70.196a12.99 12.99 0 00-3.783-9.133 12.904 12.904 0 00-9.099-3.802v0zm-98.05 32.547h-64.15a7.372 7.372 0 01-5.206-2.17 7.423 7.423 0 01-2.162-5.223V70.196a7.423 7.423 0 012.162-5.222 7.372 7.372 0 015.205-2.17h64.151v27.004zM59.523 57.26c-13.413 0-24.652-10.884-25.617-24.778 0-14.742 10.717-22.327 19.867-24.334 8.968-1.981 21.572.332 27.583 13.415l10.169 21.4a9.874 9.874 0 01-.574 9.602 9.584 9.584 0 01-3.447 3.412 9.542 9.542 0 01-4.67 1.283H59.524zm27.235 5.01h-.243.243zm19.676 123.161H84.592V62.812h21.842v122.62zm-5.35-132.866a9.85 9.85 0 01-.539-9.645l10.151-21.392c4.802-10.474 13.822-13.965 21.833-13.965a26.42 26.42 0 015.741.62c9.142 2.007 19.824 9.6 19.859 24.334-.966 13.894-12.178 24.778-25.617 24.778H109.2a9.546 9.546 0 01-4.676-1.298 9.59 9.59 0 01-3.44-3.432v0zm83.41 29.85c0 3.49-3.862 7.392-7.376 7.392h-65.152V62.812h65.152a7.373 7.373 0 015.21 2.168 7.427 7.427 0 012.166 5.225v12.21z"
      ></path>
    </svg>
  );
};
