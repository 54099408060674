import React from "react";
import { makeBEM } from "utils";

export interface ProgressUnitProps {
  filled?: boolean;
}

const bem = makeBEM("progress-unit");

export const ProgressUnit: React.FC<ProgressUnitProps> = ({ filled }) => {
  return (
    <div className={bem(null, { filled })}>
      <div className={bem("fill")}></div>
    </div>
  );
};
