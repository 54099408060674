import React from "react";
import cn from "classnames";

import { makeBEM } from "utils";

import { Loader } from "./Loader";
import { ComponentSize } from "./types";

export interface ButtonBaseProps {
  auto?: boolean;
  icon?: React.ReactNode;
  iconRight?: boolean;
  loading?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  color?: ButtonColor;
  rounded?: boolean;
  bordered?: boolean;
  light?: boolean;
  subtle?: boolean;
  size?: ComponentSize;
  block?: boolean;
  sharp?: boolean;
  stopPropagation?: boolean;
  align?: ButtonAlign;
}

export type ButtonAlign = "left" | "right" | "center";
export type ButtonColor = "primary" | "secondary" | "danger";

const bem = makeBEM("button");

export const createButton = <T, P extends React.HTMLAttributes<HTMLElement>>(
  el: keyof React.ReactHTML | React.ComponentType<P>
) =>
  React.forwardRef<T, ButtonBaseProps & P>(
    (
      {
        auto,
        icon,
        iconRight,
        loading,
        clickable = true,
        disabled,
        color = "primary",
        rounded,
        light,
        size = "md",
        children,
        bordered,
        className,
        stopPropagation,
        onClick,
        block,
        sharp,
        align,
        subtle,
        ...props
      },
      ref
    ) => {
      icon = icon && loading ? <Loader /> : icon;

      const elProps: React.HTMLAttributes<HTMLElement> = {
        ref,
        className: cn(
          className,
          bem(
            null,
            {
              "un-clickable": !clickable || loading,
              "with-icon": children && !auto && !!icon,
              "with-icon-left": children && !!icon && !iconRight,
              "with-icon-right": children && !!icon && iconRight,
              auto: !block && auto,
              rounded,
              light,
              disabled,
              loading: !icon && loading,
              bordered,
              block,
              sharp,
              subtle,
              [`align-${align}`]: align,
            },
            [color, size]
          )
        ),
        disabled: disabled || !clickable || loading,
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          if (stopPropagation) e.stopPropagation();
          if (clickable && !disabled && !loading) onClick?.(e);
        },
        ...props,
      };

      return React.createElement(
        el,
        elProps as any,
        <>
          {children && icon && !iconRight && <span className={bem("icon-left")}>{icon}</span>}
          <span className={bem("text")}>{children ?? icon}</span>
          {loading && (
            <span className={bem("loader")}>
              <Loader />
            </span>
          )}
          {children && icon && iconRight && <span className={bem("icon-right")}>{icon}</span>}
          <span className={bem("counter-scale")} />
        </>
      );
    }
  );
