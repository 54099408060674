import React from "react";

import models from "models";
import { Button } from "components";

import { Image } from "./Image";

export interface CategoryCardProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, "color"> {
  active?: boolean;
  data: models.Category;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ active, data, className, ...props }) => {
  return (
    <Button
      icon={<Image src={data.image?.url} width="1.5em" height="1.5em" alt="" />}
      color={active ? "primary" : "secondary"}
      size="lg"
      auto
      align="left"
      style={{ borderRadius: "1rem", fontWeight: "normal" }}
      bordered
      {...props}
    >
      {data.title}
    </Button>
  );
};
