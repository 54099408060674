import React from "react";

export const Like: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M9 10.5V19.5L10.1526 19.8842C11.3762 20.2921 12.6575 20.5 13.9473 20.5H17.5406C18.9706 20.5 20.2019 19.4906 20.4823 18.0883L21.2315 14.3424C21.6189 12.4054 20.0611 10.6293 18.0902 10.7607L14.5 11L14.821 7.4694C14.9171 6.41183 14.0844 5.5 13.0225 5.5C12.3881 5.5 11.8003 5.83282 11.4739 6.37677L9 10.5Z"
        fill={fill}
        fillRule="evenodd"
      />
      <rect
        height="11"
        width="5"
        fill={fill}
        opacity="0.3"
        rx="1"
        x="2"
        y="9.5"
      />
    </svg>
  );
};
