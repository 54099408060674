import React from "react";

import { FormInstance } from "rc-field-form";

import models from "models";
import { noop } from "utils";

interface SendGiftContextState {
  contactType: models.UserContactType;
  selectedUsers: Record<models.UserContactType, models.UserContact[]>;
  selectedProducts: models.ProductSendConfig[];
  sendMethodType: models.SendMethodType;
  quickSendContact?: models.UserContact;

  hasInitialProducts?: boolean;
}

export interface SendGiftContextValue extends SendGiftContextState {
  setContextValue: React.Dispatch<
    Partial<SendGiftContextState> | ((prev: SendGiftContextState) => Partial<SendGiftContextState>)
  >;
  messageForm?: FormInstance;
  quickSendForm?: FormInstance;
}

export const deafultSendGiftContextValue: SendGiftContextValue = {
  contactType: "clients",
  selectedProducts: [],
  selectedUsers: {
    clients: [],
    employees: [],
  },
  sendMethodType: models.SendMethodType.email,
  setContextValue: noop,
};

export const SendGiftContext = React.createContext<SendGiftContextValue>(deafultSendGiftContextValue);
