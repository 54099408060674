import React from "react";

export interface FlexProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "wrap"> {
  align?: React.CSSProperties["alignItems"];
  justify?: React.CSSProperties["justifyContent"];
  direction?: React.CSSProperties["flexDirection"];
  wrap?: true | React.CSSProperties["flexWrap"];
  gap?: React.CSSProperties["gap"];
  inline?: boolean;
}

export const Flex = React.forwardRef<HTMLDivElement, React.PropsWithChildren<FlexProps>>(
  ({ align, justify, direction, wrap, style, gap, inline, ...props }, ref) => {
    return (
      <div
        style={{
          display: inline ? "inline-flex" : "flex",
          justifyContent: justify,
          alignItems: align,
          flexDirection: direction,
          flexWrap: wrap === true ? "wrap" : wrap,
          gap,
          ...style,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
