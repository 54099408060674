import React from "react";

export const Client: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" fill="none" viewBox="0 0 188 188" {...svgProps}>
      <g fill={stroke} clipPath="url(#clip0_1361_3633)">
        <path d="M88.125 0h11.75c.47.103.94.228 1.414.305 4.825.734 9.719 1.193 14.467 2.276 36.205 8.277 64.684 38.584 70.819 75.215.573 3.426.955 6.885 1.425 10.329v11.75c-.103.474-.239.94-.308 1.421-.639 4.348-1.043 8.746-1.932 13.043-7.538 36.443-38.698 66.262-75.383 72.211-3.489.565-6.999.969-10.502 1.45h-11.75c-.408-.099-.811-.231-1.226-.294-4.293-.628-8.622-1.039-12.852-1.891-36.462-7.296-66.667-38.65-72.578-75.251C.896 107.013.485 103.437 0 99.875v-11.75c.599-4.164 1.05-8.353 1.836-12.484C8.812 38.228 40.075 7.623 77.62 1.447 81.104.874 84.622.477 88.125 0zM37.582 154.56c5.596-17.841 16.89-30.447 34.001-38.099-10.571-7.41-16.927-17.298-18.223-30.01-1.297-12.712 2.603-23.823 11.585-32.966 15.682-15.97 41.352-16.289 57.354-.76 8.001 7.765 12.327 17.257 12.521 28.442.265 14.977-6.242 26.562-18.359 35.32 17.137 7.663 28.417 20.32 33.976 38.103 22.031-19.92 33.656-54.307 21.77-88.42-11.445-32.84-43.798-55.547-78.967-55.143-35.959.396-67.298 23.463-78.34 57.986-10.541 32.962 1.194 66.314 22.682 85.547zm56.396 22.443c16.373-.143 31.398-4.561 45.164-13.355 1.498-.958 1.674-1.876 1.311-3.429-5.486-23.434-26.379-38.75-50.426-36.917-20.75 1.582-38.404 17.085-42.594 37.35-.275 1.333.04 2.071 1.212 2.824 13.82 8.926 28.956 13.395 45.333 13.527zm0-124.693a29.874 29.874 0 10-.044 59.748A29.874 29.874 0 0094 52.31h-.022z"></path>
        <path d="M151.516 113.868a1.751 1.751 0 01-.532-1.289v-14.79h-14.482a1.835 1.835 0 01-1.836-1.836v-4.08a1.833 1.833 0 011.836-1.836h14.482v-14.32a1.71 1.71 0 01.532-1.325 1.815 1.815 0 011.289-.496h4.55a1.702 1.702 0 011.341.495 1.707 1.707 0 01.494 1.34v14.321h14.482a1.839 1.839 0 011.836 1.836v4.094c.011.478-.167.942-.495 1.289a1.732 1.732 0 01-1.326.532h-14.511v14.787c.009.478-.169.94-.496 1.289a1.717 1.717 0 01-1.325.529h-4.55a1.768 1.768 0 01-1.289-.54z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1361_3633">
          <path fill="#fff" d="M0 0H188V188H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
