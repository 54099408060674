import React from "react";

import { useScrollTopPosition } from "hooks";
import { makeBEM } from "utils";

import { Icon } from "./Icon";
import { Button } from "./Button";

export interface ScrollToTopButtonProps {
  offset?: number;
}

const bem = makeBEM("sroll-to-top-button");

export const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ offset = 0 }) => {
  const visible = !useScrollTopPosition(offset);

  return (
    <>
      {visible && (
        <Button
          className={bem()}
          color="secondary"
          auto
          size="lg"
          icon={<Icon type="droparrow" className={bem("icon")} />}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      )}
    </>
  );
};
