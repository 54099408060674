import React from "react";
import { useQuery, useMutation } from "react-query";

import {
  Container,
  Flex,
  Icon,
  MutationConfirmModal,
  Table,
  WhiteSpace,
  PageContainer,
  LinkButton,
  QueryContent,
} from "components";
import api, { querykeys } from "api";
import { stringifyUrl } from "api/utils";
import { useModalState, useScreenBreakPoint } from "hooks";
import models from "models";
import { atoms } from "atoms";

import { autoGiftTableColumns } from "features/auto-gifts/config";
import { RequiredSettingsWrapper } from "features/common/components";

export const AutoGifts = () => {
  const query = useQuery(querykeys.autoGifts.grouped, api.autoGifts.get);

  const addModal = useModalState<models.AutoGiftPatch>(atoms.add);

  // Switch active / unactive
  const switchModal = useModalState<models.AutoGift>(atoms.edit);
  const deleteModal = useModalState<models.AutoGift>(atoms.delete);

  const employeesTableColumns = React.useMemo(
    () => autoGiftTableColumns({ onActiveSwitch: switchModal.openWith, onDeleteClick: deleteModal.openWith }),
    [switchModal.openWith, deleteModal.openWith]
  );
  const clientsTableColumns = React.useMemo(
    () => autoGiftTableColumns({ onActiveSwitch: switchModal.openWith, onDeleteClick: deleteModal.openWith }),
    [switchModal.openWith, deleteModal.openWith]
  );

  const deleteMutation = useMutation(() => api.autoGifts.delete(deleteModal.data?.id || 0), {
    onSuccess: () => {
      query.refetch();
    },
  });

  const switchMutation = useMutation(
    () => api.autoGifts.patch(switchModal.data?.id || 0, { active: !switchModal.data?.active }),
    {
      onSuccess: () => query.refetch(),
    }
  );

  const onTablet = useScreenBreakPoint("tablet");

  const getTitle = (content: React.ReactNode, contact_type: models.UserContactType) => () =>
    (
      <Flex className="py-4 px-6" justify="end" align="center" gap="1rem" wrap>
        <h3 className="flex-1" style={{ flex: onTablet ? "100%" : "" }}>
          {content}
        </h3>
        <LinkButton
          to={stringifyUrl(`/auto-gifts/new`, { contact_type })}
          auto
          icon={<Icon type="plus" />}
          onClick={() => addModal.openWith({ contact_type })}
        >
          Add automated gift
        </LinkButton>
      </Flex>
    );

  return (
    <PageContainer>
      <RequiredSettingsWrapper>
        <Container size={970} mauto>
          <QueryContent query={query}>
            <WhiteSpace v="2rem" />

            <Table
              columns={employeesTableColumns}
              data={query.data?.employees}
              title={getTitle("Automatic Rewards for Employees", "employees")}
              variants={["elevated"]}
              rowKey="id"
            />
            <WhiteSpace v="2rem" />

            <Table
              columns={clientsTableColumns}
              data={query.data?.clients}
              title={getTitle("Automatic Rewards for Clients and Customers", "clients")}
              variants={["elevated"]}
              rowKey="id"
            />
            <MutationConfirmModal open={switchModal.isOpen} onClose={switchModal.close} mutation={switchMutation}>
              Are you sure to {switchModal.data?.active ? "deactivate" : "activate"} this automatic gift?
            </MutationConfirmModal>
          </QueryContent>
        </Container>
        <MutationConfirmModal open={deleteModal.isOpen} mutation={deleteMutation} onClose={deleteModal.close}>
          Are you sure to delete this automated gift?
        </MutationConfirmModal>
      </RequiredSettingsWrapper>
    </PageContainer>
  );
};
