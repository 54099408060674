import { useState } from "react";

import { NullableElement } from "types";

import { useResizeObserver } from "./useResizeObserver";
import { BreakPointKey, breakPointSize, isBreakPointKey } from "./useScreenBreakPoint";
import { useStateRef } from "./useStateRef";

export function useElementBoxBreakPoint(el: NullableElement, breakPoint: BreakPointKey | number) {
  breakPoint = isBreakPointKey(breakPoint) ? breakPointSize[breakPoint] : breakPoint;

  const [state, setState] = useState(false);

  const stateRef = useStateRef(state);

  useResizeObserver([el], (entries) => {
    const firstEntry = entries[0];
    const contentBoxSize: ResizeObserverSize = Array.isArray(firstEntry.contentBoxSize)
      ? firstEntry.contentBoxSize[0]
      : firstEntry.contentBoxSize;

    const newState = contentBoxSize.inlineSize <= breakPoint;
    if (newState !== stateRef.current) setState(contentBoxSize.inlineSize <= breakPoint);
  });

  return state;
}
