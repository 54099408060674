import React from "react";

export const PhoneBook: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M17.5,17.5s-2.02-.892-3.432-1.415c-.926-.344-.794-1.907-.258-2.494a4.283,4.283,0,0,0,1.259-3.531A3,3,0,0,0,12,6.78a3,3,0,0,0-3.069,3.279A4.283,4.283,0,0,0,10.19,13.59c.536.587.668,2.15-.258,2.494C8.52,16.607,6.5,17.5,6.5,17.5"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5,22.5a1,1,0,0,1-1,1H3.5V.5h16a1,1,0,0,1,1,1Z"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5.5h-2a1,1,0,0,0-1,1v21a1,1,0,0,0,1,1h2"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5,2.5h1a2.006,2.006,0,0,1,2,2v2h-3"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 6.5L23.5 6.5 23.5 11.5 20.5 11.5"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 11.5L23.5 11.5 23.5 16.5 20.5 16.5"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5,16.5h3v2a2.006,2.006,0,0,1-2,2h-1"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
