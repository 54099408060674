import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "api/axios";
import { stringifyUrl } from "api/utils";

export const products = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/products/`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Product>>(url);

    return data;
  },
  grouped: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/products/all/`, query);
    const { data } = await apiAxios.get<models.GroupedProductListCategory[]>(url);

    return data;
  },
};
