import React from "react";

export const Delivery: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M24.2667 28H12.2667C10.2667 28 8.80001 26.4 8.93334 24.4L10 12.9334C10.2667 11.2 11.6 9.8667 13.3333 9.8667H23.2C24.9333 9.8667 26.2667 11.2 26.5333 12.8L27.6 24.2667C27.7333 26.4 26.2667 28 24.2667 28Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3334 13.8667V7.33333C13.3334 5.46667 14.8 4 16.6667 4H20.4C22.2667 4 23.7334 5.46667 23.7334 7.33333V13.8667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M4.40002 16.6667H13.6"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M6.53333 19.7334H13.6"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
