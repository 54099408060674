import { Dispatch } from "react";

import { ProductListGroupProps } from "components";
import models from "models";

export const createProductPropsGetter =
  (
    selectedProducts: models.ProductSendConfig[],
    setSelectedProducts: Dispatch<models.ProductSendConfig[]>,
    onCharacteristicChoose: Dispatch<models.Product>
  ): ProductListGroupProps["getProductProps"] =>
  (product) => ({
    choice: selectedProducts.find((config) => config.product.id === product.id)?.product_characteristic_choices,
    selectable: true,
    selected: !!selectedProducts.find((config) => config.product.id === product.id),
    onClick: () => {
      if (selectedProducts.find((config) => config.product.id === product.id)) {
        setSelectedProducts(selectedProducts.filter((config) => config.product.id !== product.id));
      } else if (product.characteristic_types?.length) {
        onCharacteristicChoose(product);
      } else {
        setSelectedProducts([...selectedProducts, { product }]);
      }
    },
  });
