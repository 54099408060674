import React from "react";
import cn from "classnames";
import { makeBEM } from "utils";
import { ComponentSize } from "./types";

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: ComponentSize;
  spread?: boolean;
}

const bem = makeBEM("loader");

export const Loader: React.FC<LoaderProps> = ({ size = "sm", spread, className, children, ...props }) => {
  return (
    <div className={cn(className, bem(null, { spread }, [size]))} {...props}>
      <div className={bem("circle-2")}></div>
      <div className={bem("circle-1")}></div>
    </div>
  );
};
