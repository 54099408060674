import $axios from "axios";

import { API_POST_CONTENT_TYPE } from "app-constants";

import { authInterceptor, cancelInterceptors } from "./interceptors";

export const apiAxios = $axios.create();

apiAxios.defaults.headers.post["Content-Type"] = API_POST_CONTENT_TYPE;
apiAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;

apiAxios.interceptors.request.use(authInterceptor);
apiAxios.interceptors.response.use(...cancelInterceptors);
