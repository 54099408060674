import React from "react";

export const Notifications: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M11.3334 26.4002C10.1334 25.8668 9.7334 24.5335 10.1334 23.3335L14.2667 25.2002C13.8667 26.5335 12.5334 26.9335 11.3334 26.4002Z"
        fill={fill}
      />
      <path
        d="M22.4 21.6001C21.6 21.2001 21.2 20.2668 21.6 19.3335L23.6 14.8001C24.8 12.2668 24 9.33346 22 7.46679L22.4 6.53346C22.8 5.60012 22.4 4.53346 21.4667 4.13346C20.5334 3.73346 19.4667 4.13346 19.0667 5.06679L18.6667 6.13346C16 5.73346 13.3334 7.20012 12.1334 9.73346L10.1334 14.2668C9.73338 15.0668 8.80005 15.4668 7.86671 15.0668C7.06671 14.6668 6.00005 15.0668 5.60005 15.8668L5.06671 17.0668C4.66671 17.8668 5.06671 18.9335 5.86671 19.3335L20.4 26.0001C21.2 26.4001 22.2667 26.0001 22.6667 25.2001L23.2 24.0001C23.6 23.0668 23.2 22.0001 22.4 21.6001Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M7.86646 14.9333L22.3998 21.6"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
