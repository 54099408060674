import api, { querykeys } from "api";
import { TemplateConfig } from "features/users/types";

export const clientsTemplateConfig: TemplateConfig = {
  usersApi: api.companies.createUsersApi("clients"),
  querykeys: {
    users: querykeys.companies.users.clients,
    groups: querykeys.companies.groups.companies,
  },
  name: {
    plural: "clients and customers",
    singular: "client or customer",
  },
  groupName: { plural: "companies", singular: "company" },
  contactType: "clients",
};
