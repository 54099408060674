import { FormInstance } from "ebs-design";

export const videoOrCommentRule = (form: FormInstance) => ({
  validator: (_: unknown, value: string) => {
    if (!form.getFieldValue("video")?.length && !value?.trim?.()) {
      return Promise.reject("Please write a message or upload a video");
    }
    return Promise.resolve();
  },
});
