import React from "react";

import { Container, Button, Icon, Flex, WhiteSpace, SearchInput } from "components";
import { capitalized } from "utils";

import { TableCardHeader } from "features/common/components";
import { usePageTemplate } from "features/users/hooks";

import { GroupSelect } from ".";

export interface UsersCardHeaderProps {
  count?: number;
  search: string;
  setSearch: React.Dispatch<string>;
  onAddNewClick: () => void;
  onGroupsClick: () => void;
  onCsvClick: () => void;
  group: string | undefined;
  setGroup: React.Dispatch<string | undefined>;
}

export const UsersCardHeader = ({
  count,
  search,
  setSearch,
  onAddNewClick,
  onGroupsClick,
  onCsvClick,
  group,
  setGroup,
}: UsersCardHeaderProps) => {
  const template = usePageTemplate();

  return (
    <>
      <Flex justify="end" gap="1rem" wrap="wrap-reverse">
        <Button onClick={onCsvClick} bordered>
          Upload CSV File
        </Button>
        <Button bordered onClick={onGroupsClick}>
          Manage {template.groupName.plural}
        </Button>
        <Button icon={<Icon type="plus" />} onClick={onAddNewClick} auto>
          Add new {template.name.singular}
        </Button>
      </Flex>
      <WhiteSpace v="2.5rem" />

      <TableCardHeader
        title={capitalized(template.name.plural)}
        count={count}
        filters={(isSmallScreen) => (
          <>
            <SearchInput value={search} onChange={setSearch} />

            <Container size={!isSmallScreen ? 200 : undefined} fixed={!isSmallScreen}>
              <GroupSelect
                isClearable
                placeholder={capitalized(template.groupName.singular)}
                value={group}
                onChange={(v) => (v === undefined || typeof v === "string") && setGroup(v)}
              />
            </Container>
          </>
        )}
      />
    </>
  );
};
