import React from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";

import { Modal, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import * as dateFNS from "date-fns";

import { notifyErrors } from "utils";
import models from "models";
import api, { querykeys } from "api";
import { QueryList, MutationConfirmModal, WhiteSpace, Flex, Button, Icon } from "components";
import { MONTH_DAY_FORMAT } from "app-constants";

import { AddPaymentMethodForm, PaymentMethodCard } from "features/payments/components";

import { useAutoGiftEditContext } from "../hooks";

export interface PaymentMethodModalProps extends ModalProps {
  isCustomAutoGift: boolean;
  apiCall: (payload: models.AutoGiftPatch) => Promise<models.AutoGift>;
}

export const PaymentMethodModal = ({ isCustomAutoGift, apiCall, ...props }: PaymentMethodModalProps) => {
  const [isAddPMLoading, setIsAddPMLoading] = React.useState(false);

  const { form, selectedProduct, sendMethodType, contactType } = useAutoGiftEditContext();

  const notify = useNotify();
  const stripe = useStripe();
  const history = useHistory();

  /** Payment method */
  const [selectedPM, setSelectedPM] = React.useState<models.PaymentMethod>();

  const [deleteModalData, setDeleteModalData] = React.useState<models.PaymentMethod>();

  const query = useQuery(querykeys.paymentMethods.many(), api.paymentMethods.getList, {
    select: (data) => data?.filter((paymentMethod) => paymentMethod.type === models.PaymentMethodType.card),
  });
  const deleteMutation = useMutation(() => api.paymentMethods.delete(deleteModalData?.id || ""), {
    onError: (error) => notifyErrors(notify, error),
    onSuccess: () => {
      query.refetch();
      if (selectedPM?.id === deleteModalData?.id) setSelectedPM(undefined);
    },
  });

  React.useEffect(() => {
    if (query.isSuccess) setSelectedPM(query.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess]);

  const mutation = useMutation(
    async (payload: models.AutoGiftPatch) => {
      const response = await api.orders.setupPaymentIntent(payload.payment_method || "");
      const { error } =
        (await stripe?.confirmCardSetup(response?.client_secret || "", {
          payment_method: selectedPM?.id,
        })) || {};
      if (error) throw new Error(error.message);

      await apiCall(payload);
    },
    {
      onError: (error) => notifyErrors(notify, error),
      onSuccess: () => {
        history.push("/auto-gifts");
      },
    }
  );

  const addPMSuccessHandler = (pm: models.PaymentMethod) => {
    setSelectedPM(pm);
    query.refetch();
    submit(pm?.id);
  };

  const submit = (pmid?: string) => {
    const { title, timer, message }: models.AutoGift = form?.getFieldsValue();

    const choiceId = selectedProduct?.product_characteristic_choices?.id;

    mutation.mutate({
      payment_method: pmid,
      ...(isCustomAutoGift
        ? {
            timer: dateFNS.parse(timer || "", MONTH_DAY_FORMAT, new Date()).toISOString(),
            title,
            trigger: models.AutoGiftTrigger.custom,
          }
        : {}),
      message,
      product: selectedProduct?.product?.id || 0,
      product_characteristic_choices: choiceId ? [choiceId] : undefined,
      notification_method: sendMethodType,
      contact_type: contactType,
      description: "Created by me",
    });
  };

  return (
    <>
      <Modal size="small" {...props}>
        <Modal.Content>
          <QueryList
            query={query}
            getItems={(data) => data}
            renderItems={(items?: models.PaymentMethod[]) => (
              <>
                <h2>Select a payment method</h2>
                <WhiteSpace v="1rem" />
                {items?.map((paymentMethod) => (
                  <PaymentMethodCard
                    onClick={() => setSelectedPM(paymentMethod)}
                    selectable
                    selected={paymentMethod.id === selectedPM?.id}
                    className="my-3"
                    data={paymentMethod.data}
                    onRemoveClick={() => setDeleteModalData(paymentMethod)}
                  />
                ))}
                <WhiteSpace v="2rem" />

                <Flex justify="center">
                  <Button
                    loading={mutation.isLoading}
                    disabled={!selectedPM || !stripe || isAddPMLoading}
                    onClick={() => submit(selectedPM?.id)}
                    icon={<Icon type="card" />}
                  >
                    Pay now
                  </Button>
                </Flex>

                <WhiteSpace v="3rem" />
                <Flex align="center">
                  <hr className="flex-1" />
                  <WhiteSpace h="1rem" />
                  <h2>OR</h2>
                  <WhiteSpace h="1rem" />
                  <hr className="flex-1" />
                </Flex>
                <WhiteSpace v="3rem" />
              </>
            )}
            emptyText={<></>}
          />

          <h2>Add and pay with a new payment method</h2>
          <WhiteSpace v="1rem" />
          <AddPaymentMethodForm
            disabled={mutation.isLoading}
            onLoadingChange={setIsAddPMLoading}
            onSuccess={addPMSuccessHandler}
          />
          <WhiteSpace v="1rem" />
        </Modal.Content>
      </Modal>
      <MutationConfirmModal
        open={!!deleteModalData}
        mutation={deleteMutation}
        onClose={() => setDeleteModalData(undefined)}
      >
        Are you sure to remove card with number <b>****** {deleteModalData?.data?.last4}</b> ?
      </MutationConfirmModal>
    </>
  );
};
