import React from "react";

import { ProductFiltersProps } from "components";
import models from "models";

import { useSet } from "./useSet";
import { filterGroupedProducts } from "utils";

export function useProductFilters(
  groupedProducts: models.GroupedProductListCategory[] | undefined,
  onClose: () => void
) {
  const [search, setSearch] = React.useState("");
  const [categories, categoriesActions] = useSet<number>();
  const [redeemTypes, redeemTypesActions] = useSet<models.ProductReedemType>();
  const [priceRanges, priceRangesActions] = useSet<number>();

  const productFilterProps: ProductFiltersProps = {
    onClose,
    search,
    onSearchChange: setSearch,
    activeCategories: Array.from(categories),
    onCategoryClick: categoriesActions.toggle,
    categories: groupedProducts || [],
    activeRedeemTypes: Array.from(redeemTypes),
    onRedeemTypeClick: redeemTypesActions.toggle,
    activePriceRanges: Array.from(priceRanges),
    onPriceRangeClick: priceRangesActions.toggle,
    onFiltersClear: () => {
      setSearch("");
      categoriesActions.reset();
      redeemTypesActions.reset();
      priceRangesActions.reset();
    },
  };

  return {
    productFilterProps,
    filteredProducts: filterGroupedProducts(groupedProducts || [], {
      search,
      activePriceRanges: Array.from(priceRanges),
      activeRedeemTypes: Array.from(redeemTypes),
      activeCategories: Array.from(categories),
    }),
  };
}

// RECYCLE
// const productFilterProps: ProductFiltersProps = {
//   onClose: React.useCallback(() => setOpen(false), [setOpen]),
//   activeCategories: React.useMemo(() => Array.from(categories), [categories]),
//   onCategoryClick: categoriesActions.toggle,
//   categories: React.useMemo(() => query.data || [], [query.data]),
//   activeRedeemTypes: React.useMemo(() => Array.from(redeemTypes), [redeemTypes]),
//   onRedeemTypeClick: redeemTypesActions.toggle,
//   activePriceRanges: React.useMemo(() => Array.from(priceRanges), [priceRanges]),
//   onPriceRangeClick: priceRangesActions.toggle,
// };
