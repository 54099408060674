import React from "react";

import { useScreenBreakPoint } from "hooks";
import { makeBEM } from "utils";

import { Button } from "./Button";
import { Icon } from "./Icon";

export interface HorizontalTabPanelProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, "title"> {
  active?: boolean;
  completed?: boolean;

  number: React.ReactNode;
  title: React.ReactNode;
  mobileTitle?: React.ReactNode;
  subTitle: React.ReactNode;

  nextDisabled?: boolean;
  onNext?: () => void;
}

const bem = makeBEM("horizontal-tab-panel");

export const HorizontalTabPanel: React.FC<HorizontalTabPanelProps> = ({
  active,
  completed,
  number,
  title,
  mobileTitle = title,
  subTitle,
  children,
  onClick,
  nextDisabled,
  onNext,
  ...props
}) => {
  const onMobile = useScreenBreakPoint("tablet");

  completed = completed && !active;

  return (
    <div className={bem("wrapper")}>
      <button
        className={bem(null, { completed, active })}
        onClick={(e) => {
          completed && onClick?.(e);
        }}
        {...props}
      >
        <div className={bem("number")}>{number}</div>
        <div className={bem("title-container")}>
          <div className={bem("title")}>{onMobile ? mobileTitle : title}</div>
          <div className={bem("subtitle")}>{subTitle}</div>
        </div>
      </button>
      <div className={bem("next-button-wrapper", { visible: !!onNext })}>
        <Button onClick={onNext} disabled={nextDisabled} auto rounded>
          <Icon type="back" size="1.2rem" style={{ transform: "rotate(180deg)" }} />
        </Button>
      </div>
    </div>
  );
};
