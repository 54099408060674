import React from "react";
import { ColorMapKey, getColor } from "components";

import { iconList } from "./iconList";

export type IconType = keyof typeof iconList;
export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  type: IconType;
  size?: number | string;
  color?: ColorMapKey;
}

export const Icon = ({
  type,
  size = "1.5em",
  className = "",
  style: propsStyle = {},
  color,
  ...svgProps
}: IconProps) => {
  const IconComponent = iconList[type];
  const style: React.CSSProperties = {
    fontSize: size,
    strokeWidth: 2,
    color: getColor(color),
  };
  Object.assign(style, propsStyle);
  return <IconComponent {...svgProps} style={style} className={`ebs-icon ${className}`} />;
};
