import { Attachment } from "./attachments";

export interface Product {
  id?: number;
  title?: string;
  featured_image?: Attachment;
  image?: Attachment;
  images?: Attachment[];
  description?: string;
  short_description?: string;
  cost?: number;
  price?: number;
  brand?: Brand;
  types?: ProductReedemType[];
  characteristic_types?: ProductCharacteristic[];

  // RECYCLE
  // parameters?: {};
  // age_limit?: number;
  // is_active?: boolean;
  // expiring_months?: number;
  // type?: ProductType;
  // categories?: CategoryEntity[];
}

export enum ProductReedemType {
  delivery = "DELIVERY",
  store = "STORE",
}

export interface Brand {
  id?: number;
  title?: string;
  image?: Attachment;
  timestamp?: string;
  description?: string;
  identifier?: string;
}

export interface ProductSendConfig {
  product: Product;
  product_characteristic_choices?: ProductCharacteristicChoice;
}

export interface ProductSendConfigPost {
  product: number;
  product_characteristic_choices?: readonly number[];
}

export interface GroupedProductListSubcategory {
  id?: number;
  image?: Attachment;
  products?: Product[];
  title?: string;
  products_count?: number;
}
export interface GroupedProductListCategory {
  id?: number;
  image?: Attachment;
  sub_categories?: GroupedProductListSubcategory[];
  title?: string;
}

export interface ProductCharacteristic {
  choices?: ProductCharacteristicChoice[];
  characteristic_title?: string;
  representation_type?: ProductCharacteristicRepresantationType;
}

export interface ProductCharacteristicChoice {
  id?: number;
  value?: string;
  price?: number;
}

export enum ProductCharacteristicRepresantationType {
  horizontalList = "horizontal_list",
  verticalList = "vertical_list",
  dropdown = "dropdown",
}
