import { Button, LinkButton, Flex, WhiteSpace, SearchInput } from "components";
import { TableCardHeader } from "features/common/components";

export interface OrdersCardHeaderProps {
  count?: number;
  search: string;
  setSearch: React.Dispatch<string>;
  onMonthlyInvoiceClick: () => void;
}

export const OrdersCardHeader = ({ count, search, setSearch, onMonthlyInvoiceClick }: OrdersCardHeaderProps) => {
  return (
    <>
      <Flex justify="end" gap="1rem">
        <Button bordered onClick={onMonthlyInvoiceClick}>
          Monthly invoice
        </Button>
        <LinkButton to="/send-gift">Send gift</LinkButton>
      </Flex>
      <WhiteSpace v="2.5rem" />

      <TableCardHeader
        title="Orders"
        count={count}
        filters={
          <>
            <SearchInput value={search} onChange={setSearch} />
          </>
        }
      />
    </>
  );
};
