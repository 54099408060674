import $axios from "axios";

import { API_POST_CONTENT_TYPE } from "app-constants";

export const authAxios = $axios.create();

authAxios.defaults.headers.post["Content-Type"] = API_POST_CONTENT_TYPE;
authAxios.defaults.baseURL = process.env.REACT_APP_BASE_SSO;
authAxios.defaults.headers["Service-Token"] =
  process.env.REACT_APP_SSO_SERVICE_TOKEN;
authAxios.defaults.withCredentials = true;
