import React from "react";

export const Account: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 -30.5 187 156.5"
      {...svgProps}
    >
      <g fill={stroke} clipPath="url(#clip0_1361_3627)">
        <path d="M178.182 53.898a92.685 92.685 0 00-16.927-24.82 93.666 93.666 0 00-23.364-17.853 93.253 93.253 0 00-27.717-9.74 91.755 91.755 0 00-29.991-.475c-25.515 3.807-46.37 16.316-61.976 37.189C8.9 50.642 3.07 64.972.876 80.814a94.095 94.095 0 00-.485 21.268c.397 4.448 2.592 6.521 6.894 6.521h.032c8.683-.023 17.367-.048 26.05-.076h2.774c3.01 0 6.122 0 9.192-.069 1.898-.034 3.57-.781 4.702-2.099a6.094 6.094 0 001.363-4.839c-.4-3.097-2.965-5.18-6.38-5.182-8.038-.01-16.076-.01-24.113 0h-8.553c-.72-11.167 1.53-23.458 6.398-34.795a81.938 81.938 0 0129.14-35.327 80.14 80.14 0 0144.7-13.987 81.622 81.622 0 0145.393 13.275 80.852 80.852 0 0129.874 34.963c5.148 11.584 7.549 24.257 6.821 35.875h-32.276c-.375 0-.763 0-1.17.021a6.062 6.062 0 00-3.591 1.456 6.088 6.088 0 00-1.981 3.337c-.556 2.823.855 5.645 3.355 6.712a8.58 8.58 0 003.228.611c11.838.064 23.532.099 36.496.134h.101c2.578 0 4.456-.261 5.809-1.51 1.354-1.249 1.783-3.15 2.006-5.788 1.393-16.42-1.459-32.37-8.473-47.417z"></path>
        <path d="M114.605 86.62c-5.718-6.479-12.317-12.742-20.177-19.143-5.844-4.758-11.903-9.43-17.763-13.948a2546.752 2546.752 0 01-4.42-3.408c-2.829-2.189-5.552-2.535-8.089-1.016-1.629.972-3.433 2.959-2.785 6.901l.517 3.155c1.18 7.191 2.397 14.63 3.806 21.918a186.404 186.404 0 007.719 28.161c2.068 5.719 5.534 10.097 10.27 13.014 4.86 2.996 9.865 3.687 13.206 3.747h.06a26.777 26.777 0 009.652-1.931 23.81 23.81 0 0011.752-10.291 22.725 22.725 0 002.558-15.208c-.759-4.148-2.762-7.934-6.306-11.95zm-27.381 22.477c-1.255-1.793-2.068-4.081-2.868-6.296l-.016-.046c-3.546-9.908-5.695-20.003-7.49-29.43a504.373 504.373 0 01-.941-5.148l.861.675c7.88 6.222 17.026 13.704 25.134 22.122 1.045 1.085 2.068 2.26 3.056 3.396.441.507.884 1.012 1.328 1.516a10.235 10.235 0 012.383 8.501 11.176 11.176 0 01-5.556 7.885c-5.467 3.138-12.449 1.737-15.891-3.175z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1361_3627">
          <path fill="#fff" d="M0 0H187V126H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
