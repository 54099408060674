import React from "react";

export const Home2: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 28.0001H6.66667C5.2 28.0001 4 26.8001 4 25.3334V16.2667C4 14.6667 4.66667 13.0667 6 12.1334L14.2667 5.3334C15.2 4.5334 16.6667 4.5334 17.6 5.3334L26 12.1334C27.3333 13.0667 28 14.6667 28 16.2667V25.3334C28 26.8001 26.8 28.0001 25.3333 28.0001H12Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12 28.0002V21.2002C12 18.9335 13.7333 17.2002 16 17.2002C18.2667 17.2002 20 18.9335 20 21.2002V28.0002"
        stroke={stroke}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
