import { useEffect, useRef } from "react";

import { useStateRef } from "hooks";
import { NullableElement } from "types";

export function useResizeObserver(entries: NullableElement[], callback: ResizeObserverCallback) {
  const resizeObserverRef = useRef<ResizeObserver>();

  const callbackRef = useStateRef(callback);

  if (!resizeObserverRef.current)
    resizeObserverRef.current = new ResizeObserver((...args) => callbackRef.current(...args));

  const resizeObserver = resizeObserverRef.current;

  useEffect(() => {
    resizeObserver.disconnect();
    entries.forEach((el) => el && resizeObserver.observe(el));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...entries]);
}
