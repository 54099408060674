import { PageContainer, VerticalTabs, WhiteSpace, WithInfoIcon } from "components";

import { settingsTabPanels, settingsTabContents } from "features/settings/config";

export const Settings = () => {
  return (
    <PageContainer>
      <WithInfoIcon>
        You must complete your account and business details before being able to send any gift
      </WithInfoIcon>
      <WhiteSpace v="2rem" />

      <VerticalTabs.Container panels={settingsTabPanels} defaultActive="account">
        {settingsTabContents.map(({ tabKey, content }) => (
          <VerticalTabs.Content key={tabKey} tabKey={tabKey}>
            {content}
          </VerticalTabs.Content>
        ))}
      </VerticalTabs.Container>
    </PageContainer>
  );
};
