import React from "react";

export const Home: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 28H6.66667C5.2 28 4 26.7622 4 25.2493V15.8968C4 14.2464 4.66667 12.596 6 11.6332L14.2667 4.61891C15.2 3.7937 16.6667 3.7937 17.6 4.61891L26 11.6332C27.3333 12.596 28 14.2464 28 15.8968V25.2493C28 26.7622 26.8 28 25.3333 28H12Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12 23.8667V20C12 17.7333 13.7333 16 16 16C18.2667 16 20 17.7333 20 20V23.8667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
