import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const brands = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/brands/list/`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Brand>>(url);
    data?.results?.sort?.((a, b) => ((a?.title || "") < (b?.title || "") ? -1 : 1));
    return data;
  },
};
