import React from "react";
import { Flex } from "./Flex";

export interface WithOptionalProps {}

export const WithOptional: React.FC<WithOptionalProps> = ({ children }) => {
  return (
    <Flex align="center" gap="0.5rem" style={{ marginBottom: "0.5rem" }}>
      <div>{children}</div>
      <div className="badge">optional</div>
    </Flex>
  );
};
