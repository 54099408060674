import React from "react";
import { useMutation, useQueryClient } from "react-query";

import { Modal, Form, useNotify, useForm } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, WhiteSpace, Button, Icon, AnchorButton, ORDivider } from "components";
import { useModalState, useScreenBreakPoint } from "hooks";
import { notifyErrors } from "utils";
import models from "models";
import { atoms } from "atoms";

import { usePageTemplate } from "features/users/hooks";

import { AddGroupModal, AddedUserCard, UserFormFields } from ".";
import { importExampleHref } from "../config";

export interface AddUserModalProps extends ModalProps {
  onCsvClick: () => void;
}

export const AddUserModal = ({ onCsvClick, ...props }: AddUserModalProps) => {
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const template = usePageTemplate();
  const addGroupModal = useModalState<models.Group>(atoms.addGroup);

  const [justAddedGroup, setJustAddedGroup] = React.useState<models.Group>();

  const [justAddedUsers, setJustAddedUsers] = React.useState<models.UserContact[]>([]);

  const postMutation = useMutation(template.usersApi.post, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(template.querykeys.users.many());
      notify.success({ title: "", description: "User added successfully" });
      setJustAddedUsers((prev) => [data, ...prev].slice(0, 3));
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: models.UserContact) => {
    postMutation.mutate(
      { ...values, phone: values.phone ? "+" + values.phone : undefined },
      { onSuccess: () => form.resetFields() }
    );
  };

  const onTablet = useScreenBreakPoint("tablet");

  return (
    <>
      <Modal size="small" {...props}>
        <Modal.Content>
          <h1>Add {template.name.singular}</h1>
          <p>Add manually users here - If you want to add more upload a CSV in the bottom right</p>
          <WhiteSpace v="1rem" />
          <hr />
          <WhiteSpace v="2rem" />
          {justAddedUsers.map((user) => (
            <div key={user.id} className="py-1">
              <AddedUserCard user={user} />
            </div>
          ))}
          <WhiteSpace v="2rem" />
          <Form form={form} onFinish={submitHandler}>
            <UserFormFields {...{ justAddedGroup }} onAddGroupClick={(s) => addGroupModal.openWith({ name: s })} />

            <Flex justify="end" gap="1rem">
              <Button auto={onTablet} bordered color="secondary" onClick={props.onClose} type="button">
                Close
              </Button>
              <Button auto={onTablet} loading={postMutation.isLoading} icon={<Icon type="edit" />}>
                Save
              </Button>
            </Flex>
          </Form>
          <WhiteSpace v="3rem" />
          <ORDivider />
          <WhiteSpace v="1rem" />
          <h3>Add multiple recipients</h3>
          <WhiteSpace v="1rem" />
          <Flex gap="1rem">
            <AnchorButton
              href={importExampleHref(template.contactType)}
              target="_blank"
              rel="noopener noreferrer"
              auto
              size={onTablet ? "md" : "lg"}
              className="flex-1"
            >
              Download template
            </AnchorButton>

            <Button
              className="flex-1"
              onClick={() => {
                props.onClose?.();

                // Need timeout because hash states are conflicting
                window.setTimeout(() => {
                  onCsvClick();
                }, 150);
              }}
              size={onTablet ? "md" : "lg"}
              auto
            >
              Upload CSV File
            </Button>
          </Flex>
        </Modal.Content>
      </Modal>
      <AddGroupModal
        data={addGroupModal.data}
        open={addGroupModal.isOpen}
        onClose={addGroupModal.close}
        onSuccess={(group) => {
          form.setFieldsValue({ group: group?.id });
          setJustAddedGroup(group);
        }}
      />
    </>
  );
};
