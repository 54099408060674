import React from "react";

export const Birthday: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M22.8758 28H9.01242C7.89441 28 7 27.0459 7 25.8532V17.1468C7 15.9541 7.89441 15 9.01242 15H22.9876C24.1056 15 25 15.9541 25 17.1468V25.8532C24.8882 27.0459 23.9938 28 22.8758 28Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M25 20.5C24.3292 19.8333 23.2112 19.8333 22.4286 20.5C21.7578 21.1667 20.6398 21.1667 19.8571 20.5C19.1863 19.8333 18.0683 19.8333 17.2857 20.5C16.6149 21.1667 15.4969 21.1667 14.7143 20.5C14.0435 19.8333 12.9255 19.8333 12.1429 20.5C11.472 21.1667 10.354 21.1667 9.57143 20.5C8.90062 19.8333 7.78261 19.8333 7 20.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M17 12H15V15H17V12Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.4263 10C19.0734 9.3617 19.2351 8.34043 18.5881 7.44681L16 4L13.4119 7.44681C12.7649 8.34043 12.9266 9.23404 13.5737 10"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
