import React from "react";

import imageNotFoundSrc from "./image-not-found.svg";

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  src?: string;
  width?: string | number;
  height?: string | number;
  borderRadius?: React.CSSProperties["borderRadius"];
  objectFit?: React.CSSProperties["objectFit"];
}

export const Image = ({
  src,
  alt,
  className,
  width,
  height,
  style: propsStyle,
  borderRadius,
  objectFit,
  ...restProps
}: ImageProps) => {
  const [isError, setIsError] = React.useState(false);

  const showImage = !isError && !!src;

  React.useLayoutEffect(() => setIsError(false), [src]);

  const style = {
    width,
    height,
    borderRadius,
    objectFit,
    ...propsStyle,
  };

  return showImage ? (
    <img className="image" {...restProps} style={style} src={src} alt={alt} onError={() => setIsError(true)} />
  ) : (
    <img className="image" {...restProps} style={{ ...style, objectFit: "contain" }} src={imageNotFoundSrc} alt={alt} />
  );
};
