import { useQueryClient, useMutation } from "react-query";

import { useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import models from "models";
import { capitalized, notifyErrors } from "utils";

import { usePageTemplate } from "features/users/hooks";
import { GroupModal } from ".";

export interface EditGroupModalProps extends ModalProps {
  data?: models.Group;
}

export const EditGroupModal = ({ data, ...props }: EditGroupModalProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const template = usePageTemplate();

  const patchMutation = useMutation(
    (group: models.Group) =>
      template.usersApi.groups.patch(data?.id || 0, group || {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(template.querykeys.groups.many());
        notify.success({
          title: "",
          description:
            capitalized(template.groupName.singular) + " updated successfully",
        });
        props?.onClose?.();
      },
      onError: (error) => notifyErrors(notify, error),
    }
  );

  return (
    <GroupModal
      mutation={patchMutation}
      title={"Edit " + template.groupName.singular}
      submitBtnText="Save"
      data={data}
      {...props}
    />
  );
};
