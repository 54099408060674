import React from "react";
import cn from "classnames";
import { makeBEM } from "utils";

export interface TabCardProps extends React.HTMLAttributes<HTMLDivElement> {
  centerVertically?: boolean;
}

const bem = makeBEM("send-gift-tab-card");

export const TabCard: React.FC<TabCardProps> = ({ className, centerVertically, ...props }) => {
  return <div className={cn(className, bem(null, { "center-vertically": centerVertically }))} {...props} />;
};
