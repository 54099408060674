import React from "react";

export const SendArrow: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 -25.5 158 158"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="M63.0659 50.951L46 13L141 50.951L46 94L63.0659 50.951Z" stroke={stroke} strokeWidth="13" />
      <line x1="17.5" y1="52.5" x2="32.5" y2="52.5" stroke={stroke} strokeWidth="13" strokeLinecap="round" />
      <line x1="6.5" y1="26.5" x2="21.5" y2="26.5" stroke={stroke} strokeWidth="13" strokeLinecap="round" />
      <line x1="6.5" y1="77.5" x2="21.5" y2="77.5" stroke={stroke} strokeWidth="13" strokeLinecap="round" />
    </svg>
  );
};
