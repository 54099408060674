import React from "react";
import { Flex } from "./Flex";

export interface DividerWithTextProps {
  position?: DividerWithTextPosition;
  gap?: number | string;
}

export type DividerWithTextPosition = "left" | "right" | "center";

export const DividerWithText: React.FC<DividerWithTextProps> = ({ gap = "1rem", position = "center", children }) => {
  return (
    <Flex align="center" gap={gap}>
      {(["left", "center"] as DividerWithTextPosition[]).includes(position) && <hr className="flex-1" />}
      {children}
      {(["right", "center"] as DividerWithTextPosition[]).includes(position) && <hr className="flex-1" />}
    </Flex>
  );
};
