import React from "react";
import { UseQueryResult } from "react-query";
import { useHistory } from "react-router-dom";

import { QueryTable } from "components";
import { capitalized } from "utils";
import models from "models";
import { UsePaginationResult } from "hooks";

import { usePageTemplate } from "features/users/hooks";
import { usersTableColumns } from "features/users/config";
import { useSendGiftInitialState } from "features/send-gift/hooks";

export interface UsersTableProps {
  setEditModalData: React.Dispatch<models.UserContact>;
  setDeleteModalData: React.Dispatch<models.UserContact>;
  // onGroupsClick: () => void;
  query: UseQueryResult<models.WithResults<models.UserContact>>;
  pagination: UsePaginationResult;
}

export const UsersTable = ({
  query,
  pagination,
  setEditModalData,
  setDeleteModalData,
}: // onGroupsClick,
UsersTableProps) => {
  const history = useHistory();
  const template = usePageTemplate();
  const [, setSendGiftInitialState] = useSendGiftInitialState();

  const onSendGiftClick = React.useCallback(
    (user: models.UserContact) => {
      setSendGiftInitialState({
        users: [user],
        contactType: template.contactType,
      });
      history.push("/send-gift");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSendGiftInitialState, template.contactType]
  );

  // const onFooterSendGiftClick = React.useCallback(
  //   () => {
  //     setSendGiftInitialState({
  //       users: [],
  //       contactType: template.contactType,
  //     });
  //     history.push("/send-gift");
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [setSendGiftInitialState, template.contactType]
  // );

  const tableColumns = React.useMemo(
    () =>
      usersTableColumns({
        onEditClick: setEditModalData,
        onDeleteClick: setDeleteModalData,
        onSendGiftClick,
        groupName: capitalized(template.groupName.singular),
        contactType: template.contactType,
      }),
    [onSendGiftClick, setDeleteModalData, setEditModalData, template.groupName.singular, template.contactType]
  );

  return (
    <QueryTable
      pagination={pagination}
      query={query}
      columns={tableColumns}
      // CANCELED
      // footer={() => (
      //   <Button onClick={onGroupsClick} bordered>
      //     Manage database
      //   </Button>
      //   // <Button onClick={onFooterSendGiftClick} icon={<Icon type="status-redeemed" />}>
      //   //   Send gift
      //   // </Button>
      // )}
    />
  );
};
