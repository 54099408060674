import models from "models";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "app-constants";

import { apiAxios, authAxios } from "./axios";
import { users } from "./users";

export const auth = {
  getUser: async () => {
    const user = await users.profile.get();
    return user;
  },
  getTokens: async () => {
    const { data: tokens } = await authAxios.get<models.TokensResponse>("/authentication/cookie");
    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },
  refresh: async (refreshToken: string) => {
    const { data: tokens } = await authAxios.post<models.TokensResponse>(`/authentication/refresh`, {
      refresh: refreshToken,
    });

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens.access);
  },
  login: async (credentials: models.LoginCredentials) => {
    const { data: tokens } = await authAxios.post<models.TokensResponse>(`/authentication/login`, credentials);
    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },
  logout: async () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    await authAxios.get(`/authentication/logout`);
  },

  register: async (credentials: models.RegisterCredentials) => {
    const authCredentials = {
      username: credentials.email,
      password: credentials.password,
      register_type: "email",
    };

    const { data: registerTokens } = await authAxios.post<models.TokensResponse>(
      `/authentication/register`,
      authCredentials
    );
    localStorage.setItem(ACCESS_TOKEN_KEY, registerTokens?.access);

    await apiAxios.post(`/users/register/`, credentials);

    const tokens = await auth.login(authCredentials);

    return tokens;
  },

  restorePasswordRequest: async (credentials: models.RequestRestorePasswordCredentials) => {
    const { data } = await authAxios.post<models.ResponseWithSuccess>(`/authentication/restore-request`, credentials);

    if (!data.success) throw new Error(data.message);

    return data;
  },

  restorePassword: async (credentials: models.RestorePasswordCredentials) => {
    const { data } = await authAxios.post<models.ResponseWithSuccess>(`/authentication/restore-confirm`, credentials);

    if (!data.success) throw new Error(data.message);

    return data;
  },
};
