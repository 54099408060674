import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { Modal } from "ebs-design";

import api, { querykeys } from "api";
import { atoms } from "atoms";
import {
  HorizontalTabPanelsContainer,
  Flex,
  HorizontalTabPanel,
  WhiteSpace,
  Drawer,
  FilterButton,
  NoFiltersMatchMessage,
  ProductCharacteristicChoices,
  ProductFilters,
  ProductListGroup,
  QueryList,
  Text,
} from "components";
import { useModalState, useProductFilters, useScreenBreakPoint } from "hooks";
import models from "models";
import { makeBEM } from "utils";

import { RegisterForm } from "features/auth/components";
import { NextButton } from "features/send-gift/components";
import { createProductPropsGetter } from "features/send-gift/utils";
import { AuthLinks } from "../components/AuthLinks";
import { LinearProgress } from "components/LinearProgress";
// import { someProducts } from "./_products";

const bem = makeBEM("register-layout");

export const Register = () => {
  const smallScreen = useScreenBreakPoint("1400px");

  const query = useQuery(querykeys.products.many(), () => api.companies.products.grouped());
  // const query = useQuery(querykeys.products.many(), async () => {
  //   return someProducts;
  // });

  const productFilters = useModalState(atoms.productFilters);

  const [selectedProducts, setSelectedProducts] = useState<models.ProductSendConfig[]>([]);

  const { productFilterProps, filteredProducts } = useProductFilters(query.data, productFilters.close);

  const characteristicsModal = useModalState<models.Product>(atoms.characteristics);

  const [registerFormOpen, setRegisterFormOpen] = useState(false);

  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (registerFormOpen) {
      const $input = firstInputRef.current;
      if (!$input) return;
      const focusOnForm = () => {
        $input.setCustomValidity("Let's create an account first");
        $input.reportValidity();
        $input.focus();
        setTimeout(() => {
          $input.setCustomValidity("");
        }, 2500);
      };

      if (smallScreen) {
        window.setTimeout(focusOnForm, 200);
      } else {
        focusOnForm();
      }
    }
  }, [smallScreen, registerFormOpen]);

  useEffect(() => {
    if (registerFormOpen && smallScreen) {
      const initialOverflow = document.body.style.overflowY;
      document.body.style.overflowY = "hidden";
      return () => {
        document.body.style.overflowY = initialOverflow;
      };
    }
  }, [registerFormOpen, smallScreen]);

  return (
    <>
      <div className={bem()}>
        <div className={bem("form-wrapper", { mobile: smallScreen, "mobile-open": registerFormOpen })}>
          <RegisterForm firstInputRef={firstInputRef} showInfo={registerFormOpen} />
          <div>
            <AuthLinks />
          </div>
        </div>
        <div className={bem("form-space-holder", { hidden: smallScreen })}></div>
        <div className={bem("send-gift-container", { mobile: smallScreen, "overflow-hidden": registerFormOpen })}>
          {smallScreen && (
            <Flex justify="end" gap="0.5rem" style={{ padding: "2rem 1.5rem" }}>
              <Text el="span" color="gray">
                Already have an account?
              </Text>{" "}
              <Link to="/login">Sign in here</Link>
            </Flex>
          )}
          <HorizontalTabPanelsContainer noTopbar>
            <Flex gap="0.5rem">
              <HorizontalTabPanel
                title="Select gift"
                mobileTitle="Gift"
                subTitle="Select what you want to send"
                number={1}
                active
              />
              <HorizontalTabPanel number={2} title="Recipients" subTitle="Who are you sending to?" />
              <HorizontalTabPanel number={3} title="Message" subTitle="What do you want to say?" />
              <HorizontalTabPanel number={4} title="Pay" subTitle="Review and Send" />
            </Flex>
            <WhiteSpace v="0.5rem" />

            <LinearProgress color="success" value={25} />
          </HorizontalTabPanelsContainer>
          <WhiteSpace v="2rem" />
          <Flex className={bem("filter-toggle")} justify="end">
            <FilterButton onClick={productFilters.open} />
          </Flex>
          <Drawer open={productFilters.isOpen} onClose={productFilters.close}>
            <ProductFilters {...productFilterProps} />
          </Drawer>
          <WhiteSpace v="1rem" />
          <QueryList
            query={query}
            emptyText={<NoFiltersMatchMessage />}
            getItems={() => filteredProducts}
            renderItems={(items) => (
              <Flex direction="column" gap="3.5rem" align="stretch">
                {items.map((category) => (
                  <ProductListGroup
                    data={category}
                    getProductProps={createProductPropsGetter(
                      selectedProducts,
                      setSelectedProducts,
                      characteristicsModal.openWith
                    )}
                  />
                ))}
              </Flex>
            )}
          />
          {selectedProducts.length > 0 && (
            <NextButton disabled={registerFormOpen} onClick={() => setRegisterFormOpen(true)} />
          )}
          <Modal size="small" open={characteristicsModal.isOpen} onClose={characteristicsModal.close}>
            <Modal.Content>
              <ProductCharacteristicChoices
                characteristics={characteristicsModal.data?.characteristic_types || []}
                onChoiceClick={(choice) => {
                  setSelectedProducts([
                    ...selectedProducts,
                    { product: characteristicsModal.data!, product_characteristic_choices: choice },
                  ]);
                  characteristicsModal.close();
                }}
              />
            </Modal.Content>
          </Modal>
        </div>
      </div>
    </>
  );
};
