import React from "react";

export const Swipe: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M23.8666 3.19995L26.6666 5.99995L23.8666 8.79995"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M8.13337 8.79995L5.33337 5.99995L8.13337 3.19995"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M5.33337 6H26.6667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M20.4001 17.4665C20.4001 16.6665 21.3335 15.8665 22.2668 16.1332C22.9335 16.3999 23.3335 16.9332 23.4668 17.7332V17.9999V23.4665L23.0668 24.7999C22.4001 27.1999 20.1335 28.9332 17.6001 28.9332C15.2001 28.9332 13.0668 27.3332 12.2668 25.0665C12.0001 24.1332 11.4668 23.3332 10.8001 22.6665L7.33347 19.1998C6.6668 18.5332 6.6668 17.3332 7.4668 16.7999C8.00014 16.3999 8.93347 16.5332 9.4668 17.0665L11.8668 19.4665V17.9999V10.6665C11.8668 9.73318 12.6668 9.06652 13.6001 9.19985C14.2668 9.33318 14.6668 9.99985 14.6668 10.6665L14.6667 14.6665M20.4001 17.4665V16.1332C20.4001 15.4665 20.0001 14.7999 19.3335 14.6665C18.4001 14.3999 17.4668 15.1999 17.4668 15.9999M20.4001 17.4665V19.4665M17.4668 15.9999V14.6665C17.4668 13.9999 17.0668 13.3332 16.4001 13.1998C15.4668 12.9332 14.6667 13.8665 14.6667 14.6665M17.4668 15.9999V19.4665M14.6667 14.6665L14.6668 19.4665"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
