import React from "react";
import { QueryKey, useInfiniteQuery } from "react-query";

import { Select, useNotify } from "ebs-design";
import { SelectProps } from "ebs-design/dist/components/molecules/Select/components/Component";

import { DebouncedInput, Button, WhiteSpace } from "components";
import { combineQueryPagesData, hasNextPage, notifyErrors } from "utils";
import models from "models";

import { GenericObject } from "types";

type SelectValue = string | number;

export interface QuerySelectProps<RecordType> extends SelectProps {
  querykey: (queryParams: GenericObject) => QueryKey;
  apiCall: (queryParams: GenericObject) => Promise<models.WithResults<RecordType>>;
  getValue: (record: RecordType) => SelectValue;
  getText: (record: RecordType) => React.ReactNode;
}

export const QuerySelect = <RecordType,>({
  querykey,
  apiCall,
  getValue,
  getText,
  ...selectProps
}: QuerySelectProps<RecordType>) => {
  const notify = useNotify();

  const [search, setSearch] = React.useState("");

  const queryParams = { search };

  const query = useInfiniteQuery(
    querykey(queryParams),
    ({ pageParam = 1 }) => apiCall({ ...queryParams, page: pageParam }),
    {
      onError: (error) => notifyErrors(notify, error),
      getNextPageParam: (lastPage) => (hasNextPage(lastPage) ? (lastPage.current_page || 0) + 1 : undefined),
    }
  );

  const items = combineQueryPagesData(query.data);

  return (
    <Select loading={query.isLoading} {...selectProps}>
      <DebouncedInput placeholder="Search" value={search} onChange={setSearch} />
      <WhiteSpace v="0.5rem" />
      <Select.Options>
        {items.map((item) => (
          <Select.Options.Item key={getValue(item)} value={getValue(item)}>
            {getText(item)}
          </Select.Options.Item>
        ))}
      </Select.Options>
      <WhiteSpace v="0.5rem" />
      {query.hasNextPage && (
        <Button light color="secondary" loading={query.isFetching} onClick={() => query.fetchNextPage()} size="sm">
          Load more
        </Button>
      )}
    </Select>
  );
};
