import React from "react";

import { Button, ButtonProps } from "./Button";
import { Icon } from "./Icon";

export interface FilterButtonProps extends ButtonProps {}

export const FilterButton: React.FC<FilterButtonProps> = ({ ...props }) => {
  return (
    <Button auto color="secondary" {...props}>
      <Icon type="search" size="2em" />
      <Icon type="filters" size="2em" />
    </Button>
  );
};
