import { AxiosResponse, CancelToken } from "axios";
import { UploadRequestOption } from "rc-upload/lib/interface";

import models from "models";

import { apiAxios } from "./axios";

export const attachments = {
  post: async (
    option: UploadRequestOption<models.Attachment>,
    {
      cancelToken,
      attachmentType,
    }: { cancelToken?: CancelToken; attachmentType?: models.AttachmentType } = {},
  ) => {
    try {
      const formData = new FormData();
      if (option.file instanceof Blob) {
        formData.append(
          option.filename || "",
          option.file,
          (option.file as any).name,
        );
      } else {
        formData.append(option.filename || "", option.file);
      }
      attachmentType && formData.append("type", attachmentType);
      const { data } = await apiAxios.post<
        models.Attachment,
        AxiosResponse<models.Attachment[]>
      >("/attachments/", formData, {
        cancelToken,
        onUploadProgress: (e: ProgressEvent) => {
          option.onProgress &&
            option.onProgress({
              ...e,
              percent: e.total ? (e.loaded / e.total) * 100 : 0,
            });
        },
      });
      option.onSuccess && option.onSuccess(data[0], {} as XMLHttpRequest);
      return data;
    } catch (error: any) {
      option.onError && option.onError(error, undefined);
      return null;
    }
  },
};
