import React from "react";

export const Privacy: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg height="1em" width="1em" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <g>
          <path
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            d="M7.36,13.43h0a1,1,0,0,1-.72,0h0a9.67,9.67,0,0,1-6.14-9V1.5a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1V4.42A9.67,9.67,0,0,1,7.36,13.43Z"
            strokeWidth={1}
          />
          <path
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            d="M3.25,11.19a5,5,0,0,1,7.5,0"
            strokeWidth={1}
          />
          <circle
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            cx="7"
            cy="5.5"
            r="2.5"
            strokeWidth={1}
          />
        </g>
      </g>
    </svg>
  );
};
