import { useMutation, useQueryClient } from "react-query";

import { Form, Modal, useNotify, useForm } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Text, Upload, UploadItem, WhiteSpace, Button, Flex } from "components";
import { notifyErrors } from "utils";
import api from "api";
import { USERS_UPLOAD_ACCEPT } from "app-constants";
import models from "models";

import { usePageTemplate } from "features/users/hooks";

import { importExampleHref } from "../config";

export interface UploadCSVModalProps extends ModalProps {}

export const UploadCSVModal = ({ ...props }: UploadCSVModalProps) => {
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const template = usePageTemplate();

  const csvMutation = useMutation(template.usersApi.import, {
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: { csv: UploadItem<models.Attachment>[] }) => {
    csvMutation.mutate(values?.csv?.[0]?.data?.url || "", {
      onSuccess: () => {
        queryClient.invalidateQueries(template.querykeys.users.many());
        props.onClose?.();
        notify.success({ title: "", description: "Users added successfully" });
      },
    });
  };

  return (
    <Modal size="small" {...props}>
      <Modal.Content>
        <h1>Add {template.name.plural}</h1>
        <p>Upload a CSV File here to upload multiple {template.name.plural}</p>
        <WhiteSpace v="1rem" />
        <hr />
        <WhiteSpace v="2rem" />
        <h3>Upload the CVS File with all of your {template.name.plural}</h3>
        <Text color="gray">
          If you havent got the CVS file then download the template{" "}
          <a href={importExampleHref(template.contactType)} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </Text>
        <WhiteSpace v="2rem" />
        <Form form={form} onFinish={submitHandler}>
          <Form.Field name="csv" hideLabel label="CSV" initialValue={[]} rules={[{ required: true }]}>
            <Upload accept={USERS_UPLOAD_ACCEPT} name="files" customRequest={api.attachments.post}>
              <Button type="button">Upload CSV/Excel</Button>
            </Upload>
          </Form.Field>
          <WhiteSpace v="4rem" />
          <hr />
          <WhiteSpace v="1rem" />
          <Flex justify="end">
            <Form.Field dependencies={["csv"]}>
              {(_, __, form) => {
                const csv: UploadItem | undefined = form.getFieldValue("csv")?.[0];
                return (
                  <Button disabled={!csv?.isSuccess} loading={csvMutation.isLoading}>
                    Finish
                  </Button>
                );
              }}
            </Form.Field>
          </Flex>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
