import React from "react";

export const AccountInfo: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M3 27.1335C3 22.3335 6.86667 18.4668 11.6667 18.4668C16.4667 18.4668 20.3333 22.3335 20.3333 27.1335"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.5333 15.4C14.4051 15.4 16.7333 13.0719 16.7333 10.2C16.7333 7.32812 14.4051 5 11.5333 5C8.66137 5 6.33325 7.32812 6.33325 10.2C6.33325 13.0719 8.66137 15.4 11.5333 15.4Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M24.7112 10.4471C24.6512 10.4471 24.6002 10.4291 24.5582 10.3931C24.5222 10.3511 24.5042 10.3001 24.5042 10.2401V9.75409C24.5042 9.69409 24.5222 9.64309 24.5582 9.60109C24.6002 9.55909 24.6512 9.53809 24.7112 9.53809H25.2782C25.3382 9.53809 25.3892 9.55909 25.4312 9.60109C25.4732 9.64309 25.4942 9.69409 25.4942 9.75409V10.2401C25.4942 10.3001 25.4732 10.3511 25.4312 10.3931C25.3892 10.4291 25.3382 10.4471 25.2782 10.4471H24.7112ZM24.7832 16.0001C24.7232 16.0001 24.6722 15.9821 24.6302 15.9461C24.5942 15.9041 24.5762 15.8531 24.5762 15.7931V11.5271C24.5762 11.4671 24.5942 11.4191 24.6302 11.3831C24.6722 11.3411 24.7232 11.3201 24.7832 11.3201H25.2152C25.2752 11.3201 25.3232 11.3411 25.3592 11.3831C25.4012 11.4191 25.4222 11.4671 25.4222 11.5271V15.7931C25.4222 15.8531 25.4012 15.9041 25.3592 15.9461C25.3232 15.9821 25.2752 16.0001 25.2152 16.0001H24.7832Z"
        fill={fill}
      />
      <circle cx="25" cy="13" r="5.5" stroke={stroke} />
    </svg>
  );
};
