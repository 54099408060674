import React from "react";

export const Cross: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  strokeWidth,
  ...svgProps
}) => {
  return (
    <svg height="1em" width="1em" fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path d="M8 8L24 24" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M8 24L24 8" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" />
    </svg>
  );
};
