import { UseMutationResult } from "react-query";

import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, Button } from "components";

export interface MutationConfirmModalProps extends ModalProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  mutation: UseMutationResult<any, any, any>;
  children: React.ReactNode;
}

export const MutationConfirmModal = ({
  children,
  onConfirm,
  onCancel,
  onClose,
  mutation,
  ...props
}: MutationConfirmModalProps) => {
  return (
    <Modal size="small" onClose={onClose} {...props}>
      <Modal.Content>
        <Flex align="center" justify="end" wrap gap="2rem">
          <div className="flex-1 nowrap">{children}</div>

          <Flex gap="1rem">
            <Button auto bordered color="secondary" onClick={onCancel || onClose}>
              Cancel
            </Button>
            <Button
              auto
              loading={mutation.isLoading}
              onClick={onConfirm || (() => mutation.mutate({}, { onSuccess: onClose }))}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal.Content>
    </Modal>
  );
};
