import { Link } from "react-router-dom";
import { WithInfoIcon } from "components";
import { useAuth } from "features/auth/hooks";

export const RequiredSettingsWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const auth = useAuth();

  return (
    <>
      {auth.user?.company?.can_send_gift ? (
        children
      ) : (
        <div className="text-center">
          <WithInfoIcon justify="center" align="center" inline wrap style={{ padding: "4rem 2rem" }}>
            <h3>
              You need complete all of your Business details from <Link to="/settings">Settings</Link> to be able to
              send a gift
            </h3>
          </WithInfoIcon>
        </div>
      )}
    </>
  );
};
