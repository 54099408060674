import React from "react";

export const UrgentMail: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 25 23"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M13.2332 13.306L16.3196 9.69488H14.0048V6.08377L9.37516 11.5004H11.69V13.306H6.25016C5.67487 13.306 5.2085 12.9018 5.2085 12.4032V3.37543C5.2085 2.87684 5.67487 2.47266 6.25016 2.47266H18.7502C19.3255 2.47266 19.7918 2.87684 19.7918 3.37543V12.4032C19.7918 12.9018 19.3255 13.306 18.7502 13.306H13.2332Z"
        fill={fill}
        fillRule="evenodd"
        opacity="0.3"
      />
      <path
        d="M10.2128 10.5202L9.375 11.5004H11.6898V15.1115L16.3194 9.69486H16.1058L21.0492 6.60063C21.2824 6.45467 21.608 6.50018 21.7764 6.70227C21.8405 6.7792 21.875 6.87167 21.875 6.96656V16.0143C21.875 17.0115 20.9423 17.8199 19.7917 17.8199H5.20833C4.05774 17.8199 3.125 17.0115 3.125 16.0143V6.96656C3.125 6.71726 3.35819 6.51517 3.64583 6.51517C3.75532 6.51517 3.86202 6.54507 3.95078 6.60063L10.2128 10.5202Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};
