import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "api/axios";
import { stringifyUrl } from "api/utils";

export const createUsersApi = (name: models.UserContactType) => ({
  setupGift: async (payload: models.SetupGiftPayload) => {
    const { data } = await apiAxios.post<models.SetupGiftResponse>(`/companies/${name}/send-gift/`, payload);
    return data;
  },
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/${name}/`, query);
    const { data } = await apiAxios.get<
      models.WithResults<models.UserContact>
    >(url);
    return data;
  },
  delete: async (id: number) => {
    await apiAxios.delete(`/companies/${name}/${id}/`);
  },
  patch: async (id: number, user: models.UserContact) => {
    const { data } = await apiAxios.patch<models.UserContact>(
      `/companies/${name}/${id}/`,
      user
    );
    return data;
  },
  post: async (user: models.UserContact) => {
    const { data } = await apiAxios.post<models.UserContact>(
      `/companies/${name}/`,
      user
    );
    return data;
  },
  import: async (file_url: string) => {
    const { data } = await apiAxios.post<models.UserContact>(
      `/companies/${name}/import/`,
      { file_url }
    );
    return data;
  },

  groups: {
    get: async (query: GenericObject = {}) => {
      const url = stringifyUrl(`/companies/${name}/groups/`, query);
      const { data } = await apiAxios.get<models.WithResults<models.Group>>(
        url
      );
      return data;
    },
    delete: async (id: number) => {
      await apiAxios.delete(`/companies/${name}/groups/${id}/`);
    },
    patch: async (id: number, group: models.Group) => {
      const { data } = await apiAxios.patch<models.Group>(
        `/companies/${name}/groups/${id}/`,
        group
      );
      return data;
    },
    post: async (group: models.Group) => {
      const { data } = await apiAxios.post<models.Group>(
        `/companies/${name}/groups/`,
        group
      );
      return data;
    },
  },
})