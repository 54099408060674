import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "api/axios";
import { stringifyUrl } from "api/utils";

export const monthlyInvoice = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/monthly-report/`, query);
    const { data } = await apiAxios.get<models.MonthlyInvoice[]>(url);

    return data;
  },
};
