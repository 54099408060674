export function generateColor(seed: number) {
  return (
    "#" +
    Math.floor(Math.abs(Math.sin(seed) * ((1 << 24) - 1)))
      .toString(16)
      .padStart(6, "0")
  );
}
export function contrastTextColor(hex: string) {
  const rgb = hexToRgb(hex);

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  );
  return brightness > 125 ? "black" : "white";
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16) || 0,
      g: parseInt(result[2], 16) || 0,
      b: parseInt(result[3], 16) || 0
    }
    : { r: 0, g: 0, b: 0 };
}
