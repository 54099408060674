import React from "react";

export const Card: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 25.2H6.66667C5.2 25.2 4 24 4 22.5334V9.46672C4 8.00005 5.2 6.80005 6.66667 6.80005H25.3333C26.8 6.80005 28 8.00005 28 9.46672V22.6667C28 24 26.8 25.2 25.3333 25.2Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path d="M28 10.6667H4V14.2667H28V10.6667Z" fill={fill} />
      <path
        d="M13.2 21.3333H7.19997C6.66663 21.3333 6.1333 20.8 6.1333 20.2666C6.1333 19.7333 6.66663 19.2 7.19997 19.2H13.0666C13.6 19.2 14.1333 19.7333 14.1333 20.2666C14.2666 20.8 13.7333 21.3333 13.2 21.3333Z"
        fill={fill}
      />
    </svg>
  );
};
