import { useQuery } from "react-query";

import { QueryContent } from "components";
import api, { querykeys } from "api";
import { useParam } from "hooks";

import { AutoGiftsForm } from "../components/AutoGiftForm";

export const EditAutoGift = () => {
  const id = useParam("id", Number);

  const query = useQuery(querykeys.autoGifts.one(id), () => api.autoGifts.getById(id));
  return (
    <QueryContent query={query}>
      <AutoGiftsForm titlePrefix="Edit" apiCall={api.autoGifts.patch.bind(null, id)} data={query.data} />
    </QueryContent>
  );
};
