import models from "models";
import { apiAxios } from "./axios";

export const users = {
  profile: {
    get: async () => {
      const { data } = await apiAxios.get<models.User>("/users/profile/");
      return data;
    },
    patch: async (user: models.UserPatch) => {
      const { data } = await apiAxios.patch<models.User>("/users/profile/", user);
      return data;
    },
  },
  settings: {
    get: async () => {
      const { data } = await apiAxios.get<models.Setting[]>("/users/settings/list/");
      return data;
    },
  },
};
