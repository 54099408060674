import React from "react";

export const Menu: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-3 -3 54 54" {...svgProps}>
      <g fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" transform="scale(3.42857)">
        <path d="M13.5 2L0.5 2" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M13.5 7L0.5 7" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M13.5 12L0.5 12" strokeLinecap="round" strokeLinejoin="round"></path>
      </g>
    </svg>
  );
};
