import { TabContent } from "./TabContent";
import { TabPanel } from "./TabPanel";
import { TabProvider } from "./TabProvider";

export const TabsBase = {
  Provider: TabProvider,
  Panel: TabPanel,
  Content: TabContent,
};

export type { TabProviderProps } from "./TabProvider"
export type { WithTabKey } from "./types"
