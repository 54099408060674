import models from "models";
import produce from "immer";
import Fuse from "fuse.js";
import { ArrayUtils } from "./array";

export interface FilterCriteria {
  search: string;
  activeCategories: number[];
  activeRedeemTypes: models.ProductReedemType[];
  activePriceRanges: number[];
}

export const priceRangeMap: Record<number, number[]> = {
  1: [0, 10],
  2: [10, 100],
  3: [100, 1000],
  4: [1000, 10000],
};

export const filterGroupedProducts = (
  groupedProducts: models.GroupedProductListCategory[],
  { search, activeCategories, activeRedeemTypes, activePriceRanges }: FilterCriteria
) => {
  if (activeCategories.length) groupedProducts = groupedProducts.filter((c) => activeCategories.includes(c.id || 0));

  let allProducts = new ArrayUtils(
    groupedProducts
      .map((category) => category.sub_categories?.map((subCategory) => subCategory.products || []) || [])
      .flat(2)
  );

  if (activeRedeemTypes.length)
    allProducts.filter((p) => !!p.types?.find((type) => !!activeRedeemTypes.includes(type)));

  if (activePriceRanges.length)
    allProducts.filter(
      (p) =>
        !!activePriceRanges.find((rangeId) => {
          const [mn, mx] = priceRangeMap[rangeId];
          const price = p.cost || 0;
          return p.characteristic_types?.length
            ? p.characteristic_types.find((c) => c.choices?.find((c) => (c.price || 0) >= mn && (c.price || 0) <= mx))
            : price >= mn && price <= mx;
        })
    );

  const fuse = new Fuse(allProducts.asArray(), {
    keys: ["title", "brand.title"],
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: false,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.25,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
  });

  if (search) {
    const includedIds = fuse.search(search).map((p) => p.item.id || 0);
    allProducts.filter((p) => includedIds.includes(p.id || 0));
  }

  groupedProducts = produce(groupedProducts, (groupedProducts) => {
    groupedProducts.forEach((category) => {
      category.sub_categories?.forEach((subCategory) => {
        subCategory.products = allProducts.intersect(subCategory.products || [], (p) => p.id);
      });
      category.sub_categories = category.sub_categories?.filter((subCategory) => subCategory.products?.length);
    });
  });
  groupedProducts = groupedProducts.filter((category) => category.sub_categories?.length);

  return groupedProducts;
};

export const getProductPrice = (config: models.ProductSendConfig) =>
  config.product_characteristic_choices ? config.product_characteristic_choices.price || 0 : config.product.price || 0;
export const getProductCost = (config: models.ProductSendConfig) =>
  config.product_characteristic_choices ? config.product_characteristic_choices.price || 0 : config.product.cost || 0;
