import { mixins } from "./mixins";

export const querykeys = {
  loggedUser: "logged-user",
  profile: "profile",

  orders: {
    one: mixins.one("order-invoice"),
    many: mixins.many("orders"),
    stats: () => ["orders", "stats"],
  },

  companies: {
    one: mixins.one("companies"),
    users: {
      clients: {
        many: mixins.many("clients"),
      },
      employees: {
        many: mixins.many("employees"),
      },
    },
    groups: {
      teams: {
        many: mixins.many("teams"),
      },
      companies: {
        many: mixins.many("companies"),
      },
    },
    monthlyInvoice: mixins.many("monthly-invoice"),
  },

  products: {
    many: mixins.many("products"),
  },
  nomenclatures: {
    many: mixins.many("nomenclatures"),
  },
  paymentMethods: {
    many: mixins.many("payment-methods"),
  },
  brands: {
    many: mixins.many("brands"),
  },
  categories: {
    many: mixins.many("categories"),
    manyById: (id: number) => mixins.many(["categories", id, "children"]),
  },

  users: {
    settings: ["users", "settings"],
  },

  autoGifts: {
    grouped: "grouped-auto-gifts",
    one: mixins.one("auto-gifts"),
  },
};
