import React from "react";

import { Form } from "ebs-design";

import {
  Card,
  Flex,
  InputFormField,
  TabsBase,
  Text,
  Upload,
  UploadItem,
  WhiteSpace,
  Button,
  ORDivider,
} from "components";
import { makeBEM, videoOrCommentRule } from "utils";
import api from "api";
import models from "models";

import humanImageSrc from "features/send-gift/assets/human.svg";
import { useSendGiftContext } from "features/send-gift/hooks";

import { StepContext } from "../contexts";
import { TabCard } from ".";

const c = makeBEM("send-gift-send-method");

export const SendMethodTab = React.memo(() => {
  const { messageForm, contactType, sendMethodType, setContextValue } = useSendGiftContext();
  const { canGoNext, setCanGoNext } = React.useContext(StepContext);

  const [messageStep, setMessageStep] = React.useState(1);

  const cardClickHandler = (name: models.SendMethodType) => () => {
    setContextValue({ sendMethodType: name });
    setMessageStep(2);
  };

  React.useEffect(() => {
    // May change in the future
    // if (contactType === "clients") setCurrentStep(2);
    // else setCurrentStep(1);

    setMessageStep(2);
    setContextValue({
      sendMethodType: contactType === "clients" ? models.SendMethodType.email : models.SendMethodType.mobile,
    });
  }, [contactType, setContextValue]);

  return (
    <TabCard>
      <div className={c("container")}>
        <TabsBase.Provider value={messageStep} onChange={setMessageStep}>
          {/* HOLD */}
          <TabsBase.Content tabKey={1}>
            <div className={c("grid")}>
              <Card onClick={cardClickHandler(models.SendMethodType.email)} className={c("card", ["email"])}>
                <WhiteSpace v="2rem" />
                <h2>Via Email</h2>
                <WhiteSpace v="1rem" />
                <p>Send your prsnt via email and they can redeem it using their browser.</p>
                <WhiteSpace v="2rem" />
                <img className={c("card-image")} src={humanImageSrc} alt="" />
              </Card>
              <Card onClick={cardClickHandler(models.SendMethodType.mobile)} className={c("card", ["mobile"])}>
                <WhiteSpace v="1rem" />
                <h2>
                  Via the Prsnt App <br />
                  (Recommended)
                </h2>
                <WhiteSpace v="1rem" />
                <p>
                  Send it via the prsnt app and your recipient will get the benefit of the prsnt app and all its awesome
                  features.
                </p>
                <WhiteSpace v="1rem" />
                <p>You can also attach a video message</p>
                <WhiteSpace v="2rem" />
                <img className={c("card-image")} src={humanImageSrc} alt="" />
              </Card>
            </div>
          </TabsBase.Content>
          <TabsBase.Content tabKey={2}>
            <Form preserve form={messageForm}>
              <Card className={c("message-card")}>
                <Flex align="center" justify="space-between" wrap>
                  <h2>Add a message to go with your gift below</h2>
                  <Text color="gray">Via {sendMethodType === models.SendMethodType.email ? "email" : "prsnt app"}</Text>
                </Flex>
                <WhiteSpace v="1rem" />
                {/* <div>Message</div> */}
                <InputFormField
                  name="comment"
                  label="Message"
                  textarea
                  getInputProps={(_, __, form) => ({
                    disabled: !!form.getFieldValue("video")?.length,
                  })}
                  rules={sendMethodType === models.SendMethodType.mobile ? [videoOrCommentRule] : [{ required: true }]}
                  dependencies={["video"]}
                />

                {/* HOLD */}
                {/* <WhiteSpace v="1rem" />
              {contactType === "employees" && (
                <div style={{ marginLeft: "-13px" }}>
                  <Button type="text" onClick={() => setCurrentStep(1)}>
                    Back to choose send method
                  </Button>
                </div>
              )} */}
                <Form.Field shouldUpdate className="m-0">
                  {(_, __, form) => {
                    const videos: UploadItem<models.Attachment>[] | undefined = form.getFieldValue("video");
                    const newCanGoNext =
                      (!videos?.length || videos?.[0]?.isSuccess) &&
                      (form.getFieldValue("comment") || videos?.[0]?.isSuccess);

                    newCanGoNext !== canGoNext && setCanGoNext(newCanGoNext);
                    return <></>;
                  }}
                </Form.Field>
              </Card>
              {sendMethodType === models.SendMethodType.mobile && (
                <>
                  <WhiteSpace v="1rem" />
                  <ORDivider />
                  <WhiteSpace v="1rem" />
                  <Card className={c("message-card")}>
                    <h2>Attach a video</h2>
                    <WhiteSpace v="1rem" />

                    <Form.Field className="m-0" name="video" initialValue={[]} dependencies={["comment"]}>
                      {(control, _, form) => {
                        const disabled = !!form.getFieldValue("comment");
                        return (
                          <Upload
                            {...control}
                            disabled={disabled}
                            accept="video/*"
                            name="files"
                            customRequest={api.attachments.post}
                          >
                            <Button disabled={disabled} type="button" size="lg" color="secondary" block>
                              Upload video
                            </Button>
                          </Upload>
                        );
                      }}
                    </Form.Field>
                  </Card>
                </>
              )}
            </Form>
          </TabsBase.Content>
        </TabsBase.Provider>
      </div>
    </TabCard>
  );
});
