import { Form, InputPhone } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { PHONE_PREFIX } from "app-constants";

export interface InputPhoneFormFieldProps extends FormFieldProps {
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export const InputPhoneFormField = ({
  rules = [],
  required,
  initialValue = "",
  hideLabel = true,
  placeholder,
  disabled,
  ...restProps
}: InputPhoneFormFieldProps) => {
  return (
    <Form.Field
      {...restProps}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
        hideLabel,
      }}
    >
      {(control, meta) => (
        <InputPhone
          {...control}
          onChange={(value, data) => {
            if ("dialCode" in data) {
              control.onChange(value.replace(new RegExp(`^${data.dialCode}0`), data.dialCode));
            }
          }}
          size="medium"
          preferredCountries={["gb", "us"]}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => {
            if (!meta.touched && !control.value) control.onChange(PHONE_PREFIX);
          }}
        />
      )}
    </Form.Field>
  );
};
