export const colorMap = {
  primary: "var(--primary-color)",
  success: "var(--success-color)",
  danger: "var(--danger-color)",
  gray: "#B5B5C3",
};

export type ColorMapKey = keyof typeof colorMap | (string & {});

export const getColor = (color?: ColorMapKey | undefined) => {
  return color ? colorMap[color as keyof typeof colorMap] || color : undefined;
};
