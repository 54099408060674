import { Card, PageContainer, Text, WhiteSpace } from "components";

export const Contact = () => {
  return (
    <PageContainer>
      <Card style={{ padding: "4rem" }}>
        <Text color="primary" el="h1">
          Prsnt Ltd
        </Text>
        <WhiteSpace v="1rem" />
        <div>11a Jew Street, Brighton</div>
        <div>BN1 1UT Registered company 12374095</div>
        <WhiteSpace v="0.5rem" />
        <div>
          Our email: <a href="mailto:info@prsnt.co.uk">info@prsnt.co.uk</a>
        </div>
      </Card>
    </PageContainer>
  );
};
