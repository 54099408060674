import models from "models";

export interface AttachmentLinkProps extends models.Attachment {}

export const AttachmentLink = ({ name, url }: AttachmentLinkProps) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="video-link">
      {name || url || "(No title)"}
    </a>
  );
};
