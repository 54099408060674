import React, { CSSProperties } from "react";

import { Card, Grid, AttachmentLink, WhiteSpace, ProductListItem, Button, Flex } from "components";
import { getProductPrice, makeBEM, withCurrency } from "utils";
import { useModalState } from "hooks";
import { atoms } from "atoms";

import { useSendGiftContext, useCanPay } from "features/send-gift/hooks";
import { useAuth } from "features/auth/hooks";

import { PayModal, TabCard } from ".";

const c = makeBEM("send-gift-finish");

export const FinishTab = React.memo(() => {
  const { selectedProducts, messageForm, quickSendContact, selectedUsers, contactType } = useSendGiftContext();
  const canPay = useCanPay();

  const { user } = useAuth();

  const payModal = useModalState(atoms.paymentMethod);

  const message = messageForm?.getFieldValue("comment");
  const video = messageForm?.getFieldValue("video")?.[0];

  const recipients = quickSendContact ? [quickSendContact] : selectedUsers[contactType];

  const breakdown = {
    productsPrice: selectedProducts.reduce((sum, prod) => sum + getProductPrice(prod), 0),
    productsCost: selectedProducts.reduce((sum, prod) => sum + getProductPrice(prod) + (user?.company?.fee || 0), 0),
    get subtotal() {
      return this.productsPrice * recipients.length;
    },
    serviceCharge: (user?.company?.fee || 0) * recipients.length * selectedProducts.length,
    get total() {
      return this.productsCost * recipients.length;
    },
  };

  return (
    <TabCard centerVertically>
      <div className={c("container")}>
        <WhiteSpace v="3rem" />
        <Card style={cardStyle}>
          <h3>Message</h3>
          <WhiteSpace v="0.5rem" />

          {message || (contactType === "employees" && video) ? (
            <>
              <div>{message}</div>
              {contactType === "employees" && video && (
                <>
                  <WhiteSpace v="0.5rem" />
                  <AttachmentLink {...video?.data} />
                </>
              )}
            </>
          ) : (
            <div className="color-danger">Please add a message</div>
          )}
        </Card>
        <WhiteSpace v="1rem" />

        <div className={c("recipients-products-container")}>
          <Card style={cardStyle}>
            <h3>Recipients</h3>
            <WhiteSpace v="1rem" />
            <div className={c("list")}>
              {recipients.length ? (
                recipients.map((user) => (
                  <div key={user.id}>
                    <h5>
                      {user.first_name} {user.last_name}
                    </h5>
                    <div>{user.email}</div>
                  </div>
                ))
              ) : (
                <div className="color-danger">Please add recipients</div>
              )}
            </div>
          </Card>
          <Card style={cardStyle}>
            <h3>Selected gifts</h3>
            <WhiteSpace v="1rem" />
            <div className={c("list")}>
              {selectedProducts.length ? (
                selectedProducts.map((config) => <ProductListItem key={config.product.id} data={config} />)
              ) : (
                <div className="color-danger">Please select at least one product</div>
              )}
            </div>
          </Card>
        </div>
        <WhiteSpace v="2rem" />
        <Card style={cardStyle}>
          <h3>Breakdown</h3>
          <WhiteSpace v="1rem" />
          <Grid cols="1fr 1fr" gap="0.25rem 3rem">
            <span>Value of gifts</span>
            <b>{withCurrency(breakdown.productsPrice.toFixed(2))}</b>
            <span>Number of recipients</span>
            <b>{recipients.length}</b>
            <span>Subtotal</span>
            <b>{withCurrency(breakdown.subtotal.toFixed(2))}</b>
            <span>Service Charge</span>
            <b>{withCurrency(breakdown.serviceCharge.toFixed(2))}</b>
          </Grid>
          <WhiteSpace v="1rem" />
          <h2>Total: {withCurrency(breakdown.total.toFixed(2))}</h2>
        </Card>
        <WhiteSpace v="3rem" />
        <Flex justify="center">
          <Button disabled={!canPay} size="lg" onClick={payModal.open}>
            Pay now
          </Button>
        </Flex>
        <WhiteSpace v="3rem" />
        <PayModal open={payModal.isOpen} onClose={payModal.close} />
      </div>
    </TabCard>
  );
});

const cardStyle: CSSProperties = {
  borderRadius: "1rem",
  padding: "2rem 2rem",
  /* backgroundColor: "#F3F6F9" */
};
