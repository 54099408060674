import models from "models";
import { UseInfiniteQueryResult } from "react-query";

export const combineQueryPagesData = <T>(
  data: Required<UseInfiniteQueryResult<models.WithResults<T>>["data"]>
) =>
  (data?.pages
    .map((p) => p.results)
    .flat()
    .filter((_) => _) as T[]) || [];

export const hasNextPage = (lastPage: models.WithResults<unknown>) =>
  (lastPage.total_pages || 0) > (lastPage.current_page || 0);
