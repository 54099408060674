import React from "react";

export const Delete: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M22.2666 26.9333H11.4666C10.4 26.9333 9.59998 26.1333 9.59998 25.0666V11.2H24.2666V24.9333C24.1333 26 23.3333 26.9333 22.2666 26.9333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6667 5.33325H19.0667C19.4667 5.33325 19.8667 5.59992 19.8667 6.13325V11.1999H14V6.13325C13.8667 5.73325 14.2667 5.33325 14.6667 5.33325Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M5.8667 11.2H27.7334"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M13.8667 14.5334V22.5334"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.7333 14.5334V22.5334"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
