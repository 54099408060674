import { MessageFormValues } from "features/send-gift/types";
import { useSendGiftContext } from ".";

export function useCanPay() {
  const { contactType, selectedProducts, selectedUsers, quickSendContact, messageForm } = useSendGiftContext();

  const formValues: MessageFormValues = messageForm?.getFieldsValue(["comment", "video"]);

  return (
    selectedProducts.length &&
    (selectedUsers[contactType].length || quickSendContact) &&
    (formValues.comment || (contactType === "employees" && formValues.video?.[0]?.isFinished))
  );
}
