import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";

import { Form, useForm, useNotify } from "ebs-design";

import { Flex, InputFormField, Button, Text, WhiteSpace } from "components";
import { notifyErrors } from "utils";

import { LoginFormValues } from "features/auth/types";
import { useAuth } from "features/auth/hooks";

export const LoginForm = () => {
  const auth = useAuth();

  const [form] = useForm();
  const notify = useNotify();

  const loginMutation = useMutation(auth.login, {
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: LoginFormValues) => {
    loginMutation.mutate({
      username: values.email,
      password: values.password,
    });
  };

  return (
    <Form form={form} onFinish={submitHandler}>
      <h1>Welcome to Prsnt for Business</h1>
      <Text el="span" color="gray">
        New here?
      </Text>{" "}
      <Link to="/register">Create an account</Link>
      <br />
      <WhiteSpace v="2rem" />
      <div>Email</div>
      <InputFormField type="email" name="email" label="Email" required />
      <Flex justify="space-between">
        <div>Password</div>
        <Link to="/reset-password">Forgot password?</Link>
      </Flex>
      <InputFormField type="password" name="password" label="Password" required />
      <Button loading={loginMutation.isLoading} auto>
        Sign in
      </Button>
    </Form>
  );
};
