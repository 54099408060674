import * as dateFNS from "date-fns";

import { WhiteSpace } from "components";
import models from "models";
import { getProperty, withCurrency, wrapInArr } from "utils";

import { ordersTableColumns } from "../config";

export interface RawMonthlyInvoiceProps {
  data: models.MonthlyInvoice;
  items: models.Order[];
}

export const RawMonthlyInvoice = ({ data, items }: RawMonthlyInvoiceProps) => {
  const date = new Date(data.year || 0, (data.month || 1) - 1);

  const tableColumns = ordersTableColumns({ small: true });

  return (
    <>
      <h4>Monthly Invoice</h4>
      <WhiteSpace v="1rem" />
      <h1>{dateFNS.format(date, "MMMM yyyy")}</h1>
      <WhiteSpace v="1rem" />

      <table className="modest-table">
        <tbody>
          {(
            [
              ["Gifts sent", data.gifts_count],
              ["Total fee", withCurrency(data.total_fee)],
              ["Total spent", withCurrency(data.total_paid)],
            ] as const
          ).map((pair) => (
            <tr>
              <th>{pair[0]}</th>
              <td>{pair[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <WhiteSpace v="2.5rem" />

      <h3>Orders this month</h3>
      <WhiteSpace v="1rem" />

      <table className="modest-table">
        <thead>
          {tableColumns.map((col) => (
            <th>{col.title}</th>
          ))}
        </thead>
        <tbody>
          {items.map((item) => (
            <tr>
              {tableColumns.map((col, idx) => (
                <td>
                  {col.render
                    ? col.render(getProperty(item, ...wrapInArr(col.dataIndex)), item, idx)
                    : getProperty(item, ...wrapInArr(col.dataIndex))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
