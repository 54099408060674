import React from "react";
import { useSetState } from "react-use";

import { useForm } from "ebs-design";

import { Flex, HorizontalTabPanel, HorizontalTabPanelsContainer, WhiteSpace } from "components";
import { makeBEM, wrapInArr } from "utils";

import {
  deafultSendGiftContextValue,
  SendGiftContext,
  SendGiftContextValue,
  StepContext,
} from "features/send-gift/contexts";
import { useSendGiftInitialState } from "features/send-gift/hooks";
import { RequiredSettingsWrapper } from "features/common/components";

import { FinishTab, NextButton, RecipientsTab, SelectPrsntTab, SendMethodTab, UserTypeChoice } from "../components";
import { LinearProgress } from "components/LinearProgress";
import { useScreenBreakPoint } from "hooks";

const bem = makeBEM("send-gift");

export const SendGift = () => {
  const [contextValue, setContextValue] = useSetState<SendGiftContextValue>({
    ...deafultSendGiftContextValue,
    messageForm: useForm()[0],
    quickSendForm: useForm()[0],
  });
  const [initialState, setInitialState] = useSendGiftInitialState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inChoice, setInChoice] = React.useState(true);
  const [currentStep, setCurrentStep] = React.useState(1);

  const [canGoNext, setCanGoNext] = React.useState(false);

  const lastActiveStepRef = React.useRef(currentStep);
  lastActiveStepRef.current = Math.max(lastActiveStepRef.current, currentStep);
  const lastActiveStep = lastActiveStepRef.current;

  const onMobile = useScreenBreakPoint("laptop");

  React.useEffect(() => {
    if (initialState) {
      setContextValue((prev) => ({
        contactType: initialState.contactType,
        selectedUsers: initialState.contactType
          ? {
              ...prev.selectedUsers,
              [initialState.contactType]: wrapInArr(initialState.users),
            }
          : prev.selectedUsers,
        selectedProducts: initialState.selectedProducts,
        hasInitialProducts: !!initialState.selectedProducts?.length,
      }));
      if (initialState.contactType) {
        setInChoice(false);
      }
      if (initialState.selectedProducts?.length) {
        setCurrentStep(2);
      }
      setInitialState(null);
    }
  }, [initialState, setContextValue, setInitialState]);

  const getPanelProps = (idx: number) => ({
    number: "" + idx,
    /**
     * Make next step clickable if can go next
     */
    completed: lastActiveStep >= idx - Number(canGoNext && currentStep === idx - 1),
    active: currentStep === idx,
    onNext: !onMobile && currentStep < 4 && currentStep === idx ? () => setCurrentStep((prev) => prev + 1) : undefined,
    nextDisabled: !canGoNext,
    onClick: () => setCurrentStep(idx),
  });

  return (
    <RequiredSettingsWrapper>
      <SendGiftContext.Provider value={{ ...contextValue, setContextValue }}>
        {inChoice ? (
          <UserTypeChoice {...{ setInChoice }} />
        ) : (
          <StepContext.Provider value={{ currentStep, setCurrentStep, canGoNext, setCanGoNext }}>
            <div className={bem("container")}>
              <HorizontalTabPanelsContainer>
                <Flex gap="0.5rem">
                  <HorizontalTabPanel
                    title="Select gift"
                    mobileTitle="Gift"
                    subTitle="Select what you want to send"
                    {...getPanelProps(1)}
                  />
                  <HorizontalTabPanel title="Recipients" subTitle="Who are you sending to?" {...getPanelProps(2)} />
                  <HorizontalTabPanel title="Message" subTitle="What do you want to say?" {...getPanelProps(3)} />
                  <HorizontalTabPanel title="Pay" subTitle="Review and Send" {...getPanelProps(4)} />
                </Flex>
                <WhiteSpace v="0.5rem" />

                <LinearProgress color="success" value={(lastActiveStep / 4) * 100} />
              </HorizontalTabPanelsContainer>
              {currentStep === 1 && <SelectPrsntTab />}
              {currentStep === 2 && <RecipientsTab />}
              {currentStep === 3 && <SendMethodTab />}
              {currentStep === 4 && <FinishTab />}
              {onMobile && currentStep < 4 && canGoNext && (
                <NextButton onClick={() => setCurrentStep((prev) => prev + 1)} />
              )}
            </div>
          </StepContext.Provider>
        )}
      </SendGiftContext.Provider>
    </RequiredSettingsWrapper>
  );
};
