import React from "react";

export const StatusUnwrapped: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M13.7333 5.33325L5.7333 11.9999C5.19997 12.5333 4.26664 12.3999 3.86664 11.8666L2.79997 10.6666C2.26664 9.99992 2.39997 9.19992 2.9333 8.66659L10.9333 1.99992C11.4666 1.46659 12.4 1.59992 12.8 2.13325L13.8666 3.33325C14.4 3.99992 14.2666 4.79992 13.7333 5.33325Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M6.66663 15.0667H25.3333V24.9333C25.3333 26.4 24.1333 27.6 22.6666 27.6H9.33329C7.86663 27.6 6.66663 26.4 6.66663 24.9333V15.0667Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 11.8666V9.46655"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3334 11.8666L21.0667 10.1333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12.6667 11.8666L10.9333 10.1333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.1334 22.1333L16 20.8L13.8667 22.1333V15.0667H18.1334V22.1333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
