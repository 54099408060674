import { PropsWithChildren } from "react";
import { UseQueryResult } from "react-query";
import { AlertErrors, Loader } from ".";

export interface QueryContentProps {
  query: UseQueryResult;
  showOnIdle?: boolean;
}

export const QueryContent = ({ query, showOnIdle, children }: PropsWithChildren<QueryContentProps>) => {
  if (query.isLoading) return <Loader size="lg" spread />;
  if (query.isError) return <AlertErrors error={query.error} />;
  if (query.isSuccess || (showOnIdle && query.isIdle)) return <>{children}</>;

  return <></>;
};
