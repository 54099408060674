import React from "react";

export const Settings: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M24.1333 16.0001C24.1333 15.7334 24.1333 15.6001 24.1333 15.3334C24 14.5334 24.4 13.7334 25.0667 13.3334L25.2 13.2001C26.1333 12.6667 26.5333 11.4667 26 10.4001L25.7333 10.0001C25.2 9.06675 24 8.66675 22.9333 9.20008L22.8 9.33341C22.1333 9.73341 21.3333 9.73341 20.6667 9.20008C20.2667 8.93341 19.8667 8.66675 19.4667 8.53341C18.8 8.26675 18.2667 7.46675 18.2667 6.66675V6.66675C18.2667 5.60008 17.3333 4.66675 16.2667 4.66675H15.8667C14.8 4.66675 13.8667 5.60008 13.8667 6.66675V7.06675C13.8667 7.86675 13.4667 8.53341 12.6667 8.93341C12.2667 8.93341 11.8667 9.20008 11.4667 9.46675C10.8 9.86675 9.99999 10.0001 9.33332 9.60008L9.06666 9.33341C7.99999 8.80008 6.79999 9.20008 6.26666 10.1334L5.99999 10.5334C5.46666 11.4667 5.86666 12.6667 6.79999 13.2001L7.19999 13.4667C7.86666 13.8667 8.13332 14.5334 8.13332 15.3334C8.13332 15.6001 8.13332 15.7334 8.13332 16.0001C8.13332 16.2667 8.13332 16.4001 8.13332 16.6667C8.13332 17.4667 7.86666 18.1334 7.19999 18.5334L6.79999 18.8001C5.86666 19.3334 5.46666 20.5334 5.99999 21.6001L6.26666 22.0001C6.79999 22.9334 7.99999 23.3334 9.06666 22.8001L9.33332 22.4001C9.99999 22.0001 10.9333 22.0001 11.4667 22.5334C11.8667 22.8001 12.1333 23.0667 12.5333 23.2001C13.2 23.6001 13.7333 24.2667 13.7333 25.0667V25.3334C13.7333 26.4001 14.6667 27.3334 15.7333 27.3334H16.1333C17.2 27.3334 18.1333 26.4001 18.1333 25.3334V25.0667C18.1333 24.2667 18.6667 23.6001 19.3333 23.2001C19.7333 23.0667 20.1333 22.8001 20.5333 22.5334C21.2 22.1334 22 22.0001 22.6667 22.4001L22.8 22.5334C23.7333 23.0667 24.9333 22.8001 25.6 21.7334L25.8667 21.3334C26.4 20.4001 26.1333 19.2001 25.0667 18.5334V18.6667C24.4 18.2667 24 17.4667 24.1333 16.6667C24.1333 16.4001 24.1333 16.2667 24.1333 16.0001Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M15.9999 20.1334C18.2827 20.1334 20.1332 18.2828 20.1332 16C20.1332 13.7173 18.2827 11.8667 15.9999 11.8667C13.7171 11.8667 11.8666 13.7173 11.8666 16C11.8666 18.2828 13.7171 20.1334 15.9999 20.1334Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
