import { GenericObject } from "types";
import models from "models";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const nomenclatures = {
  getEntityList: async (entity: string, query: GenericObject = {}) => {
    query.limit = 10;
    const url = stringifyUrl(`/nomenclatures/${entity}/list/`, query);
    const { data } = await apiAxios.get<models.WithResultsDeprecated<models.IdName>>(url);
    return data;
  },
}
