import React from "react";
import { Button } from "components";
import { ButtonBaseProps } from "./ButtonBase";

export type FilterCardProps = Omit<React.HTMLAttributes<HTMLButtonElement>, "color"> &
  ButtonBaseProps & {
    active?: boolean;
  };

export const FilterCard: React.FC<FilterCardProps> = ({ active, className, ...props }) => {
  return (
    <Button
      color={active ? "primary" : "secondary"}
      size="lg"
      style={{ borderRadius: "1rem", fontWeight: "normal" }}
      subtle={!active}
      bordered={active}
      auto
      {...props}
    />
  );
};
