import { GenericObject } from "types";
import { NamePath } from "rc-field-form/es/interface";
import { makeNamePath } from "../../utils";

export const mixins = {
  one:
    <T extends string>(name: T) =>
    (id: number | string) =>
      [name, id] as const,
  many:
    (...name: NamePath[]) =>
    (query: GenericObject = {}) =>
      [...makeNamePath(...name), query] as const,
};
