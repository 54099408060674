import { RefObject } from "react";

import { Meta } from "rc-field-form/lib/interface";

import { Form, FormInstance, Input, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { InputProps } from "ebs-design/dist/components/atoms/Input/Input";
import { TextareaProps } from "ebs-design/dist/components/molecules/Textarea/Textarea";

export interface InputFormFieldProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  textarea?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputRef?: RefObject<HTMLInputElement>;
  getInputProps?: (control: any, meta: Meta, form: FormInstance) => InputProps & any;
  getTextareaProps?: (control: any, meta: Meta, form: FormInstance) => TextareaProps & any;
}

export const InputFormField = ({
  rules = [],
  required,
  type,
  initialValue = "",
  hideLabel = true,
  textarea,
  disabled,
  placeholder,
  inputRef,
  getInputProps,
  getTextareaProps,
  ...restProps
}: InputFormFieldProps) => {
  return (
    <Form.Field
      {...restProps}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
        hideLabel,
      }}
    >
      {(control, meta, form) => {
        return textarea ? (
          <Textarea disabled={disabled} {...getInputProps?.(control, meta, form)} {...control} />
        ) : (
          <Input
            ref={inputRef}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            {...getTextareaProps?.(control, meta, form)}
            {...control}
          />
        );
      }}
    </Form.Field>
  );
};
