import { FormInstance } from "ebs-design/dist/components/organisms/Form";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

type Rule = Required<FormFieldProps>['rules'][number];

export const confirmPasswordValidatorRule = (form: FormInstance): Rule => ({
  validateTrigger: "onSubmit",
  validator: (_, value) => {
    return value === form.getFieldValue("password")
      ? Promise.resolve()
      : Promise.reject("Confirm password does not match password");
  }
})