import { PageContainer } from "components";

export const PrivacyPolicy = () => {
  return (
    <PageContainer>
      <iframe
        style={{ width: "100%", minHeight: "85vh" }}
        title="Privacy Policy"
        src="/static/privacy-policy.html"
      ></iframe>
    </PageContainer>
  );
};
