import React from "react";
import cn from "classnames";

export interface CardProps extends React.HTMLProps<HTMLDivElement> {}

export const Card: React.FC<CardProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(className, "card")} {...props}>
      {children}
    </div>
  );
};
