import { Attachment } from "./attachments";
import { Product, ProductSendConfigPost } from "./products";
import { SendMethodType } from "./send-gift";
import { UserContactType } from "./users";

export interface AutoGift {
  id?: number;
  product?: Product;
  video?: Attachment;
  title?: string;
  description?: string;
  message?: string;
  contact_type?: UserContactType;
  user?: 338;
  payment_method?: string;
  notification_method?: SendMethodType;
  active?: boolean;
  timer?: string;
  trigger?: AutoGiftTrigger.custom;
}

export enum AutoGiftTrigger {
  birthday = "birthday",
  special_day = "special_day",
  custom = "other",
}

export interface AutoGiftPatch extends Omit<AutoGift, "video" | "product">, Partial<ProductSendConfigPost> {
  video?: number;
}

export type GroupedAutoGifts = Record<UserContactType, AutoGift[]>;
