import React from "react";
import { Button, ButtonProps, Icon } from "components";
import { makeBEM } from "utils";

export interface NextButtonProps extends ButtonProps {
  position?: "top" | "bottom";
}

const bem = makeBEM("send-gift");

export const NextButton: React.FC<NextButtonProps> = ({ position = "bottom", ...props }) => {
  return (
    <Button
      icon={<Icon type="back" size="var(--icon-size)" />}
      className={bem("next-button", [position])}
      auto
      rounded
      {...props}
    />
  );
};
