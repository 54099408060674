import { PageContainer } from "components";

export const TermsConditions = () => {
  return (
    <PageContainer>
      <iframe
        style={{ width: "100%", minHeight: "85vh" }}
        title="Privacy Policy"
        src="/static/terms-conditions.html"
      ></iframe>
    </PageContainer>
  );
};
