import React from "react";

export const Cart: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
        fill={fill}
        opacity="0.3"
      />
      <path
        d="M3.50026 9H20.5003C21.0525 9 21.5003 9.44772 21.5003 10C21.5003 10.132 21.4741 10.2627 21.4233 10.3846L17.7695 19.1538C17.3037 20.2718 16.2114 21 15.0003 21H9.00026C7.78916 21 6.69684 20.2718 6.23103 19.1538L2.57718 10.3846C2.36477 9.87481 2.60584 9.28934 3.11565 9.07692C3.23752 9.02614 3.36823 9 3.50026 9ZM12.0003 17C13.1048 17 14.0003 16.1046 14.0003 15C14.0003 13.8954 13.1048 13 12.0003 13C10.8957 13 10.0003 13.8954 10.0003 15C10.0003 16.1046 10.8957 17 12.0003 17Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};
