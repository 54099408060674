import { Card, Image } from "components";
import { makeBEM } from "utils";

import infoImageSrc from "../assets/info_image.jpg";

const c = makeBEM("dashboard-info-cards");
export const InfoCards = () => {
  return (
    <div className={c("container")}>
      <Card className={c("item")}>
        <h2 className={c("item-header")}>How to use Prsnt For Business</h2>
        <ol>
          <li>Create a team in Employees or Clients</li>
          <li>Select recipient and pick a gift</li>
          <li>Personalise with a text or video message</li>
          <li>Delivered instantly via email or mobile app</li>
        </ol>
      </Card>
      <Card className={c("item", ["with-image"])}>
        <Image objectFit="contain" src={infoImageSrc} />
      </Card>
      <Card className={c("item")}>
        <h2 className={c("item-header")}>Key benefits</h2>
        <ul>
          <li>Digital gifting is ultra sustainable </li>
          <li>Super simple to use</li>
          <li>Automated “set and forget” calendar </li>
          <li>Increase team productivity and loyalty</li>
        </ul>
      </Card>
    </div>
  );
};
