import { GenericObject } from "types";

export const omitKeys = (obj: GenericObject, keys: string[]): GenericObject => {
  const updatedObj = { ...obj };
  for (const n of keys) delete updatedObj[n];
  return updatedObj;
};

export const typedOmitKeys = <T extends object, TKeys extends keyof T>(obj: T, keys: TKeys[]): Omit<T, TKeys> => {
  const updatedObj = { ...obj };
  for (const key of keys) delete updatedObj[key];
  return updatedObj;
};

export const typedPickKeys = <T extends object, TKeys extends keyof T>(obj: T, keys: TKeys[]): Pick<T, TKeys> => {
  const picked: Partial<Pick<T, TKeys>> = {};
  for (const key of keys) picked[key] = obj[key];
  return picked as Pick<T, TKeys>;
};

export const falsyToUndefined = (obj: any): any =>
  obj instanceof Array
    ? obj.map((i) => falsyToUndefined(i))
    : obj instanceof Date
    ? obj
    : obj instanceof Object
    ? Object.fromEntries(Object.entries(obj).map((e) => [e[0], falsyToUndefined(e[1])]))
    : obj || undefined;

export function getProperty<TReturn = any>(obj: any, ...args: (string | number | symbol)[]): TReturn | undefined {
  for (let i = 0; i < args.length; i++) {
    if (obj instanceof Object && obj.hasOwnProperty(args[i])) {
      obj = obj[args[i]];
    } else return undefined;
  }
  return obj as TReturn;
}
