import React from "react";

export const Verify: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M4.76221 9.06805C4.76221 9.06805 5.69591 6.93925 7.09647 6.70272C8.49703 6.46618 9.66416 7.41231 11.0647 9.06805C12.4653 10.7238 11.765 11.9064 11.0647 12.8526C10.3644 13.7987 8.96389 14.9814 9.43074 15.9275C9.89759 16.8736 13.1656 20.4216 13.1656 20.4216C13.1656 20.4216 15.2664 22.787 16.4335 22.787C17.6007 22.787 18.0675 22.0774 18.7678 21.3678C19.4681 20.6582 20.6352 19.0024 22.5026 20.4216C24.37 21.8408 25.5372 22.787 25.3037 23.9696C25.0703 25.1523 23.6698 27.7542 19.7015 27.9907C15.7333 28.2272 9.43074 23.9696 5.92934 18.2928C2.42794 12.616 4.76221 9.06805 4.76221 9.06805Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.6666 9.42857L21.2753 12L25.3333 8"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="22"
        cy="10"
        r="6"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
