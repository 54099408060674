import React from "react";

export const Share: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M29.3333 12.7207L19.4146 4V9.97532C10.1463 9.97532 2.66663 17.4041 2.66663 26.6093C2.66663 27.5783 7.86988 15.9506 19.4146 15.9506V21.28L29.3333 12.7207Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
