import models from "models";
import { apiAxios } from "./axios";

export const autoGifts = {
  get: async () => {
    const { data } = await apiAxios.get<models.GroupedAutoGifts>(`/gifts/automation/?group_by=contact_type`);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.AutoGift>(`/gifts/automation/${id}/`);
    return data;
  },
  post: async (autoGift: models.AutoGiftPatch) => {
    const { data } = await apiAxios.post<models.AutoGift>(`/gifts/automation/`, autoGift);
    return data;
  },
  patch: async (id: number, autoGift: models.AutoGiftPatch) => {
    const { data } = await apiAxios.patch<models.AutoGift>(`/gifts/automation/${id}/`, autoGift);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete<models.AutoGift>(`/gifts/automation/${id}/`);
    return data;
  },
};
