export interface Setting {
  id?: number;
  code_name?: SettingCodeName;
  value?: string;
}

export enum SettingCodeName {
  fee = "FEE",
  vat = "VAT",
}
