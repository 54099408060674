import { TabProviderProps, TabsBase } from "components";
import { makeBEM } from "utils";

import { VerticalTabsPanel, VerticalTabsPanelProps } from "./VerticalTabsPanel";

export interface VerticalTabsContainerProps extends TabProviderProps {
  panels: VerticalTabsPanelProps[];
}

const c = makeBEM("vertical-tabs");

export const VerticalTabsContainer: React.FC<VerticalTabsContainerProps> = ({ panels, children, ...restProps }) => {
  return (
    <TabsBase.Provider className={c("container")} {...restProps}>
      <div className={c("panels-container")}>
        {panels.map((p) => (
          <VerticalTabsPanel {...p} key={p.tabKey} />
        ))}
      </div>
      <div className={c("space")} />
      <div className={c("content-container")}>{children}</div>
    </TabsBase.Provider>
  );
};
