import React from "react";
import { makeBEM } from "utils";

export interface LayoutProps {}

const bem = makeBEM("layout");

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <div className={bem()}>{children}</div>;
};
