import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const categories = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/categories/list/`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Category>>(url);
    return data;
  },
  getChildren: async (id: number, query: GenericObject = {}) => {
    const url = stringifyUrl(`/categories/${id}/children/list/`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Category>>(url);
    return data;
  },
};
