import React from "react";

export const CalendarStandard: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 28H6.66667C5.2 28 4 26.8 4 25.3334V9.46672C4 8.00005 5.2 6.80005 6.66667 6.80005H25.3333C26.8 6.80005 28 8.00005 28 9.46672V25.3334C28 26.8 26.8 28 25.3333 28Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9333 4V9.86667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6 4V9.86667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M4 12.2666H28"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
