import React, { forwardRef } from "react";
import cn from "classnames";

import { makeBEM } from "utils";
import { useControlledState } from "hooks";

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  indeterminate?: boolean;
  onChange?: (value: boolean /*  e: React.ChangeEvent<HTMLInputElement> */) => void;
}

const bem = makeBEM("checkbox");

export const Checkbox: React.FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, onChange, indeterminate, checked: _checked, children, ...props }, ref) => {
    const [checked, setChecked] = useControlledState(_checked, onChange, false);

    return (
      <label className={cn(className, bem(null, { checked: checked, indeterminate: !checked && indeterminate }))}>
        <div className={bem("check-wrapper")}>
          <input
            ref={ref}
            checked={checked}
            onChange={(e) => {
              // onChange?.(e.target.checked /* e */);
              setChecked(e.currentTarget.checked);
            }}
            type="checkbox"
            {...props}
          />
          <div className={bem("check-background")} />
          <div className={bem("check-icon")} />
        </div>
        <div className={bem("text")}>{children}</div>
      </label>
    );
  }
);
