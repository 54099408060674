import { Tooltip } from "ebs-design";
import React, { useContext } from "react";
import { makeBEM } from "utils";
import { SidebarContext } from "./Sidebar/SidebarContext";

export interface SidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title?: string;
  active?: boolean;
  secondary?: boolean;
}

const bem = makeBEM("sidebar-item");

export const SidebarItem: React.FC<SidebarItemProps> = ({ title, icon, active, secondary, children, ...props }) => {
  const { collapsed } = useContext(SidebarContext);

  const getChildren = (children: React.ReactNode) =>
    collapsed && title ? (
      <Tooltip delayShow={250} offset={[4, 10]} className="dark-tooltip" hideArrow tooltip={title} placement="right">
        {children}
      </Tooltip>
    ) : (
      children
    );

  return (
    <>
      {getChildren(
        <div className={bem(null, { active, secondary })} {...props}>
          <div className={bem("icon")}>{icon}</div>
          <div className={bem("text")}>{children}</div>
        </div>
      )}
    </>
  );
};
