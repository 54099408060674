import React from "react";

export const List: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <g>
          <path
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            d="M9.5,1.5H11a1,1,0,0,1,1,1v10a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1H4.5"
            strokeWidth={1}
          />
          <rect
            width="5"
            height="2.5"
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            rx="1"
            x="4.5"
            y="0.5"
            strokeWidth={1}
          />
          <line
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            x1="4.5"
            x2="9.5"
            y1="5.5"
            y2="5.5"
            strokeWidth={1}
          />
          <line
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            x1="4.5"
            x2="9.5"
            y1="8"
            y2="8"
            strokeWidth={1}
          />
          <line
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            x1="4.5"
            x2="9.5"
            y1="10.5"
            y2="10.5"
            strokeWidth={1}
          />
        </g>
      </g>
    </svg>
  );
};
