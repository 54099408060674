import React from "react";

export const Birthday2: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 24.7999H6.66663V14.9333C6.66663 13.4666 7.86663 12.2666 9.33329 12.2666H22.6666C24.1333 12.2666 25.3333 13.4666 25.3333 14.9333V24.7999Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M24.9333 28.0001H7.06667C6 28.0001 5.06667 27.3334 4.66667 26.4001L4 24.8H28L27.3333 26.4001C26.9333 27.3334 26 28.0001 24.9333 28.0001Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3333 18.2667C24.6666 17.4667 23.4666 17.4667 22.6666 18.2667C22 19.0667 20.8 19.0667 20 18.2667C19.3333 17.4667 18.1333 17.4667 17.3333 18.2667C16.6666 19.0667 15.4666 19.0667 14.6666 18.2667C14 17.4667 12.8 17.4667 12 18.2667C11.3333 19.0667 10.1333 19.0667 9.33329 18.2667C8.66663 17.4667 7.46663 17.4667 6.66663 18.2667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 11.6001V9.6001"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6666 9.06667C13.9999 8.4 13.9999 7.46666 14.3999 6.8L15.9999 4L17.5999 6.8C17.9999 7.6 17.8666 8.53333 17.3333 9.06667C16.5333 9.86667 15.4666 9.86667 14.6666 9.06667Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
