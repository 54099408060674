import React from "react";

export const Alert: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.23 20.3048C15.1048 20.3048 14.9984 20.2604 14.9108 20.1715C14.8231 20.0826 14.7793 19.9747 14.7793 19.8477V11.1239C14.7793 10.9969 14.8231 10.889 14.9108 10.8001C14.9984 10.7112 15.1048 10.6667 15.23 10.6667H16.7511C16.8888 10.6667 16.9953 10.7112 17.0704 10.8001C17.158 10.8763 17.2018 10.9842 17.2018 11.1239V19.8477C17.2018 19.9747 17.158 20.0826 17.0704 20.1715C16.9953 20.2604 16.8888 20.3048 16.7511 20.3048H15.23ZM15.1173 24.0001C14.9921 24.0001 14.8857 23.9556 14.7981 23.8667C14.7104 23.7779 14.6666 23.6699 14.6666 23.5429V21.7715C14.6666 21.6318 14.7104 21.5175 14.7981 21.4287C14.8857 21.3398 14.9921 21.2953 15.1173 21.2953H16.8638C17.0015 21.2953 17.1142 21.3398 17.2018 21.4287C17.2895 21.5175 17.3333 21.6318 17.3333 21.7715V23.5429C17.3333 23.6699 17.2895 23.7779 17.2018 23.8667C17.1142 23.9556 17.0015 24.0001 16.8638 24.0001H15.1173Z"
          fill={fill}
        />
        <path
          d="M14.701 5.91675C15.2783 4.91675 16.7217 4.91675 17.299 5.91675L27.6913 23.9167C28.2687 24.9167 27.547 26.1667 26.3923 26.1667H5.60769C4.45299 26.1667 3.73131 24.9167 4.30866 23.9167L14.701 5.91675Z"
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect height="32" width="32" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
};
