import { atomWithHash } from "jotai/utils";

export const atoms = {
  // General
  add: atomWithHash("add", false),
  edit: atomWithHash("edit", false),
  delete: atomWithHash("delete", false),
  details: atomWithHash("details", false),

  // Gifts
  productFilters: atomWithHash("products-filters", false),
  paymentMethod: atomWithHash("payment-method", false),
  characteristics: atomWithHash("characteristics", false),

  // Users
  addGroup: atomWithHash("add-group", false),
  groups: atomWithHash("groups", false),
  editGroup: atomWithHash("edit-group", false),
  deleteGroup: atomWithHash("delete-group", false),
  csv: atomWithHash("csv", false),

  // Dashboard
  monthlyInovice: atomWithHash("monthly-invoice", false),

  // Settings
  logout: atomWithHash("logout", false),
};
