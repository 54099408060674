import React from "react";
import cn from "classnames";

import { TabContext } from "./TabProvider";
import { WithTabKey } from "./types";

export const TabPanel = ({
  children,
  tabKey,
  className,
  ...props
}: WithTabKey) => {
  const {
    activeTabClassName: activeCls,
    active,
    setActive,
  } = React.useContext(TabContext);

  return (
    <div
      className={cn(className, tabKey === active && activeCls)}
      onClick={() => active !== tabKey && setActive(tabKey)}
      {...props}
    >
      {children}
    </div>
  );
};
