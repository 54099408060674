import { Text } from "components";

export const renderTitleSubtitle =
  (titleKey: string, subtitleKey: string, fromRecord?: boolean) => (val: any, record: any) => {
    const obj = fromRecord ? record : val;
    return (
      <div>
        {obj?.[titleKey]}
        <br />
        <Text color="gray">{obj?.[subtitleKey]}</Text>
      </div>
    );
  };
