import React from "react";
import { makeBEM } from "utils";

export interface DrawerProps {
  open?: boolean;
  onClose?: () => void;
}

const bem = makeBEM("drawer");

export const Drawer: React.FC<DrawerProps> = ({ open, onClose, children }) => {
  return (
    <>
      <div className={bem(null, { open })}>{children}</div>
      <div className={bem("overlay", { visible: open })} onClick={onClose}></div>
    </>
  );
};
