import React from "react";

import { TabContext } from "./TabProvider";
import { WithTabKey } from "./types";

export const TabContent = ({
  tabKey,
  children,
  style: propsStyle,
  ...props
}: WithTabKey) => {
  const { active } = React.useContext(TabContext);

  return (
    <div
      style={{ display: tabKey === active ? undefined : "none", ...propsStyle }}
      {...props}
    >
      {children}
    </div>
  );
};
