import { UseQueryResult } from "react-query";

import { AlertErrors, Table, TableProps, Pagination, Loader } from "components";
import models from "models";
import { UsePaginationResult } from "hooks";

export interface QueryTableProps<RecordType> extends TableProps<RecordType> {
  query: UseQueryResult<models.WithResults<RecordType>>;
  columns: TableProps<RecordType>["columns"]; // required
  pagination?: UsePaginationResult;
  perPageChoice?: number[];
}
export const QueryTable = <RecordType,>({
  query,
  pagination,
  perPageChoice,
  variants,
  footer,
  ...tableProps
}: QueryTableProps<RecordType>) => {
  return (
    <>
      <AlertErrors error={query.error} />
      {!query.isError && (
        <Table
          data={query.data?.results}
          emptyText={query.isLoading ? <Loader size="lg" spread /> : <div className="py-6 text-center">No data</div>}
          footer={
            pagination
              ? (...args) => (
                  <>
                    {/* <Flex className="py-4 px-4" justify="space-between" wrap="wrap-reverse" gap="1rem"> */}
                    <div>{footer?.(...args)}</div>
                    <Pagination perPageChoice={perPageChoice} pagination={pagination} data={query.data} />
                    {/* </Flex> */}
                  </>
                )
              : undefined
          }
          rowKey="id"
          scroll={{ x: true }}
          variants={[
            ...(variants || []),
            "bordered",
            "elevated",
            // "stripped-even",
          ]}
          {...tableProps}
        />
      )}
    </>
  );
};
