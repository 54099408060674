import { Login, Register, ResetPassword } from "features/auth/pages";
import { Clients } from "features/clients/pages";
import { Employees } from "features/employees/pages";
import { Dashboard } from "features/dashboard/pages";
import { SendGift } from "features/send-gift/pages";
import { Settings } from "features/settings/pages";
import { AutoGifts, EditAutoGift, NewAutoGift } from "features/auto-gifts/pages";
import { NotFound } from "features/not-found/pages";
import { PrivacyPolicy } from "features/privacy-policy/pages";
import { TermsConditions } from "features/terms-conditions/pages";
import { Contact } from "features/contact/pages";
import { Catalogue } from "features/catalogue/pages";

import { RouteBuilder } from "utils";

export const authRoutes = new RouteBuilder()
  .add("/login", Login)
  .add("/register", Register)
  .add("/reset-password", ResetPassword)
  .getRoutes();

export const appRoutes = new RouteBuilder()
  .add("/", Catalogue, true)
  .add("/account", Dashboard)
  .add("/settings", Settings)
  .add("/clients", Clients)
  .add("/employees", Employees)
  .add("/send-gift", SendGift)
  .add("/auto-gifts", AutoGifts, true)
  .add("/auto-gifts/new", NewAutoGift)
  .add("/auto-gifts/:id/edit", EditAutoGift)
  .add("/privacy-policy", PrivacyPolicy)
  .add("/terms-conditions", TermsConditions)
  .add("/contact", Contact)
  .add("*", NotFound)
  .getRoutes();
