import { Flex, Image } from "components";
import models from "models";
import { withCurrency } from "utils";

export interface ProductListItemProps {
  data: models.ProductSendConfig;
}

export const ProductListItem = ({ data }: ProductListItemProps) => {
  return (
    <Flex gap="0.5rem" align="center">
      <Image height={40} width={40} src={data.product.featured_image?.url || data.product.image?.url} alt="" />
      <div className="flex-1">
        <h3>{data.product.title}</h3>
        <b className="color-gray">
          {withCurrency(
            data.product_characteristic_choices ? data.product_characteristic_choices.price : data.product.price
          )}
        </b>
      </div>
      <Image width={45} height={25} src={data.product.brand?.image?.url} />
    </Flex>
  );
};
