import models from "models";

import { Image } from "components";

import visaSrc from "features/payments/assets/visa.svg";
import mastercardSrc from "features/payments/assets/mastercard.svg";
import discoverSrc from "features/payments/assets/discover.svg";
import amexSrc from "features/payments/assets/amex.svg";
import dinersSrc from "features/payments/assets/diners.svg";
import jcbSrc from "features/payments/assets/jcb.svg";
import unionpaySrc from "features/payments/assets/unionpay.svg";

export interface CardBrandImageProps {
  type?: models.PaymentMethodBrand | (string & {});
}

const brandMap: Record<models.PaymentMethodBrand, string> = {
  visa: visaSrc,
  mastercard: mastercardSrc,
  discover: discoverSrc,
  amex: amexSrc,
  diners: dinersSrc,
  jcb: jcbSrc,
  unionpay: unionpaySrc,
};

export const CardBrandImage = ({ type }: CardBrandImageProps) => {
  return (
    <Image
      borderRadius="0px"
      objectFit="contain"
      width="2rem"
      height="1.5rem"
      src={brandMap[type as models.PaymentMethodBrand] ?? ""}
      alt=""
    />
  );
};
