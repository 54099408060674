import models from "models";
import { GenericObject } from "types";
import { apiAxios } from "api/axios";
import { stringifyUrl } from "api/utils";

/** @deprecated */
export const products = {
  grouped: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/products/all/`, query);
    const { data } = await apiAxios.get<models.GroupedProductListCategory[]>(url);

    return data;
  },
};
