import { CSSProperties } from "react";
import { useQuery } from "react-query";

import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import api, { querykeys } from "api";
import { Card, Flex, Grid, AttachmentLink, WhiteSpace, ProductListItem, QueryContent } from "components";
import { isValidDate, makeBEM, withCurrency } from "utils";
import { LOCALE } from "app-constants";

const c = makeBEM("order-invoice");

export interface InvoiceModalProps extends ModalProps {
  operationId?: string;
}

export const InvoiceModal = ({ operationId, ...props }: InvoiceModalProps) => {
  const query = useQuery(querykeys.orders.one(operationId || ""), () => api.orders.getInvoice(operationId || ""), {
    enabled: !!operationId,
  });
  const order = query.data;

  const productsPrice = order?.products?.reduce((sum, prod) => sum + (prod.price || 0), 0) || 0;

  // ? may be possibility to print the invoice
  // const contentRef = React.useRef<HTMLDivElement>(null);
  // const buttonRef = React.useRef<HTMLDivElement>(null);
  // const printHandler = () => {
  //   const $content = contentRef.current,
  //     $button = buttonRef.current;
  //   if ($content && $button) {
  //     const prevDisplay = $button.style.getPropertyValue("display");
  //     $button.style.setProperty("display", "none");
  //     printElement($content);
  //     $button.style.setProperty("display", prevDisplay);
  //   }
  // };

  const date = new Date(order?.date || "");

  return (
    <Modal {...props}>
      <Modal.Content>
        <div
        // ref={contentRef}
        >
          <QueryContent query={query}>
            <Flex justify="space-between" align="center">
              <h1>Invoice</h1>
              <div className="text-right">{isValidDate(date) ? date.toLocaleDateString(LOCALE) : "No date"}</div>
            </Flex>
            <WhiteSpace v="1rem" />
            <hr />
            <WhiteSpace v="2rem" />
            <Card style={cardStyle}>
              <h4>Message</h4>
              {order?.comment?.comment}
              {order?.video && (
                <>
                  <WhiteSpace v="0.5rem" />
                  <AttachmentLink {...order?.video} />
                </>
              )}
            </Card>
            <WhiteSpace v="1rem" />

            <div className={c("recipients-products-container")}>
              <Card style={cardStyle}>
                <h3>Recipients</h3>
                <WhiteSpace v="1rem" />
                <div className={c("list")}>
                  {order?.contacts?.length ? (
                    order.contacts.map((user) => (
                      <div key={user.id}>
                        <h5>
                          {user.first_name} {user.last_name}
                        </h5>
                        <div>{user.email}</div>
                      </div>
                    ))
                  ) : (
                    <div>No recipients</div>
                  )}
                </div>
              </Card>
              <Card style={cardStyle}>
                <h3>Selected gifts</h3>
                <WhiteSpace v="1rem" />
                <div className={c("list")}>
                  {order?.products?.length ? (
                    order.products.map((product) => <ProductListItem key={product.id} data={{ product }} />)
                  ) : (
                    <div>No products</div>
                  )}
                </div>
              </Card>
            </div>
            <WhiteSpace v="1rem" />
            <Card style={cardStyle}>
              <h3>Breakdown</h3>
              <WhiteSpace v="1rem" />
              <Grid cols="1fr 1fr" gap="0.25rem 3rem">
                <span>Value of gifts</span>
                <b>{withCurrency(productsPrice)}</b>
                <span>Number of recipients</span>
                <b>{order?.contacts_count}</b>
                <span>Subtotal</span>
                <b>{withCurrency(order?.subtotal)}</b>
                <span>Service Charge</span>
                <b>{withCurrency(order?.service_charge)}</b>
              </Grid>
              <WhiteSpace v="1rem" />
              <h2>Total: {withCurrency(order?.total)}</h2>
            </Card>
            <WhiteSpace v="3rem" />
          </QueryContent>
        </div>
        {/* <WhiteSpace v="1rem" />
        <Flex justify="end">
          <Button color="secondary" bordered onClick={props.onClose}>
            Close
          </Button>
        </Flex> */}
      </Modal.Content>
    </Modal>
  );
};

const cardStyle: CSSProperties = { backgroundColor: "#F3F6F9" };
