import { useCallback, useMemo, useState } from 'react';

interface StableActions<K> {
  add: (key: K) => void;
  addMany: (...keys: K[]) => void;
  remove: (key: K) => void;
  removeMany: (...keys: K[]) => void;
  toggle: (key: K) => void;
  reset: () => void;
}

export interface UseSetActions<K> extends StableActions<K> {
  has: (key: K) => boolean;
}

export const useSet = <K>(initialSet = new Set<K>()): [Set<K>, UseSetActions<K>] => {
  const [set, setSet] = useState(initialSet);

  const stableActions = useMemo<StableActions<K>>(() => {
    const add = (item: K) => setSet((prevSet) => new Set([...Array.from(prevSet), item]));
    const addMany = (...items: K[]) => setSet((prevSet) => new Set([...Array.from(prevSet), ...items]));
    const remove = (item: K) =>
      setSet((prevSet) => new Set(Array.from(prevSet).filter((i) => i !== item)));
    const removeMany = (...items: K[]) =>
      setSet((prevSet) => new Set(Array.from(prevSet).filter((i) => !items.includes(i))));
    const toggle = (item: K) =>
      setSet((prevSet) =>
        prevSet.has(item)
          ? new Set(Array.from(prevSet).filter((i) => i !== item))
          : new Set([...Array.from(prevSet), item])
      );

    return { add, addMany, remove, removeMany, toggle, reset: () => setSet(initialSet) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const utils = {
    has: useCallback((item) => set.has(item), [set]),
    ...stableActions,
  } as UseSetActions<K>;

  return [set, utils];
};
