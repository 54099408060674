import React from "react";
import { useUpdateEffect } from "react-use";

export interface CollapseProps extends Omit<React.HTMLProps<HTMLDivElement>, "style"> {
  collapsed?: boolean;
  time?: number; // milliseconds
}

export const Collapse = ({ collapsed, time = 0, children, ...props }: CollapseProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const timeoutRef = React.useRef(0);

  useUpdateEffect(() => {
    if (time <= 0) return;

    const $el = ref.current;
    if (!$el) return;

    window.clearTimeout(timeoutRef.current);

    if (collapsed) {
      $el.style.height = $el.clientHeight + "px";
      $el.style.overflow = "hidden";

      window.setTimeout(() => {
        $el.style.height = "0px";
      }, 10);
    } else {
      $el.style.height = "0px";
      window.setTimeout(() => {
        $el.style.height = $el.scrollHeight + "px";
      }, 0);

      timeoutRef.current = window.setTimeout(() => {
        $el.style.removeProperty("height");
        $el.style.removeProperty("overflow");
      }, time);
    }
  }, [collapsed, time]);

  React.useEffect(() => {
    if (time <= 0) return;

    const $el = ref.current;
    if (!$el) return;

    if (collapsed) {
      $el.style.height = "0px";
      $el.style.overflow = "hidden";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   if (time !== 0) return;

  //   const $el = ref.current;
  //   if (!$el) return;

  //   if (collapsed) {
  //     $el.style.height = "0px";
  //     $el.style.overflow = "hidden";
  //   } else {
  //     $el.style.removeProperty("overflow");
  //     $el.style.removeProperty("height");
  //   }
  // }, [collapsed, time]);

  return (
    <div style={{ transition: `${time}ms` }} ref={ref} {...props}>
      {time === 0 ? !collapsed && children : children}
    </div>
  );
};
