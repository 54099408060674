import React from "react";

export const InviteFriends: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M6 27.1335C6 22.3335 9.86667 18.4668 14.6667 18.4668C19.4667 18.4668 23.3333 22.3335 23.3333 27.1335"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5333 15.4C17.4051 15.4 19.7333 13.0719 19.7333 10.2C19.7333 7.32812 17.4051 5 14.5333 5C11.6614 5 9.33325 7.32812 9.33325 10.2C9.33325 13.0719 11.6614 15.4 14.5333 15.4Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <line
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="31.5"
        x2="21.5"
        y1="14.5"
        y2="14.5"
      />
      <line
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="26.5"
        x2="26.5"
        y1="9.5"
        y2="19.5"
      />
    </svg>
  );
};
