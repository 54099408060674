import React from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";

import { Modal, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { falsyToUndefined, notifyErrors, wrapInArr } from "utils";
import models from "models";
import api, { querykeys } from "api";
import { QueryList, MutationConfirmModal, WhiteSpace, Flex, Button, Icon } from "components";

import { AddPaymentMethodForm, PaymentMethodCard } from "features/payments/components";
import { useSendGiftContext } from "features/send-gift/hooks";
import { MessageFormValues } from "features/send-gift/types";

export interface PayModalProps extends ModalProps {}

export const PayModal = ({ ...props }: PayModalProps) => {
  const { contactType, messageForm, quickSendContact, selectedUsers, selectedProducts } = useSendGiftContext();

  const notify = useNotify();
  const stripe = useStripe();
  const history = useHistory();

  const usersApi = api.companies.createUsersApi(contactType);

  /** Payment method */
  const [selectedPM, setSelectedPM] = React.useState<models.PaymentMethod>();
  const [isAddPMLoading, setIsAddPMLoading] = React.useState(false);

  const [deleteModalData, setDeleteModalData] = React.useState<models.PaymentMethod>();

  const query = useQuery(querykeys.paymentMethods.many(), api.paymentMethods.getList, {
    select: (data) => data?.filter((paymentMethod) => paymentMethod.type === models.PaymentMethodType.card),
  });

  React.useEffect(() => {
    if (query.isSuccess) setSelectedPM(query.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess]);

  const deleteMutation = useMutation(() => api.paymentMethods.delete(deleteModalData?.id || ""), {
    onError: (error) => notifyErrors(notify, error),
    onSuccess: () => {
      query.refetch();
      if (selectedPM?.id === deleteModalData?.id) setSelectedPM(undefined);
    },
  });

  const addContactMutation = useMutation(usersApi.post);

  const payMutation = useMutation(
    async (payload: models.SetupGiftPayload) => {
      const response = await usersApi.setupGift(payload);

      const { error } =
        (await stripe?.confirmCardPayment(response.payment_intent?.client_secret || "", {
          payment_method: selectedPM?.id,
        })) || {};
      if (error) throw new Error(error.message);
      history.push("/account");
    },
    { onError: (error) => notifyErrors(notify, error) }
  );

  const addPMSuccessHandler = (pm: models.PaymentMethod) => {
    setSelectedPM(pm);
    query.refetch();
    payWith(pm?.id);
  };

  const payWith = async (pmid?: string) => {
    let contactId: number | undefined = undefined;

    if (quickSendContact) {
      contactId =
        (
          await addContactMutation.mutateAsync(
            falsyToUndefined({
              ...quickSendContact,
              phone: quickSendContact.phone ? "+" + quickSendContact.phone : undefined,
            })
          )
        ).id || 0;
    }

    const formValues: MessageFormValues = messageForm?.getFieldsValue(["comment", "video"]);

    payMutation.mutate({
      comment: formValues?.comment || undefined,
      video: formValues?.video?.[0]?.data?.id || undefined,
      contacts: contactId ? [contactId] : selectedUsers[contactType].map((c) => c.id || 0),
      notification_method: contactType === "clients" ? models.SendMethodType.email : undefined,
      payment_method: pmid || "",
      products: selectedProducts.map((config) => ({
        product: config.product.id || 0,
        product_characteristic_choices: wrapInArr(config.product_characteristic_choices?.id),
      })), // .map((p) => p.id || 0),
    });
  };

  const isPaying = addContactMutation.isLoading || payMutation.isLoading;

  return (
    <>
      <Modal size="small" {...props}>
        <Modal.Content>
          <QueryList
            query={query}
            getItems={(data) => data}
            renderItems={(items?: models.PaymentMethod[]) => (
              <>
                <h2>Select a payment method</h2>
                <WhiteSpace v="1rem" />
                {items?.map((paymentMethod) => (
                  <PaymentMethodCard
                    onClick={() => setSelectedPM(paymentMethod)}
                    selectable
                    selected={paymentMethod.id === selectedPM?.id}
                    className="my-3"
                    data={paymentMethod.data}
                    onRemoveClick={() => setDeleteModalData(paymentMethod)}
                  />
                ))}
                <WhiteSpace v="2rem" />

                <Flex justify="center">
                  <Button
                    loading={isPaying}
                    disabled={!selectedPM || !stripe || isAddPMLoading}
                    onClick={() => payWith(selectedPM?.id)}
                    icon={<Icon type="card" />}
                  >
                    Pay now
                  </Button>
                </Flex>

                <WhiteSpace v="3rem" />
                <Flex align="center">
                  <hr className="flex-1" />
                  <WhiteSpace h="1rem" />
                  <h2>OR</h2>
                  <WhiteSpace h="1rem" />
                  <hr className="flex-1" />
                </Flex>
                <WhiteSpace v="3rem" />
              </>
            )}
            emptyText={<></>}
          />

          <h2>Add and pay with a new payment method</h2>
          <WhiteSpace v="1rem" />
          <AddPaymentMethodForm
            disabled={isPaying}
            onLoadingChange={setIsAddPMLoading}
            onSuccess={addPMSuccessHandler}
          />
          <WhiteSpace v="1rem" />
        </Modal.Content>
      </Modal>
      <MutationConfirmModal
        open={!!deleteModalData}
        mutation={deleteMutation}
        onClose={() => setDeleteModalData(undefined)}
      >
        Are you sure to remove card with number <b>****** {deleteModalData?.data?.last4}</b> ?
      </MutationConfirmModal>
    </>
  );
};
