import React from "react";

export const Edit: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 11.6L13.3333 23.4667L6 24.9334L7.73333 17.8667L19.6 6.00005C20.5333 5.06672 22 5.06672 22.9333 6.00005L25.3333 8.26672C26.2667 9.20005 26.2667 10.6667 25.3333 11.6Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5333 6.93335L24.2667 12.6667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
