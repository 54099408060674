type ErrorMessage = { title?: string; content?: string };

function extract(errors: any, prefix: string = "") {
  const messages: ErrorMessage[] = [];
  if (errors instanceof Array) {
    errors.forEach((e) => {
      if (e.message)
        messages.push({ title: prefix + e.property, content: e.message });
      if (e.children instanceof Array)
        messages.push(
          ...extract(
            e?.children,
            prefix + (e?.property ? e?.property + " " : ""),
          ),
        );
    });
  }
  return messages;
}

export function extractErrorMessages(error: any) {
  const messages: ErrorMessage[] = [];

  let data;
  if ((data = error?.response?.data)) {
    if (data.error) {
      if (data.error.errors instanceof Array) {
        messages.push(...extract(data.error.errors));
      }
    }
  }

  return messages;
}
