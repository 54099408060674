import React from "react";

export const Nav: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM14.1655 7.35484C14.5093 7.12782 14.9648 7.39077 14.9401 7.80205L14.6131 13.2504C14.6036 13.4075 14.5208 13.551 14.3894 13.6377L9.83451 16.6452C9.49067 16.8722 9.03523 16.6092 9.05992 16.1979L9.38695 10.7496C9.39637 10.5925 9.47922 10.449 9.61055 10.3623L14.1655 7.35484Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};
