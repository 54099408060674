import React from "react";
import { UseQueryResult } from "react-query";

import { QueryContent } from "./QueryContent";

export interface QueryListProps<RecordType, Data> {
  query: UseQueryResult<Data>;
  renderItems: (data: RecordType[]) => React.ReactNode;
  getItems: (data: Data | undefined) => RecordType[] | undefined;
  emptyText?: React.ReactNode;
}

// TODO: add pagination

export const QueryList = <RecordType, Data>({
  query,
  renderItems,
  getItems,
  emptyText = <h3 className="text-center py-3">No data available</h3>,
}: QueryListProps<RecordType, Data>) => {
  const items = getItems(query.data);
  return (
    <>
      <QueryContent query={query}>{items?.length ? renderItems(items) : emptyText}</QueryContent>
    </>
  );
};
