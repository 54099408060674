import React from "react";

export const Prsnts2: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M6.66663 15.5999H25.3333V25.4665C25.3333 26.9332 24.1333 28.1332 22.6666 28.1332H9.33329C7.86663 28.1332 6.66663 26.9332 6.66663 25.4665V15.5999Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M26.6667 15.5999H5.33333C4.53333 15.5999 4 14.9333 4 14.2666V12.6666C4 11.8666 4.66667 11.3333 5.33333 11.3333H26.6667C27.4667 11.3333 28 11.9999 28 12.6666V14.2666C28 14.9333 27.3333 15.5999 26.6667 15.5999Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8 11.3333H17.7333C16.5333 11.3333 15.7333 9.99994 16.2666 8.93328L17.6 6.13327C19.2 2.6666 24.5333 3.86661 24.5333 7.73327C24.4 9.73327 22.8 11.3333 20.8 11.3333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.2 11.3333H14.2667C15.4667 11.3333 16.2667 9.99994 15.7333 8.93328L14.4 6.13327C12.8 2.6666 7.46667 3.86661 7.46667 7.73327C7.60001 9.73327 9.20001 11.3333 11.2 11.3333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7333 11.3333H14.1333V28.1333H17.7333V11.3333Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
