import { Flex, Text, WhiteSpace } from "components";

export const NotFound = () => {
  return (
    <Flex direction="column" align="center" justify="center" style={{ height: "100%" }}>
      <WhiteSpace v="2rem" />
      <Text size="clamp(90px, 20vw, 200px)" fw={700} style={{ lineHeight: "1" }}>
        404
      </Text>
      <Text align="center" size="clamp(1.5rem, 5vw, 3.5rem)" fw={600}>
        Page not found
      </Text>
      <WhiteSpace v="3rem" />
      <Text align="center" size="1.5rem" style={{ maxWidth: "50vw" }}>
        We are very sorry for inconvenience. It looks like you're trying to access a page that has been deleted or never
        even existed.
      </Text>
      <WhiteSpace v="9rem" />
    </Flex>
  );
};
