import { useRouteMatch } from "react-router-dom";

import { Icon, Text, Topbar, Button, LinkButton, Avatar } from "components";
import { getFullNameOf, makeBEM } from "utils";

import logoSrc from "features/common/assets/logo_text.png";
import { useAuth } from "features/auth/hooks";

const bem = makeBEM("app-layout-topbar");
export interface AppLayoutTopbarProps {
  onSidebarToggle?: () => void;
}

export const AppLayoutTopbar = ({ onSidebarToggle }: AppLayoutTopbarProps) => {
  const { user } = useAuth();
  const settingsMatch = useRouteMatch({ path: "/settings" });

  return (
    <Topbar>
      <div className={bem("content")}>
        <div className={bem("sidebar-toggle")}>
          <Button onClick={onSidebarToggle} icon={<Icon type="menu" size="1.75rem" />} auto color="secondary" light />
        </div>
        <div></div>
        {/* <div className={bem("info")}>Sustainable - Personalised - Instant gifting</div> */}
        <div className={bem("logo")}>
          <img src={logoSrc} alt="Logo" />
        </div>
        <div className={bem("account")}>
          <h4 className={bem("greeting")}>
            <Text el="span" color="gray">
              Hi,{" "}
            </Text>
            {user?.first_name}
          </h4>

          <Avatar name={getFullNameOf(user || {})} src={user?.photo?.url} />

          <LinkButton
            disabled={!!settingsMatch}
            to="/settings"
            auto
            color="secondary"
            light
            icon={<Icon type="settings" size="2rem" />}
          />
        </div>
      </div>
    </Topbar>
  );
};
