import React from "react";

export const Skip: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M4 19C4 13.1523 8.77563 8.41176 14.6667 8.41176H20M20 8.41176L15.5556 4M20 8.41176L15.5556 12.8235"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="18" fill={fill} r="2" />
    </svg>
  );
};
