export interface Group {
  id?: number;
  name?: string;
  description?: string;
  group_type?: GroupType;
}

export enum GroupType {
  company = "company",
  team = "team",
}