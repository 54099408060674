import ReactPaginate from "react-paginate";

import { Flex, Icon } from "components";
import { UsePaginationResult } from "hooks";
import models from "models";
import defaults from "app-defaults";

import { ButtonGroup } from "./ButtonGroup";
import { Button } from "./Button";

export interface PaginationProps {
  pagination: UsePaginationResult;
  data?: models.WithResults<unknown>;
  perPageChoice?: number[];
}

export const Pagination = ({
  pagination,
  data,
  perPageChoice = defaults.pagination.perPageChoice,
}: PaginationProps) => {
  return (
    <>
      {data && (
        <Flex className="pagination__container" align="flex-start" justify="center" wrap gap="1rem 2rem">
          <ButtonGroup>
            {perPageChoice.map((choice) => (
              <Button
                key={choice}
                size={"sm"}
                auto
                color="secondary"
                bordered={choice !== pagination.perPage}
                onClick={() => pagination.setPerPage(choice)}
              >
                {choice}
              </Button>
            ))}
          </ButtonGroup>

          <ReactPaginate
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={data.total_pages || 0}
            previousLabel={<Icon type="left" />}
            nextLabel={<Icon type="left" style={{ transform: "rotate(180deg)" }} />}
            containerClassName="pagination"
            nextClassName="pagination__move"
            previousClassName="pagination__move"
            breakClassName="pagination__break"
            pageLinkClassName="pagination__page"
            forcePage={pagination.page - 1}
            onPageChange={({ selected }) => pagination.setPage(selected + 1)}
          />
        </Flex>
      )}
    </>
  );
};
