import { Text } from "components";
import models from "models";
import { TableColumnBuilder, withCurrency } from "utils";

interface OrdersTableColumnsArgs {
  small?: boolean;
}

export const ordersTableColumns = ({ small }: OrdersTableColumnsArgs) =>
  new TableColumnBuilder<models.Order>()
    .add("Date", "date", undefined, { width: "140px" })
    .add("Gifts sent", "", (_, order) => (
      <div>
        {order.products?.map((product) => (
          <div className="nowrap my-2" key={product.id}>
            {product.title}
          </div>
        ))}
      </div>
    ))
    .add("Recipients", "", (_, order) => (
      <div>
        {order.clients_count ? <>{order.clients_count} Client(s)</> : null}
        {order.employees_count ? <>{order.employees_count} Employee(s)</> : null}
        <br />
        <Text
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: small ? 120 : 200,
          }}
          color="gray"
        >
          {order.groups}
        </Text>
      </div>
    ))
    // .add("VAT", "vat", withCurrency)
    .add("Total fee", "total_fee", withCurrency, { width: "100px" })
    .add("Total cost", "total_cost", withCurrency, { width: "100px" })
    .getColumns();

/**
 * @deprecated
 */
export const monthlyInvoiceLimitChoice = [
  ...new Array(5).fill(null).map((_, i) => ({
    value: (i + 1) * 20,
    text: (i + 1) * 20 + "",
  })),
  // {
  //   value: null,
  //   text: "All",
  // },
];
