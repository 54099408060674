import { useMutation, useQueryClient } from "react-query";

import { useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { usePageTemplate } from "features/users/hooks";
import { capitalized, notifyErrors } from "utils";
import models from "models";

import { GroupModal } from ".";

export interface AddGroupModalProps extends ModalProps {
  onSuccess?: React.Dispatch<models.Group>;
  data?: models.Group;
}

export const AddGroupModal = ({ onSuccess, data, ...props }: AddGroupModalProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const template = usePageTemplate();

  const postMutation = useMutation(template.usersApi.groups.post, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(template.querykeys.groups.many());
      notify.success({
        title: "",
        description: capitalized(template.groupName.singular) + " successfully created",
      });
      onSuccess?.(data);
      props?.onClose?.();
    },
    onError: (error) => notifyErrors(notify, error),
  });

  return (
    <GroupModal
      data={data}
      mutation={postMutation}
      title={"Add " + template.groupName.singular}
      submitBtnText="Add"
      {...props}
    />
  );
};
