import { TabsBase, Flex, WhiteSpace, Text, Icon, IconType } from "components";
import { makeBEM } from "utils";

export interface VerticalTabsPanelProps {
  tabKey: any;
  title: string;
  subtitle: string;
  icon: IconType;
}

const c = makeBEM("vertical-tabs");

export const VerticalTabsPanel = ({
  title,
  subtitle,
  icon,
  tabKey,
}: VerticalTabsPanelProps) => {
  return (
    <TabsBase.Panel className={c("panel")} tabKey={tabKey}>
      <Flex align="center">
        <Text color="gray">
          <Icon type={icon} />
        </Text>
        <WhiteSpace h="1rem" />
        <div>
          <h3>{title}</h3>
          <Text color="gray">{subtitle}</Text>
        </div>
      </Flex>
    </TabsBase.Panel>
  );
};
