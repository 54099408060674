import { Cart } from "./svgs/Cart";
import { Check } from "./svgs/Check";
import { CreditCard } from "./svgs/CreditCard";
import { Energy } from "./svgs/Energy";
import { Flag } from "./svgs/Flag";
import { Library } from "./svgs/Library";
import { Like } from "./svgs/Like";
import { Nav } from "./svgs/Nav";
import { Person } from "./svgs/Person";
import { Trash } from "./svgs/Trash";
import { UrgentMail } from "./svgs/UrgentMail";
import { Write } from "./svgs/Write";
import { AccountInfo } from "./svgs/AccountInfo";
import { Alert } from "./svgs/Alert";
import { Back } from "./svgs/Back";
import { Birthday } from "./svgs/Birthday";
import { Birthday2 } from "./svgs/Birthday2";
import { Block } from "./svgs/Block";
import { CalendarStandard } from "./svgs/CalendarStandard";
import { CalendarValid } from "./svgs/CalendarValid";
import { Calendar } from "./svgs/Calendar";
import { Camera } from "./svgs/Camera";
import { Card } from "./svgs/Card";
import { Clock } from "./svgs/Clock";
import { Delete } from "./svgs/Delete";
import { Delivery } from "./svgs/Delivery";
import { Droparrow } from "./svgs/Droparrow";
import { Edit } from "./svgs/Edit";
import { Ellipse } from "./svgs/Ellipse";
import { Filters } from "./svgs/Filters";
import { Friends } from "./svgs/Friends";
import { Heart } from "./svgs/Heart";
import { Help } from "./svgs/Help";
import { Home } from "./svgs/Home";
import { Home2 } from "./svgs/Home2";
import { Idea } from "./svgs/Idea";
import { Info } from "./svgs/Info";
import { InviteFriends } from "./svgs/InviteFriends";
import { Left } from "./svgs/Left";
import { Lock } from "./svgs/Lock";
import { Logout } from "./svgs/Logout";
import { Message } from "./svgs/Message";
import { Moreactions } from "./svgs/Moreactions";
import { MyPrsnts2 } from "./svgs/MyPrsnts2";
import { Notifications } from "./svgs/Notifications";
import { Plus } from "./svgs/Plus";
import { Prsnts2 } from "./svgs/Prsnts2";
import { Record } from "./svgs/Record";
import { Search } from "./svgs/Search";
import { Settings } from "./svgs/Settings";
import { Share } from "./svgs/Share";
import { Skip } from "./svgs/Skip";
import { StatusRedeemed } from "./svgs/StatusRedeemed";
import { StatusSent } from "./svgs/StatusSent";
import { StatusUnwrapped } from "./svgs/StatusUnwrapped";
import { Store } from "./svgs/Store";
import { Swipe } from "./svgs/Swipe";
import { Text } from "./svgs/Text";
import { User } from "./svgs/User";
import { Verify } from "./svgs/Verify";
import { Cross } from "./svgs/Cross";
import { Client } from "./svgs/Client";
import { Dashboard } from "./svgs/Dashboard";
import { Worker } from "./svgs/Worker";
import { Privacy } from "./svgs/Privacy";
import { List } from "./svgs/List";
import { PhoneBook } from "./svgs/PhoneBook";
import { Menu } from "./svgs/Menu";
import { Account } from "./svgs/Account";
import { Catalogue } from "./svgs/Catalogue";
import { Employee } from "./svgs/Employee";
import { SendArrow } from "./svgs/SendArrow";

export const iconList = {
  cart: Cart,
  check: Check,
  "credit-card": CreditCard,
  energy: Energy,
  flag: Flag,
  library: Library,
  like: Like,
  nav: Nav,
  person: Person,
  trash: Trash,
  "urgent-mail": UrgentMail,
  write: Write,
  "account-info": AccountInfo,
  alert: Alert,
  back: Back,
  birthday: Birthday,
  birthday2: Birthday2,
  block: Block,
  "calendar-standard": CalendarStandard,
  "calendar-valid": CalendarValid,
  calendar: Calendar,
  camera: Camera,
  card: Card,
  clock: Clock,
  delete: Delete,
  delivery: Delivery,
  droparrow: Droparrow,
  edit: Edit,
  ellipse: Ellipse,
  filters: Filters,
  friends: Friends,
  heart: Heart,
  help: Help,
  home: Home,
  home2: Home2,
  idea: Idea,
  info: Info,
  "invite-friends": InviteFriends,
  left: Left,
  lock: Lock,
  logout: Logout,
  message: Message,
  moreactions: Moreactions,
  "my-prsnts2": MyPrsnts2,
  notifications: Notifications,
  plus: Plus,
  prsnts2: Prsnts2,
  record: Record,
  search: Search,
  settings: Settings,
  share: Share,
  skip: Skip,
  "status-redeemed": StatusRedeemed,
  "status-sent": StatusSent,
  "status-unwrapped": StatusUnwrapped,
  store: Store,
  swipe: Swipe,
  text: Text,
  user: User,
  verify: Verify,
  cross: Cross,
  client: Client,
  dashboard: Dashboard,
  worker: Worker,
  privacy: Privacy,
  list: List,
  "phone-book": PhoneBook,
  menu: Menu,
  account: Account,
  catalogue: Catalogue,
  employee: Employee,
  "send-arrow": SendArrow,
};
