import models from "models";
import { createGlobalState } from "react-use";

export interface SendGiftInitialState {
  contactType?: models.UserContactType;
  users?: models.UserContact[];
  selectedProducts?: models.ProductSendConfig[];
}

export const useSendGiftInitialState = createGlobalState<SendGiftInitialState | null>();
