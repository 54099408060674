import React, { CSSProperties } from "react";

export interface GridProps extends Omit<React.HTMLProps<HTMLDivElement>, "cols" | "rows"> {
  cols?: CSSProperties["gridTemplateColumns"];
  rows?: CSSProperties["gridTemplateRows"];
  content?: CSSProperties["placeContent"];
  items?: CSSProperties["placeItems"];
  gap?: CSSProperties["gap"];
}

export const Grid: React.FC<GridProps> = ({ cols, rows, gap, content, items, style: propsStyle, ...props }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: cols,
        gridTemplateRows: rows,
        placeContent: content,
        placeItems: items,
        gap,
        ...propsStyle,
      }}
      {...props}
    ></div>
  );
};
