export interface WhiteSpaceProps {
  inline?: boolean;
  /**
   * Vertical spacing
   */
  v?: number | string;
  /**
   * Horizontal spacing
   */
  h?: number | string;
}

export const WhiteSpace = ({ inline, v, h }: WhiteSpaceProps) => {
  return (
    <div
      style={{
        display: inline ? "inline-block" : undefined,
        paddingTop: v,
        paddingLeft: h,
      }}
    ></div>
  );
};
