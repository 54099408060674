import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { NotifyProvider, NotifyContainer } from "ebs-design";

import { AppRouter } from "features/common/components";
import { AuthProvider } from "features/auth/contexts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AuthProvider>
            <NotifyProvider>
              <NotifyContainer />
              <AppRouter />
            </NotifyProvider>
          </AuthProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
