import React from "react";

export const Clock: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="16" cy="15.9999" r="10.1667" stroke={stroke} />
      <circle cx="16" cy="15.9999" fill={fill} r="2.66667" />
      <path
        d="M12 20L16 16"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 16H24" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};
