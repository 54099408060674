import React from "react";
import { useLocalStorage } from "react-use";
import { Link, matchPath, useLocation, useRouteMatch } from "react-router-dom";

import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";

import api from "api";

import { Flex, Icon, Layout, LayoutContent, Sidebar, SidebarItem, WhiteSpace, Button } from "components";
import { useScreenBreakPoint } from "hooks";

import { AppLayoutTopbar } from ".";

export const AppLayout: React.FC = ({ children }) => {
  const [stripePromise] = React.useState(() => api.stripe.load());
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorage("sidebar-collapsed", true);

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed);

  const sendGiftMatch = useRouteMatch({ path: "/send-gift" });

  const location = useLocation();
  const isActive = (url: string | string[], exact = false): boolean =>
    !!matchPath(location.pathname, { path: url, exact });

  React.useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  const onLaptop = useScreenBreakPoint("laptop");

  return (
    <>
      <AppLayoutTopbar onSidebarToggle={() => setSidebarOpen(true)} />
      <Layout>
        <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} collapsed={sidebarCollapsed}>
          <Flex style={{ height: "100%" }} direction="column" justify="space-between" align="stretch">
            <div>
              {!onLaptop ? (
                <SidebarItem
                  title="Hide labels"
                  secondary
                  icon={<Icon type="menu" size="1.5rem" />}
                  onClick={() => toggleSidebarCollapsed()}
                >
                  Hide labels
                </SidebarItem>
              ) : (
                <>
                  <SidebarItem
                    secondary
                    icon={<Icon type="cross" size="1.5rem" />}
                    onClick={() => setSidebarOpen(false)}
                  >
                    Close menu
                  </SidebarItem>
                </>
              )}

              <WhiteSpace v="1rem" />
              <hr />

              {sendGiftMatch ? (
                <SidebarItem
                  secondary
                  title="Back to catalogue"
                  icon={
                    <Button auto color="secondary" rounded style={{ padding: "0.35em" }}>
                      <Icon size="1.25rem" type="back" />
                    </Button>
                  }
                >
                  <Link to="/">Back to catalogue</Link>
                </SidebarItem>
              ) : (
                <SidebarItem
                  title="Send gift"
                  icon={
                    <Button auto rounded style={{ padding: "0.35em" }}>
                      <Icon size="1.5rem" type="send-arrow" />
                    </Button>
                  }
                >
                  <Link to="/send-gift">Send gift</Link>
                </SidebarItem>
              )}

              {!sendGiftMatch && (
                <SidebarItem
                  title="Catalogue"
                  icon={<Icon type="catalogue" size="1.5rem" />}
                  active={isActive("/", true)}
                >
                  <Link to="/">Catalogue</Link>
                </SidebarItem>
              )}

              <SidebarItem title="Employees" icon={<Icon type="employee" />} active={isActive("/employees")}>
                <Link to="/employees">Employees</Link>
              </SidebarItem>

              <SidebarItem title="Clients and customers" icon={<Icon type="client" />} active={isActive("/clients")}>
                <Link to="/clients">Clients and customers</Link>
              </SidebarItem>

              <SidebarItem title="Settings" icon={<Icon type="settings" size="2rem" />} active={isActive("/settings")}>
                <Link to="/settings">Settings</Link>
              </SidebarItem>

              <SidebarItem title="Account" icon={<Icon type="account" size="1.65rem" />} active={isActive("/account")}>
                <Link to="/account">Account</Link>
              </SidebarItem>

              <SidebarItem
                title="Automatic Rewards"
                icon={<Icon type="status-sent" />}
                active={isActive("/auto-gifts")}
              >
                <Link to="/auto-gifts">Automatic Rewards</Link>
              </SidebarItem>
              <hr />
            </div>

            <div>
              <hr />
              <SidebarItem
                title="Privacy Policy"
                icon={<Icon type="privacy" size="1.5rem" />}
                active={isActive("/privacy-policy")}
              >
                <Link to="/privacy-policy">Privacy Policy</Link>
              </SidebarItem>

              <SidebarItem
                title="Terms & conditions"
                icon={<Icon type="list" size="1.5rem" />}
                active={isActive("/terms-conditions")}
              >
                <Link to="/terms-conditions">Terms {"&"} conditions</Link>
              </SidebarItem>

              <SidebarItem
                title="Contact"
                icon={<Icon type="phone-book" size="1.5rem" />}
                active={isActive("/contact")}
              >
                <Link to="/contact">Contact</Link>
              </SidebarItem>
              <hr />
            </div>
          </Flex>
        </Sidebar>
        <LayoutContent>
          <StripeElementsProvider stripe={stripePromise}>
            {/* {React.useMemo(() => children, [children])} */}
            {children}
          </StripeElementsProvider>
        </LayoutContent>
      </Layout>
    </>
  );
};
