import React from "react";

export const MyPrsnts2: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 10.2666V25.4666C25.3333 26.9333 24.2666 28 22.9333 28H9.06663C7.73329 28 6.66663 26.8 6.66663 25.4666V10.1333H25.3333V10.2666Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 9.46655V28.1332"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5333 17.5999H2.2666L5.0666 11.0665C5.4666 9.99988 6.39993 9.46655 7.33327 9.46655H15.9999L12.5333 17.5999Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.4667 17.5999H29.6L26.8 11.0665C26.4 9.99988 25.4667 9.46655 24.5333 9.46655H16L19.4667 17.5999Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16.1333 6.5333V4.1333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.4666 6.53339L21.0666 4.80005"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12.8 6.53339L11.0667 4.80005"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
