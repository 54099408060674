import React from "react";

export const Worker: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            style={{ fill: fill }}
            d="M3.73,7.53a.75.75,0,0,0,.75.75h.41a.24.24,0,0,1,.25.22c0,.12,0,.24,0,.37a.29.29,0,0,1,0,.18,2.13,2.13,0,0,0-.4,1.36,2.7,2.7,0,0,0,1.44,2.44.24.24,0,0,1,.14.19c.51,3,4,5,5.69,5s5.17-2,5.68-5a.25.25,0,0,1,.15-.18,2.74,2.74,0,0,0,1.44-2.43,2.21,2.21,0,0,0-.4-1.37.24.24,0,0,1-.05-.18l0-.37a.25.25,0,0,1,.25-.22h.4a.75.75,0,0,0,0-1.5h-.34a.25.25,0,0,1-.25-.24,6.16,6.16,0,0,0-1.33-3.69A5.08,5.08,0,0,0,15.8,1.52a.22.22,0,0,0-.21,0,.26.26,0,0,0-.13.16L14.2,6.23a.76.76,0,0,1-.72.55.64.64,0,0,1-.2,0,.75.75,0,0,1-.53-.92L14.15.76A.25.25,0,0,0,14,.46,5.58,5.58,0,0,0,12,0a5.59,5.59,0,0,0-2,.43.25.25,0,0,0-.14.3L11.2,5.83a.75.75,0,0,1-.53.92.63.63,0,0,1-.19,0,.75.75,0,0,1-.73-.56L8.53,1.68a.25.25,0,0,0-.14-.16.26.26,0,0,0-.21,0A5.13,5.13,0,0,0,6.41,2.85,6.23,6.23,0,0,0,5.08,6.54a.24.24,0,0,1-.24.24H4.48A.74.74,0,0,0,3.73,7.53ZM17.26,9.15a.75.75,0,0,0,.4.8.66.66,0,0,1,.11.44,1.29,1.29,0,0,1-.56,1.09,1.77,1.77,0,0,0-1,1.31C15.83,15,13,16.53,12,16.53S8.17,15,7.79,12.79a1.74,1.74,0,0,0-1-1.31,1.26,1.26,0,0,1-.55-1.09c0-.3.08-.43.1-.44a.76.76,0,0,0,.41-.8c0-.2-.07-.4-.09-.59a.24.24,0,0,1,.06-.2.27.27,0,0,1,.19-.08H17.09a.26.26,0,0,1,.19.08.24.24,0,0,1,.06.2C17.32,8.75,17.29,9,17.26,9.15Z"
          />
          <path
            style={{ fill: fill }}
            d="M21.12,18.82l-3.89-1.3a.74.74,0,0,0-.95.47.17.17,0,0,0,0,.07.29.29,0,0,1-.11.18l-3.33,2.33a.25.25,0,0,0-.1.2v2.76a.25.25,0,0,0,.25.25h6.5a.51.51,0,0,0,.5-.5V20.37a.24.24,0,0,1,.1-.21.27.27,0,0,1,.23,0l.3.1h0a3.07,3.07,0,0,1,1.86,3.05.75.75,0,0,0,1.5,0A4.56,4.56,0,0,0,21.12,18.82Z"
          />
          <path
            style={{ fill: fill }}
            d="M11.23,20.77a.23.23,0,0,0-.11-.2L7.79,18.24a.24.24,0,0,1-.1-.18s0,0,0-.07a.74.74,0,0,0-1-.47L2.81,18.83A4.54,4.54,0,0,0,0,23.28a.75.75,0,0,0,1.5,0,3,3,0,0,1,1.83-3h0l.34-.11a.25.25,0,0,1,.22,0,.26.26,0,0,1,.11.21v2.91a.5.5,0,0,0,.5.5H11a.26.26,0,0,0,.25-.25Z"
          />
          <path
            style={{ fill: fill }}
            d="M12.77,12.85l-.22.1A2,2,0,0,1,12,13h0a2,2,0,0,1-.55-.08,1.12,1.12,0,0,1-.22-.1.75.75,0,1,0-.73,1.31,2.52,2.52,0,0,0,.5.22,3.61,3.61,0,0,0,1,.15h0a3.61,3.61,0,0,0,1-.15,2.52,2.52,0,0,0,.5-.22.75.75,0,0,0-.73-1.31Z"
          />
          <path
            style={{ fill: fill }}
            d="M11.1,10.56a.75.75,0,0,0,0-1.06,2,2,0,0,0-2.6,0,.74.74,0,0,0,0,1.06.75.75,0,0,0,1.06,0,.54.54,0,0,1,.55,0,.73.73,0,0,0,.51.2A.72.72,0,0,0,11.1,10.56Z"
          />
          <path
            style={{ fill: fill }}
            d="M12.89,9.5a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0,.56.56,0,0,1,.56,0,.7.7,0,0,0,.51.2.75.75,0,0,0,.51-1.3A2,2,0,0,0,12.89,9.5Z"
          />
        </g>
      </g>
    </svg>
  );
};
