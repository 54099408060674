import React, { CSSProperties } from "react";

import { ComponentSize } from "./types";

export interface ContainerProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "size" | "min"> {
  size?: ComponentSize | number;
  fixed?: boolean;
  mauto?: boolean;
  min?: number;
}

const containerSizeMap: Record<ComponentSize, number> = {
  sm: 500,
  md: 800,
  lg: 1200,
};

export const Container: React.FC<ContainerProps> = ({
  size,
  fixed = false,
  mauto = false,
  min,
  children,
  style: propsStyles,
  ...props
}) => {
  size = typeof size === "string" ? containerSizeMap[size] : size;
  const styles: CSSProperties = {
    [fixed ? "width" : "maxWidth"]: size,
    margin: mauto ? "0 auto" : "",
    minWidth: min,
  };
  Object.assign(styles, propsStyles);

  return (
    <div style={styles} {...props}>
      {children}
    </div>
  );
};
