import { Checkbox } from "..";

export interface CheckboxHeaderProps {
  areAllSelected: boolean;
  hasSomeSelected: boolean;
  selectAll: () => void;
  removeAll: () => void;
}

export const SelectCheckboxHeader = ({
  areAllSelected,
  hasSomeSelected,
  selectAll,
  removeAll,
}: CheckboxHeaderProps) => (
  <Checkbox
    indeterminate={hasSomeSelected}
    checked={areAllSelected}
    onChange={() => {
      areAllSelected ? removeAll() : selectAll();
    }}
  />
);

export const renderSelectCheckbox = (has: (v: any) => boolean) => (val: any, record: any) =>
  <Checkbox checked={has(val ?? record)} />;

export const renderCheckbox = (value: any) => {
  return (
    <span style={{ pointerEvents: "none" }}>
      <Checkbox checked={!!value} />
    </span>
  );
};
