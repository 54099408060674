import { AxiosResponse } from "axios";
import models from "models";
import { GenericObject } from "types";

import { apiAxios } from "./axios";
import { stringifyUrl } from "./utils";

export const orders = {
  setupPaymentIntent: async (payment_method: string) => {
    const { data } = await apiAxios.post<any, AxiosResponse<models.PaymentIntentSetupResponse>>(
      "/orders/setup-payment-intent/",
      { payment_method }
    );
    return data;
  },
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/orders/`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Order>>(url);
    return data;
  },
  getList: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/companies/orders/list/`, query);
    const { data } = await apiAxios.get<models.Order[]>(url);
    return { results: data };
  },
  stats: async () => {
    const { data } = await apiAxios.get<models.OrdersStats>(`/companies/orders/stats/`);
    return data;
  },
  getInvoice: async (id: string) => {
    const { data } = await apiAxios.get<models.OrderInvoice>(`/companies/orders/${id}/invoice/`);
    return data;
  },
};
