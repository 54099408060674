import React from "react";
import { makeBEM } from "utils";

export interface PageContainerProps {
  color?: PageContainerColor;
  padded?: boolean;
}

export type PageContainerColor = "white" | "grey";

const bem = makeBEM("page-container");

export const PageContainer: React.FC<PageContainerProps> = ({ color = "grey", padded = true, children }) => {
  return (
    <div className={bem(null, { padded }, [color])}>
      <div className={bem("width-limit")}>{children}</div>
    </div>
  );
};
