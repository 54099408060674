import React from "react";

export const Heart: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M17.7321 24.6047C18.6059 23.6635 19.7607 22.6616 20.9778 21.8115C21.4877 21.4604 22.048 21.1205 22.6229 20.7719C25.1799 19.2209 28.0253 17.4951 27.9998 13.8264C27.9686 6.29675 20.4161 4.5965 16.8582 9.60615M17.7321 24.6047L17.3721 24.2578C17.3711 24.2588 17.3701 24.2599 17.3691 24.2609M17.7321 24.6047C16.7958 25.5763 15.2354 25.5763 14.3303 24.6047M17.7321 24.6047L17.3657 24.2645C17.3668 24.2633 17.3679 24.2621 17.3691 24.2609M17.3691 24.2609C18.2686 23.2926 19.4493 22.2692 20.6915 21.4016L20.6942 21.3997C21.217 21.0397 21.7959 20.6886 22.3748 20.3375C22.5804 20.2128 22.7859 20.0881 22.989 19.963C23.7754 19.4786 24.5429 18.9775 25.2191 18.4051C26.5584 17.2713 27.5141 15.8827 27.4998 13.8299L27.4998 13.8285C27.485 10.2459 25.6987 8.1552 23.6006 7.4907C21.484 6.82039 18.9276 7.5565 17.2665 9.89488M17.3691 24.2609C16.6259 25.0286 15.3998 25.0187 14.6966 24.2644M17.2665 9.89488C17.2667 9.89452 17.267 9.89415 17.2672 9.89379L16.8582 9.60615M17.2665 9.89488C16.6613 10.7538 15.3686 10.7534 14.764 9.89379L14.7613 9.88991L14.7613 9.8899C13.0673 7.43212 10.5162 6.71854 8.41196 7.42717C6.31451 8.13349 4.53002 10.3019 4.50016 13.8909C4.48598 15.9466 5.43695 17.3582 6.77153 18.5019C7.44486 19.079 8.21058 19.5824 8.99585 20.061C9.27632 20.2319 9.56367 20.4021 9.84989 20.5717C10.3489 20.8674 10.8445 21.161 11.2942 21.4527C12.6354 22.3225 13.8227 23.3199 14.6966 24.2644M17.2665 9.89488L14.3303 24.6047M16.8582 9.60615C16.4525 10.183 15.5787 10.183 15.173 9.60615H16.8582ZM14.3303 24.6047L14.6962 24.2639C14.6963 24.2641 14.6965 24.2642 14.6966 24.2644M14.3303 24.6047L14.6974 24.2652C14.6971 24.2649 14.6969 24.2647 14.6966 24.2644"
        stroke={stroke}
      />
    </svg>
  );
};
