import React from "react";

export const Record: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M19.8667 25.3334H6.53337C5.0667 25.3334 3.8667 24.1334 3.8667 22.6667V9.33341C3.8667 7.86675 5.0667 6.66675 6.53337 6.66675H19.8667C21.3334 6.66675 22.5334 7.86675 22.5334 9.33341V22.6667C22.5334 24.1334 21.3334 25.3334 19.8667 25.3334Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1335 20.8002L25.6001 18.9335C24.8001 18.4002 24.4001 17.4669 24.4001 16.6669V15.3335C24.4001 14.4002 24.8001 13.6002 25.6001 13.0669L28.1335 11.2002V20.8002Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M9.60002 14.9334C11.0728 14.9334 12.2667 13.7395 12.2667 12.2668C12.2667 10.794 11.0728 9.6001 9.60002 9.6001C8.12726 9.6001 6.93335 10.794 6.93335 12.2668C6.93335 13.7395 8.12726 14.9334 9.60002 14.9334Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
