import React from "react";
import { isOnMobile, makeBEM } from "utils";

import { WhiteSpace } from "./WhiteSpace";

export interface ProductListProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const bem = makeBEM("product-list");

export const ProductList: React.FC<ProductListProps> = ({ title, children, ...props }) => {
  const [onMobile] = React.useState(() => isOnMobile());
  return (
    <div className={bem(null, { "on-mobile": onMobile })} {...props}>
      <h3 className={bem("title")}>{title}</h3>
      <WhiteSpace v="0.5rem" />
      <div className={bem("list")}>{children}</div>
    </div>
  );
};
