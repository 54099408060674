import React from "react";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";

import { PageContainer, QueryTable, WhiteSpace } from "components";
import api, { querykeys } from "api";
import { useModalState, useQueryPagination, useSearch } from "hooks";
import models from "models";
import { atoms } from "atoms";

import { ordersTableColumns } from "features/dashboard/config";
import {
  DashboardStats,
  InfoCards,
  InvoiceModal,
  OrdersCardHeader,
  MonthlyInvoiceModal,
} from "features/dashboard/components";

export const Dashboard = () => {
  const detailsModal = useModalState<models.Order>(atoms.details);
  const monthlyModal = useModalState(atoms.monthlyInovice);

  const [search, setSearch] = useSearch();
  const pagination = useQueryPagination();

  const queryParams = {
    page: pagination.page,
    per_page: pagination.perPage,
    search,
  };

  useUpdateEffect(() => pagination.setPage(1), [search]);

  const query = useQuery(querykeys.orders.many(queryParams), () => api.orders.get(queryParams));

  const tableColumns = React.useMemo(() => ordersTableColumns({}), []);

  return (
    <PageContainer>
      <DashboardStats />
      <WhiteSpace v="2rem" />
      <OrdersCardHeader
        onMonthlyInvoiceClick={monthlyModal.open}
        count={query.data?.count}
        {...{ search, setSearch }}
      />
      <WhiteSpace v="1rem" />
      <QueryTable
        pagination={pagination}
        query={query}
        columns={tableColumns}
        variants={["highlight-hover"]}
        rowKey="operation_id"
        onRow={(record) => ({ onClick: () => detailsModal.openWith(record) })}
      />
      <WhiteSpace v="2rem" />
      <InfoCards />
      <MonthlyInvoiceModal
        open={monthlyModal.isOpen}
        onClose={monthlyModal.close}
        onItemRowClick={detailsModal.openWith}
      />
      <InvoiceModal
        open={detailsModal.isOpen}
        operationId={detailsModal.data?.operation_id}
        onClose={detailsModal.reset}
      />
    </PageContainer>
  );
};
