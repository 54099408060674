import React from "react";
import { useStateRef } from "./useStateRef";

export function useScrollTopPosition(offset: number) {
  const [state, setState] = React.useState(document.documentElement.scrollTop <= offset);
  const stateRef = useStateRef(state);

  React.useLayoutEffect(() => {
    const listener = () => {
      const newState = document.documentElement.scrollTop <= offset;
      newState !== stateRef.current && setState(newState);
    };
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return state;
}
