import { RefObject } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import { Form, useForm, useNotify } from "ebs-design";

import { InputFormField, Button, Text, WhiteSpace, WithInfoIcon } from "components";
import { notifyErrors, typedOmitKeys } from "utils";

import { confirmPasswordValidatorRule } from "features/auth/utils";
import { useAuth } from "features/auth/hooks";
import { RegisterFormValues } from "features/auth/types";

export interface RegisterFormProps {
  showInfo?: boolean;
  firstInputRef?: RefObject<HTMLInputElement>;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({ showInfo, firstInputRef }) => {
  const auth = useAuth();

  const [form] = useForm();
  const notify = useNotify();

  const registerMutation = useMutation(auth.register, {
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: RegisterFormValues) => {
    registerMutation.mutate(typedOmitKeys(values, ["confirm-password"]));
  };

  return (
    <Form form={form} onFinish={submitHandler}>
      <h1>Welcome to Prsnt for Business</h1>
      <h1>Create an account below!</h1>
      <Text el="span" color="gray">
        Already have an account?
      </Text>{" "}
      <Link to="/login">Sign in here</Link>
      <br />
      {showInfo && (
        <>
          <WhiteSpace v="2rem" />
          <WithInfoIcon>Before sending a gift let's create an account and complete some basic settings</WithInfoIcon>
        </>
      )}
      <WhiteSpace v="2rem" />
      <div>First name</div>
      <InputFormField inputRef={firstInputRef} name="first_name" label="First name" required />
      <div>Last name</div>
      <InputFormField name="last_name" label="Last name" required />
      <div>Email</div>
      <InputFormField name="email" type="email" label="Email" required />
      <div>Company</div>
      <InputFormField name="company_name" label="Company" required />
      <div>Password</div>
      <InputFormField name="password" type="password" label="Password" required />
      <div>Confirm password</div>
      <InputFormField
        type="password"
        name="confirm-password"
        label="Confirm password"
        validateTrigger="onSubmit"
        required
        rules={[confirmPasswordValidatorRule(form)]}
      />
      <Button loading={registerMutation.isLoading} auto>
        Start Giving
      </Button>
    </Form>
  );
};
