import React from "react";

export const Search: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M14.9333 24.2668C20.088 24.2668 24.2666 20.0881 24.2666 14.9334C24.2666 9.77877 20.088 5.6001 14.9333 5.6001C9.77865 5.6001 5.59998 9.77877 5.59998 14.9334C5.59998 20.0881 9.77865 24.2668 14.9333 24.2668Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4667 21.4668L26.4 26.4001"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
