import React from "react";
import { TabsBase } from "components";

import { clientsTemplateConfig } from "features/clients/config";
import { employeesTemplateConfig } from "features/employees/config";
import { useSendGiftContext } from "features/send-gift/hooks";
import { PageTemplateContext } from "features/users/contexts";

import { RecipientsTable } from "./RecipientsTable";
import { TabCard } from "./TabCard";

export const RecipientsTab = React.memo(() => {
  const { contactType } = useSendGiftContext();
  return (
    <TabCard>
      <TabsBase.Provider value={contactType}>
        <TabsBase.Content tabKey="clients">
          <PageTemplateContext.Provider value={clientsTemplateConfig}>
            <RecipientsTable />
          </PageTemplateContext.Provider>
        </TabsBase.Content>
        <TabsBase.Content tabKey="employees">
          <PageTemplateContext.Provider value={employeesTemplateConfig}>
            <RecipientsTable />
          </PageTemplateContext.Provider>
        </TabsBase.Content>
      </TabsBase.Provider>
    </TabCard>
  );
});
