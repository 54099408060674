export function printElement(elem: HTMLElement | string) {
  const mywindow = window.open("", "PRINT", "");
  if (!mywindow) return false;

  mywindow.document.write("<html><head></head><body>");

  mywindow.document.write("</body></html>");

  // maybe FIXME: copies also js and a lot of other crap
  mywindow.document.head.append(document.head.cloneNode(true));

  typeof elem === "string"
    ? (mywindow.document.body.innerHTML += elem)
    : mywindow.document.body.append(elem.cloneNode(true));

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  // TMP
  // mywindow.close();

  return true;
}
