import React from "react";

export const Lock: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M22.6666 28.0001H9.33329C7.86663 28.0001 6.66663 26.8001 6.66663 25.3334V18.4001C6.66663 16.9334 7.86663 15.7334 9.33329 15.7334H22.6666C24.1333 15.7334 25.3333 16.9334 25.3333 18.4001V25.3334C25.3333 26.8001 24.1333 28.0001 22.6666 28.0001Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 25.0667C17.7674 25.0667 19.2 23.6341 19.2 21.8667C19.2 20.0994 17.7674 18.6667 16 18.6667C14.2327 18.6667 12.8 20.0994 12.8 21.8667C12.8 23.6341 14.2327 25.0667 16 25.0667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6 15.7333H10.4V9.6C10.4 6.53333 12.9334 4 16 4C19.0667 4 21.6 6.53333 21.6 9.6V15.7333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
