import React from "react";

export const Calendar: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M25.3333 28H6.66667C5.2 28 4 26.8 4 25.3334V9.46672C4 8.00005 5.2 6.80005 6.66667 6.80005H25.3333C26.8 6.80005 28 8.00005 28 9.46672V25.3334C28 26.8 26.8 28 25.3333 28Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3333 27.9999H6.66667C5.2 27.9999 4 26.7999 4 25.3333V12.2666H28V25.3333C28 26.7999 26.8 27.9999 25.3333 27.9999Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9333 4V9.86667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6 4V9.86667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12 19.3334C13.0309 19.3334 13.8666 18.4977 13.8666 17.4668C13.8666 16.4358 13.0309 15.6001 12 15.6001C10.969 15.6001 10.1333 16.4358 10.1333 17.4668C10.1333 18.4977 10.969 19.3334 12 19.3334Z"
        fill={fill}
      />
      <path
        d="M20 19.3334C21.0309 19.3334 21.8666 18.4977 21.8666 17.4668C21.8666 16.4358 21.0309 15.6001 20 15.6001C18.969 15.6001 18.1333 16.4358 18.1333 17.4668C18.1333 18.4977 18.969 19.3334 20 19.3334Z"
        fill={fill}
      />
      <path
        d="M12 25.0666C13.0309 25.0666 13.8666 24.2308 13.8666 23.1999C13.8666 22.169 13.0309 21.3333 12 21.3333C10.969 21.3333 10.1333 22.169 10.1333 23.1999C10.1333 24.2308 10.969 25.0666 12 25.0666Z"
        fill={fill}
      />
      <path
        d="M20 25.0666C21.0309 25.0666 21.8666 24.2308 21.8666 23.1999C21.8666 22.169 21.0309 21.3333 20 21.3333C18.969 21.3333 18.1333 22.169 18.1333 23.1999C18.1333 24.2308 18.969 25.0666 20 25.0666Z"
        fill={fill}
      />
    </svg>
  );
};
