import React from "react";

export const Write: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M14.1383 2.98661C14.7157 3.56402 14.7252 4.49722 14.1596 5.08622L8.74273 10.7273C8.46744 11.014 8.08962 11.1797 7.69225 11.188L5.41693 11.2358L5.46498 8.94521C5.47313 8.55688 5.6316 8.18685 5.90704 7.91298L11.4441 2.40759C12.0303 1.82467 12.9777 1.82602 13.5623 2.41063L14.1383 2.98661Z"
        fill={fill}
      />
      <path
        d="M8.60016 1.33301C8.96835 1.33301 9.26683 1.63148 9.26683 1.99967C9.26683 2.36786 8.96835 2.66634 8.60016 2.66634H4.00016C3.26378 2.66634 2.66683 3.26329 2.66683 3.99967V11.9997C2.66683 12.7361 3.26378 13.333 4.00016 13.333H12.0002C12.7365 13.333 13.3335 12.7361 13.3335 11.9997V8.66634C13.3335 8.29815 13.632 7.99967 14.0002 7.99967C14.3684 7.99967 14.6668 8.29815 14.6668 8.66634V11.9997C14.6668 13.4724 13.4729 14.6663 12.0002 14.6663H4.00016C2.5274 14.6663 1.3335 13.4724 1.3335 11.9997V3.99967C1.3335 2.52692 2.5274 1.33301 4.00016 1.33301H8.60016Z"
        fill={fill}
        opacity="0.3"
      />
    </svg>
  );
};
