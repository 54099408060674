import React from "react";

import { Card, CardProps, Flex, Icon, WhiteSpace } from "components";
import models from "models";
import { makeBEM } from "utils";

import { CardBrandImage } from ".";
import classNames from "classnames";

export interface PaymentMethodCardProps extends Omit<CardProps, "data"> {
  onRemoveClick?: () => void;
  data: models.PaymentMethodCardData | undefined;
  selectable?: boolean;
  selected?: boolean;
}

const c = makeBEM("payment-method-card");

export const PaymentMethodCard = ({
  data,
  className,
  children,
  onRemoveClick,
  selected,
  selectable,
  ...props
}: PaymentMethodCardProps) => {
  const cardClassName = classNames(className, selected && "active", selectable ? c(null, { selectable }) : undefined);
  return (
    <Card className={cardClassName} {...props}>
      <Flex justify="space-between" align="center">
        <Flex>
          <CardBrandImage type={data?.brand} />
          <WhiteSpace h="1rem" />
          <h4 className={c("last-4")}> {data?.last4}</h4>
        </Flex>
        <Flex align="center">
          <div>
            {data?.exp_month?.toString?.()?.padStart(2, "0")}/{data?.exp_year?.toString?.()?.slice(-2)}
          </div>
          {onRemoveClick && (
            <>
              <WhiteSpace h="0.25rem" />
              <Icon
                onClick={(e) => {
                  onRemoveClick();
                  e.stopPropagation();
                }}
                className="cursor-pointer"
                type="cross"
              />
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
