import React from "react";

import { useScreenBreakPoint } from "hooks";
import { makeBEM } from "utils";

import { SidebarContext } from "./SidebarContext";

export interface SidebarProps {
  open?: boolean;
  onClose?: () => void;
  collapsed?: boolean;
}

const bem = makeBEM("sidebar");

export const Sidebar: React.FC<SidebarProps> = ({ open, onClose, children, collapsed }) => {
  const onLaptop = useScreenBreakPoint("laptop");

  React.useEffect(() => {
    let value;
    if (onLaptop) value = "0px";
    else if (collapsed) value = "var(--sidebar-collapsed-width)";
    else value = "var(--sidebar-width)";

    document.documentElement.style.setProperty("--sidebar-current-width", value);
  }, [collapsed, onLaptop]);

  const actuallyCollapsed = !onLaptop && !!collapsed;

  return (
    <SidebarContext.Provider value={{ collapsed: actuallyCollapsed }}>
      <div className={bem(null, { "mobile-open": onLaptop && open, mobile: onLaptop, collapsed: actuallyCollapsed })}>
        {children}
      </div>
      <div className={bem("space-holder")}></div>
      <div className={bem("overlay", { visible: onLaptop && open })} onClick={onClose}></div>
    </SidebarContext.Provider>
  );
};
