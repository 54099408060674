import { Flex, Icon, WhiteSpace } from "components";

export interface ErrorMessageProps {
  message?: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <>
    {message && (
      <Flex className="my-3 color-danger">
        <Icon size="1.2em" type="alert" />
        <WhiteSpace h=".25rem" />
        <div className="flex-1">{message}</div>
      </Flex>
    )}
  </>
);
