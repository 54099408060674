import React from "react";

export interface StepContextValue {
  canGoNext: boolean;
  setCanGoNext: React.Dispatch<React.SetStateAction<boolean>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const StepContext = React.createContext<StepContextValue>({
  canGoNext: false,
  setCanGoNext: () => {},
  currentStep: 1,
  setCurrentStep: () => {},
});
