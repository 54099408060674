import { Link } from "react-router-dom";
import { Form } from "ebs-design";

import { InputFormField, InputPhoneFormField, Text, WhiteSpace, Checkbox, WithOptional } from "components";
import { capitalized } from "utils";
import models from "models";

import { usePageTemplate } from "features/users/hooks";

import { GroupSelect } from ".";

export interface UserFormFieldsProps {
  onAddGroupClick: (search?: string) => void;
  user?: models.UserContact;
  justAddedGroup: models.Group | undefined;
}

export const UserFormFields = ({ user, justAddedGroup, onAddGroupClick }: UserFormFieldsProps) => {
  const template = usePageTemplate();

  return (
    <>
      <div>First name</div>
      <InputFormField name="first_name" label="First name" required />
      <div>Last name</div>
      <InputFormField name="last_name" label="Last name" required />
      <div>Email address</div>
      <InputFormField name="email" type="email" label="Email" required />
      {template.contactType === "employees" && (
        <>
          <WithOptional>Phone</WithOptional>
          <InputPhoneFormField name="phone" label="Phone" />
        </>
      )}

      <WithOptional>{capitalized(template.groupName.singular)}</WithOptional>
      <Text color="gray" size="0.9rem">
        Select a {template.groupName.singular} from the list or add a new one{" "}
        <Text button el="span" color="primary" onClick={() => onAddGroupClick()}>
          here
        </Text>
      </Text>
      <Form.Field
        name="group"
        hideLabel
        label={capitalized(template.groupName.singular)} /* rules={[{ required: true }]} */
      >
        <GroupSelect additional={justAddedGroup || user?.group} onAddGroupClick={onAddGroupClick} />
      </Form.Field>

      <Text fw={600}>Automatic Gifting (On by default)</Text>
      <Text color="gray">
        Do you want to automatically send gifts to this person? Select the days below and we will send them a gift on
        that day. You can set up the automatic gifts <Link to="/auto-gifts">here</Link> or in the Auto Gifting tab on
        the left
      </Text>
      <WhiteSpace v="1rem" />
      {template.contactType === "employees" && (
        <Form.Field className="m-0" name="send_birthday_gift" valuePropName="checked" initialValue={true}>
          <Checkbox>Birthday</Checkbox>
        </Form.Field>
      )}
      <Form.Field name="send_special_day_gift" valuePropName="checked" initialValue={true}>
        <Checkbox>Christmas Day</Checkbox>
      </Form.Field>
      <WhiteSpace v="2rem" />
    </>
  );
};
