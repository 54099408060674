import React from "react";

export const Idea: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M16 22.1333C21.0074 22.1333 25.0667 18.074 25.0667 13.0667C25.0667 8.05928 21.0074 4 16 4C10.9926 4 6.93335 8.05928 6.93335 13.0667C6.93335 18.074 10.9926 22.1333 16 22.1333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 17.7332C17.1782 17.7332 18.1334 16.7781 18.1334 15.5999C18.1334 14.4217 17.1782 13.4666 16 13.4666C14.8218 13.4666 13.8667 14.4217 13.8667 15.5999C13.8667 16.7781 14.8218 17.7332 16 17.7332Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8666 21.2V24.1333C19.8666 26.2666 18.1333 28 16 28C13.8666 28 12.1333 26.2666 12.1333 24.1333V21.2"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 10.9333V8.2666"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.2667 10.9333L20.1334 9.06665"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M13.7334 10.9333L11.8667 9.06665"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 17.8667V22.1334"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
