import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";

import { useForm, Form, Select } from "ebs-design";

import { Flex, Icon, InputFormField, ErrorMessage, WhiteSpace, Button } from "components";
import api from "api";
import { falsyToUndefined, makeBEM } from "utils";
import models from "models";
import { useStateRef } from "hooks";

import { countries } from "features/payments/config";

export interface AddPaymentMethodFormProps {
  disabled?: boolean;
  onLoadingChange?: React.Dispatch<boolean>;
  onSuccess?: React.Dispatch<models.PaymentMethod>;
}

const c = makeBEM("payment-method-form");

export const AddPaymentMethodForm = ({ disabled, onLoadingChange, onSuccess }: AddPaymentMethodFormProps) => {
  const [form] = useForm();

  const elements = useElements();
  const stripe = useStripe();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<StripeError>();
  const [addError, setAddError] = React.useState<StripeError>();

  const onLoadingChangeRef = useStateRef(onLoadingChange);
  React.useEffect(() => {
    onLoadingChangeRef.current?.(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const addHandler = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement(CardElement)?.focus();
      return;
    }
    setIsLoading(true);

    const billingDetails = falsyToUndefined(form.getFieldsValue());

    const { paymentMethod, error: addCardError } = await api.stripe.addCard(
      stripe,
      elements.getElement(CardElement),
      billingDetails
    );
    setIsLoading(false);

    if (addCardError) setAddError(addCardError);
    else {
      elements.getElement(CardElement)?.clear();
      form.resetFields();
      setAddError(undefined);
      onSuccess?.({ id: paymentMethod?.id, data: paymentMethod?.card });
    }
  };

  return (
    <>
      <Form form={form}>
        <div>Address line 1</div>
        <InputFormField name={["address", "line1"]} label="Address line 1" />
        <div className={c("responsive-4-cols")}>
          <div>
            <div>Country</div>
            <Form.Field name={["address", "country"]} label="Country" hideLabel>
              <Select options={countriesOptions} />
            </Form.Field>
          </div>
          <div>
            <div>State</div>
            <InputFormField name={["address", "state"]} label="State" />
          </div>
          <div>
            <div>City</div>
            <InputFormField name={["address", "city"]} label="City" />
          </div>
          <div>
            <div>Postal code</div>
            <InputFormField name={["address", "postal_code"]} label="Postal code" />
          </div>
        </div>
      </Form>
      <WhiteSpace v="1rem" />
      <div>Card</div>
      <div className="stripe-card__wrapper flex-1">
        <CardElement
          onChange={(e) => {
            setError(e.error);
          }}
          options={{
            style: {
              base: {
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
              },
            },
          }}
        />
      </div>
      <WhiteSpace v="2rem" />
      <Flex justify="center">
        <Button disabled={disabled} loading={isLoading || !stripe} icon={<Icon type="plus" />} onClick={addHandler}>
          Add card
        </Button>
      </Flex>
      {error ? <ErrorMessage message={error.message} /> : <ErrorMessage message={addError?.message} />}
    </>
  );
};

const countriesOptions = countries.map((c) => ({
  text: c.Name,
  value: c.Code,
}));
