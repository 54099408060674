import React from "react";

export const Store: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M26.6667 14.3999V25.3332C26.6667 26.7999 25.4667 27.9999 24 27.9999H8.00004C6.53337 27.9999 5.33337 26.7999 5.33337 25.3332V14.5332"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5333 6.80005H25.3333C26.8 6.80005 28 8.00005 28 9.46672V12.2667H4V9.46672C4 8.00005 5.2 6.80005 6.66667 6.80005H7.46667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 17.3333H5.33337V24.3999H16V17.3333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5333 25.5999V20.2666C18.5333 18.7999 19.7333 17.4666 21.3333 17.4666C22.8 17.4666 24.1333 18.6666 24.1333 20.2666V25.5999"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5333 4H7.46667V9.6H24.5333V4Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M6.4 14.6666C5.06667 14.6666 4 13.5999 4 12.2666H8.8C8.8 13.5999 7.73333 14.6666 6.4 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.2 14.6666C9.86672 14.6666 8.80005 13.5999 8.80005 12.2666H13.6C13.6 13.5999 12.5334 14.6666 11.2 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M16 14.6666C14.6666 14.6666 13.6 13.5999 13.6 12.2666H18.4C18.4 13.5999 17.3333 14.6666 16 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8 14.6666C19.4667 14.6666 18.4 13.5999 18.4 12.2666H23.2C23.2 13.5999 22.1334 14.6666 20.8 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M25.6 14.6666C24.2666 14.6666 23.2 13.5999 23.2 12.2666H28C28 13.5999 26.9333 14.6666 25.6 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
