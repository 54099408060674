import React from "react";
import { useAuthProvider } from "features/auth/hooks";

export type AuthContextValue = ReturnType<typeof useAuthProvider>;

export const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  isLoading: true,
  login: async () => {},
  register: async () => {},
  logout: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const value = useAuthProvider();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
