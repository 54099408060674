import api, { querykeys } from "api";
import { TemplateConfig } from "features/users/types";

export const employeesTemplateConfig: TemplateConfig = {
  usersApi: api.companies.createUsersApi("employees"),
  querykeys: {
    users: querykeys.companies.users.employees,
    groups: querykeys.companies.groups.teams,
  },
  name: { plural: "employees", singular: "employee" },
  groupName: { plural: "teams and groups", singular: "team or group" },
  contactType: "employees",
};
