import React from "react";

import { FilterButton, Flex, Text, Collapse, WhiteSpace } from "components";
import { useScreenBreakPoint } from "hooks";

export interface TableCardHeaderProps {
  title: string;
  count?: number;

  filters?: React.ReactNode | ((isSmallScreen: boolean) => React.ReactNode);
  padded?: boolean;
}

export const TableCardHeader = ({ title, count, filters: propsFilters, padded }: TableCardHeaderProps) => {
  const isSmallScreen = useScreenBreakPoint("690px");

  const [_collapsed, setCollapsed] = React.useState(true);
  const collapsed = !isSmallScreen || _collapsed;

  // const wrap = (children: React.ReactNode) => (isSmallScreen ? <>{children}</> : <Card>{children}</Card>);
  const wrap = (children: React.ReactNode) => <>{children}</>;
  const header = (children: React.ReactNode) => (isSmallScreen ? <h3>{children}</h3> : <h2>{children}</h2>);

  const filters = propsFilters instanceof Function ? propsFilters(isSmallScreen) : propsFilters;

  return wrap(
    <>
      <Flex justify="space-between" align="center" wrap gap="1rem" style={{ padding: padded ? "1.5rem" : undefined }}>
        {header(
          <>
            {title}{" "}
            <Text el="small" color="gray">
              ({count || "-"})
            </Text>
          </>
        )}
        {!isSmallScreen ? (
          <Flex align="center" gap="1rem">
            {filters}
          </Flex>
        ) : (
          <FilterButton onClick={() => setCollapsed((prev) => !prev)} light />
        )}
      </Flex>
      <Collapse collapsed={collapsed} time={200}>
        <WhiteSpace v="1rem" />
        <Flex align="stretch" gap="1rem" direction="column">
          {filters}
        </Flex>
      </Collapse>
    </>
  );
};
