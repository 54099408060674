import React from "react";

import { Container } from "./Container";
import { DebouncedInput, DebouncedInputProps } from "./DebouncedInput";
import { Icon } from "./Icon";

export interface SearchInputProps extends DebouncedInputProps {}

export const SearchInput: React.FC<SearchInputProps> = ({ ...props }) => {
  return (
    <Container max={250}>
      <DebouncedInput placeholder="Search" prefix={<Icon type="search" />} {...props} />
    </Container>
  );
};
