import { ColorMapKey, getColor } from "./utils";

export interface LinearProgressProps {
  /**
   * 0-100
   */
  value: number;
  color: ColorMapKey;
}

export const LinearProgress = ({ value, color }: LinearProgressProps) => {
  return (
    <div className="linear-progress" style={{ background: "#f5f5f5" }}>
      <div style={{ transition: ".2s", width: value + "%", background: getColor(color) }}></div>
    </div>
  );
};
