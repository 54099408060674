import React from "react";

export const Info: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="16" cy="16" fill="none" r="15" stroke={stroke} />
      <path
        d="M15.2297 9.19207C15.0697 9.19207 14.9337 9.14407 14.8217 9.04807C14.7257 8.93607 14.6777 8.80007 14.6777 8.64007V7.34407C14.6777 7.18407 14.7257 7.04807 14.8217 6.93607C14.9337 6.82407 15.0697 6.76807 15.2297 6.76807H16.7417C16.9017 6.76807 17.0377 6.82407 17.1497 6.93607C17.2617 7.04807 17.3177 7.18407 17.3177 7.34407V8.64007C17.3177 8.80007 17.2617 8.93607 17.1497 9.04807C17.0377 9.14407 16.9017 9.19207 16.7417 9.19207H15.2297ZM15.4217 24.0001C15.2617 24.0001 15.1257 23.9521 15.0137 23.8561C14.9177 23.7441 14.8697 23.6081 14.8697 23.4481V12.0721C14.8697 11.9121 14.9177 11.7841 15.0137 11.6881C15.1257 11.5761 15.2617 11.5201 15.4217 11.5201H16.5737C16.7337 11.5201 16.8617 11.5761 16.9577 11.6881C17.0697 11.7841 17.1257 11.9121 17.1257 12.0721V23.4481C17.1257 23.6081 17.0697 23.7441 16.9577 23.8561C16.8617 23.9521 16.7337 24.0001 16.5737 24.0001H15.4217Z"
        fill={fill}
      />
    </svg>
  );
};
