import models from "models";
import { GroupedProductListCategory } from "models/products";
import React from "react";
import { makeBEM } from "utils";
import { ProductCard, ProductCardProps } from "./ProductCard";
import { ProductList } from "./ProductList";

export interface ProductListGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  data: GroupedProductListCategory;
  getProductProps?: (data: models.Product) => Omit<Partial<ProductCardProps>, "data">;
}

const bem = makeBEM("product-list-group");

export const ProductListGroup: React.FC<ProductListGroupProps> = ({ data, getProductProps, children, ...props }) => {
  return (
    <div className={bem(null)} {...props}>
      <h2 className={bem("title")}>{data.title}</h2>
      <div className={bem("lists-container")}>
        {data.sub_categories?.map((subCategory, i) => {
          return (
            <ProductList key={i} title={subCategory.title}>
              {subCategory.products?.map((product, i) => (
                <ProductCard key={i} data={product} {...getProductProps?.(product)} />
              ))}
            </ProductList>
          );
        })}
      </div>
    </div>
  );
};
