import React from "react";
import { Flex, FlexProps } from "./Flex";
import { Icon } from "./Icon";

export interface WithInfoIconProps extends FlexProps {}

export const WithInfoIcon: React.FC<WithInfoIconProps> = ({ children, ...props }) => {
  return (
    <Flex gap="0.5rem" {...props}>
      <Icon type="info" /> {children}
    </Flex>
  );
};
