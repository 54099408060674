import React from "react";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Form, useForm, useNotify } from "ebs-design";

import { WhiteSpace, InputFormField, TabsBase, Button, Flex, Icon } from "components";

import { confirmPasswordValidatorRule } from "../utils";
import api from "api";
import { RequestRestorePasswordFormValues, RestorePasswordFormValues } from "../types";
import { notifyErrors } from "utils";

export const ResetPasswordForm = () => {
  const [initForm] = useForm();
  const [resetForm] = useForm();
  const notify = useNotify();
  const history = useHistory();

  const [currentStep, setCurrentStep] = React.useState(1);

  const restoreReuquestMutation = useMutation(api.auth.restorePasswordRequest, {
    onSuccess: (data) => notify.success({ title: "", description: data.message }),
    onError: (error) => notifyErrors(notify, error),
  });

  const restoreMutation = useMutation(api.auth.restorePassword, {
    onSuccess: (data) => {
      notify.success({ title: "", description: data.message });
      history.push("/login");
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const initSubmitHandler = (values: RequestRestorePasswordFormValues) => {
    restoreReuquestMutation.mutate({ username: values.email }, { onSuccess: () => setCurrentStep(2) });
  };
  const resetSubmitHandler = (values: RestorePasswordFormValues) => {
    restoreMutation.mutate(values);
  };

  return (
    <TabsBase.Provider value={currentStep}>
      <TabsBase.Content tabKey={1}>
        <Form form={initForm} onFinish={initSubmitHandler}>
          <h1>Forgot your password?</h1>
          <p>
            You can reset your password here or <Link to="/login">go back to login</Link>
          </p>
          <WhiteSpace v="2rem" />
          <div>Your email</div>
          <InputFormField name="email" label="Email" required />

          <Flex justify="start">
            <Button auto loading={restoreReuquestMutation.isLoading}>
              Next
            </Button>
          </Flex>
        </Form>
      </TabsBase.Content>
      <TabsBase.Content tabKey={2}>
        <Form form={resetForm} onFinish={resetSubmitHandler}>
          <h1>Reset your password</h1>
          <WhiteSpace v="2rem" />
          <div>Token</div>
          <InputFormField name="token" label="Token" required extra="Check your email for a confirmation token" />
          <div>New password</div>
          <InputFormField type="password" name="password" label="Password" required />
          <div>Confirm password</div>
          <InputFormField
            type="password"
            name="confirm_password"
            label="Confirm password"
            validateTrigger="onSubmit"
            required
            rules={[confirmPasswordValidatorRule(resetForm)]}
          />
          <Flex justify="space-between" gap="1rem">
            <Button
              type="button"
              icon={<Icon type="back" />}
              auto
              color="secondary"
              bordered
              onClick={() => setCurrentStep(1)}
            >
              Back
            </Button>
            <Button loading={restoreMutation.isLoading} auto>
              Confirm
            </Button>
          </Flex>
        </Form>
      </TabsBase.Content>
    </TabsBase.Provider>
  );
};
