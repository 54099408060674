import React from "react";

export type BreakPointKey = "laptop" | "tablet" | "mobile";

/**
 * WARNING Need to sync with scss variables
 */
export const breakPointSize: Record<BreakPointKey, number> = {
  laptop: 1024,
  tablet: 768,
  mobile: 376,
};

export const isBreakPointKey = (key: any): key is BreakPointKey => Object.keys(breakPointSize).includes(key);

export function useScreenBreakPoint(breakPoint: BreakPointKey | (string & {}) = "laptop") {
  const [on, setOn] = React.useState(false);

  React.useLayoutEffect(() => {
    const media = window.matchMedia(
      `(max-width: ${isBreakPointKey(breakPoint) ? breakPointSize[breakPoint] + "px" : breakPoint}`
    );
    setOn(media.matches);
    let listener = (e: MediaQueryListEvent) => {
      setOn(e.matches);
    };
    media.addEventListener("change", listener);

    return () => {
      media.removeEventListener("change", listener);
    };
  }, [breakPoint]);

  return on;
}
