import React from "react";

export const Plus: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <line
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="27.5"
        x2="4.5"
        y1="16.5"
        y2="16.5"
      />
      <line
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="16.5"
        x2="16.5"
        y1="4.5"
        y2="27.5"
      />
    </svg>
  );
};
