import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import api, { querykeys } from "api";
import models from "models";

import { createRefreshTokenInterceptors } from "api/axios/interceptors";
import { apiAxios } from "api/axios";

export function useAuthProvider() {
  const [user, setUser] = React.useState<models.User | null>(null);
  const history = useHistory();

  const userQuery = useQuery(querykeys.loggedUser, api.auth.getUser, {
    retry: false,
    onSuccess: (user) => {
      if (user.company?.name) setUser(user);
    },
    onError: () => {
      if (user) setUser(null);
    },
  });

  const login = async (credentials: models.LoginCredentials) => {
    await api.auth.login(credentials);
    await userQuery.refetch();
    history.push("/");
  };

  const register = async (credentials: models.RegisterCredentials) => {
    await api.auth.register(credentials);
    await userQuery.refetch();
    history.push("/settings");
  };

  const logout = () => {
    api.auth.logout();
    setUser(null);
    history.push("/login");
  };

  React.useEffect(() => {
    const id = apiAxios.interceptors.response.use(...createRefreshTokenInterceptors(apiAxios, () => setUser(null)));

    return () => {
      apiAxios.interceptors.response.eject(id);
    };
  }, []);

  return {
    user,
    isLoading: userQuery.isLoading,
    login,
    register,
    logout,
  };
}

// RECYCLE
// sometimes useQuery makes infinite requests so you can replace this with the useQuery statement
// const [isLoading, setIsLoading] = React.useState(true);

// React.useEffect(() => {
//   api.auth
//     .getUser()
//     .then((user) => {
//       if (user.company?.name) setUser(user);
//     })
//     .catch(() => {
//       setUser(null);
//     })
//     .finally(() => setIsLoading(false));
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);
