export const nomenclatureSingularList = [
  "country",
  "region",
  "subregion",
  "city",
] as const;

export const nomenclaturePluralList = [
  "countries",
  "regions",
  "divisions",
  "cities",
] as const;


export type NomenclatureSingular = typeof nomenclatureSingularList[number];
export type NomenclaturePlural = typeof nomenclaturePluralList[number];
export type NomenclatureIdKey = `${NomenclatureSingular}_id`;
