import React from "react";

export const Friends: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M3.8667 27.6003C3.8667 22.8003 7.73337 18.9336 12.5334 18.9336C17.3334 18.9336 21.2 22.8003 21.2 27.6003"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M12.4 15.8668C15.2718 15.8668 17.6 13.5387 17.6 10.6668C17.6 7.79492 15.2718 5.4668 12.4 5.4668C9.52807 5.4668 7.19995 7.79492 7.19995 10.6668C7.19995 13.5387 9.52807 15.8668 12.4 15.8668Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.4667 18.9334C20.4 18.1334 21.6 17.6001 22.9333 17.6001C25.7333 17.6001 27.8667 19.8668 27.8667 22.5334"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M22.9333 15.7334C24.5534 15.7334 25.8667 14.4201 25.8667 12.8C25.8667 11.18 24.5534 9.8667 22.9333 9.8667C21.3133 9.8667 20 11.18 20 12.8C20 14.4201 21.3133 15.7334 22.9333 15.7334Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
