import { useSetState } from "react-use";
import { useQuery } from "react-query";

import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, QueryList, Text, WhiteSpace } from "components";
import { makeBEM } from "utils";
import api, { querykeys } from "api";
import models from "models";

import { MonthlyInvoiceItem, MonthlyInvoiceItemProps } from ".";

export interface MontlyInvoiceModalProps extends ModalProps {
  operationId?: string;
  onItemRowClick: MonthlyInvoiceItemProps["onRowClick"];
}

const c = makeBEM("monthly-invoice");

export const MonthlyInvoiceModal = ({ operationId, onItemRowClick, ...props }: MontlyInvoiceModalProps) => {
  const query = useQuery(querykeys.companies.monthlyInvoice(), () => api.companies.monthlyInvoice.get());

  const [collapsed, setCollapsed] = useSetState<Record<number, boolean>>({});
  const isCollapsed = (i: number, obj: Record<number, boolean> = collapsed) => (obj[i] === undefined ? true : obj[i]);

  // HOLD
  // const [maxItems, setMaxItems] = React.useState<number | null>(monthlyInvoiceLimitChoice[0].value);

  return (
    <Modal {...props}>
      <Modal.Content>
        {/* <Flex align="center" gap="1rem"> */}
        <h1 className="flex-1">Monthly Invoice</h1>
        {/* <Flex align="center" gap="1rem">
            Limit:
            <ButtonGroup>
              {monthlyInvoiceLimitChoice.map((choice) => (
                <Button
                  size="small"
                  type={maxItems === choice.value ? "primary" : "ghost"}
                  onClick={() => setMaxItems(choice.value)}
                >
                  {choice.text}
                </Button>
              ))}
            </ButtonGroup>
          </Flex> */}
        {/* </Flex> */}
        <Text el="p" color="gray">
          Click on items to expand
        </Text>
        <WhiteSpace v="2.5rem" />
        <QueryList
          query={query}
          getItems={(data) => data}
          renderItems={(items: models.MonthlyInvoice[]) => (
            <Flex align="stretch" direction="column" gap="1rem" className={c("content")}>
              {items.map((item, i) => (
                <MonthlyInvoiceItem
                  // maxItems={maxItems}
                  data={item}
                  key={i}
                  collapsed={isCollapsed(i)}
                  onHeaderClick={() => setCollapsed((prev) => ({ [i]: !isCollapsed(i, prev) }))}
                  onRowClick={onItemRowClick}
                />
              ))}
            </Flex>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};
