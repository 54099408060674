import React from "react";
import { useInfiniteQuery } from "react-query";

import { Button as EBSButton, Select, useNotify } from "ebs-design";
import { SelectProps } from "ebs-design/dist/components/molecules/Select/components/Component";

import { DebouncedInput, Flex, Icon, Button, WhiteSpace } from "components";
import { combineQueryPagesData, hasNextPage, notifyErrors } from "utils";
import models from "models";

import { usePageTemplate } from "features/users/hooks";

export interface GroupSelectProps extends SelectProps {
  additional?: models.Group;
  onAddGroupClick?: (search?: string) => void;
}

export const GroupSelect = ({ additional, onAddGroupClick, ...selectProps }: GroupSelectProps) => {
  const notify = useNotify();
  const template = usePageTemplate();

  const [search, setSearch] = React.useState("");
  const currentSearchValueRef = React.useRef("");

  const queryParams = { search };

  const query = useInfiniteQuery(
    template.querykeys.groups.many(queryParams),
    ({ pageParam = 1 }) => template.usersApi.groups.get({ ...queryParams, page: pageParam }),
    {
      onError: (error) => notifyErrors(notify, error),
      getNextPageParam: (lastPage) => (hasNextPage(lastPage) ? (lastPage.current_page || 0) + 1 : undefined),
    }
  );

  const groups = combineQueryPagesData(query.data);

  if (additional && !groups.find((g) => g.id === additional?.id)) groups.push(additional);

  return (
    <Select loading={query.isLoading} {...selectProps}>
      <Flex>
        <div className="flex-1">
          <DebouncedInput
            currentValueRef={currentSearchValueRef}
            placeholder="Search"
            value={search}
            onChange={setSearch}
          />
        </div>
        {onAddGroupClick && (
          <>
            <WhiteSpace h="0.5rem" />
            <EBSButton
              onClick={() => {
                onAddGroupClick(currentSearchValueRef.current);
                setSearch("");
              }}
              style={{ width: "4rem" }}
              type="primary"
              icon={() => <Icon type="plus" />}
            />
          </>
        )}
      </Flex>
      <WhiteSpace v="0.5rem" />
      <Select.Options>
        {groups.map((g) => (
          <Select.Options.Item key={g.name} value={g.name || 0}>
            {g.name}
          </Select.Options.Item>
        ))}
      </Select.Options>
      <WhiteSpace v="1rem" />
      {query.hasNextPage && (
        <Flex justify="center">
          <Button light loading={query.isFetching} color="secondary" onClick={() => query.fetchNextPage()} size="sm">
            Load more
          </Button>
        </Flex>
      )}
    </Select>
  );
};
