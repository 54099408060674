import React from "react";
import { useHistory } from "react-router-dom";

import * as jotai from "jotai";
import { RESET } from "jotai/utils";

import { useStateRef } from "./useStateRef";

type BooleanWritebaleAtom = jotai.WritableAtom<boolean, jotai.SetStateAction<boolean> | typeof RESET>;

export function useModalState<Data>(atom: BooleanWritebaleAtom, initialData?: Data) {
  const history = useHistory();
  const historyRef = useStateRef(history);

  const [isOpen, setIsOpen] = jotai.useAtom(atom);
  const [data, setData] = React.useState<Data | undefined>(initialData);

  React.useLayoutEffect(() => {
    /** Reset state when entering application */
    setIsOpen(RESET);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = React.useMemo(
    () => ({
      open: () => setIsOpen(true),
      openWith: (value: React.SetStateAction<Data | undefined>) => {
        setData(value);
        setIsOpen(true);
      },
      close: () => {
        historyRef.current.length ? historyRef.current.goBack() : setIsOpen(false);
      },
      reset: () => {
        historyRef.current.length ? historyRef.current.goBack() : setIsOpen(false);
        setData(undefined);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsOpen]
  );

  return React.useMemo(
    () => ({
      isOpen,
      data,
      ...actions,
    }),
    [isOpen, data, actions]
  );
}
