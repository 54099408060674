import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";
import { GenericObject } from "types";

export class TableColumnBuilder<RecordType> {
  private _columns: ColumnType<RecordType>[] = [];

  add(
    title: ColumnType<RecordType>["title"],
    dataIndex: ColumnType<RecordType>["dataIndex"],
    render?: ColumnType<RecordType>["render"],
    other?: ColumnType<RecordType>
  ) {
    this._columns.push({
      title,
      dataIndex,
      render,
      ...other,
      onCell: (...args) =>
        ({
          "data-title": typeof title === "string" ? title : undefined,
          ...other?.onCell?.(...args),
        } as GenericObject),
    });
    return this;
  }

  buildWith(dispatch: React.Dispatch<TableColumnBuilder<RecordType>>) {
    dispatch(this);
    return this;
  }

  getColumns() {
    return [...this._columns];
  }
}
