import { useSetState } from "react-use";
import { useQuery } from "react-query";

import { DatePicker, Form, Modal, useForm } from "ebs-design";

import * as dateFNS from "date-fns";

import {
  WhiteSpace,
  Container,
  Flex,
  Drawer,
  ProductFilters,
  Button,
  Icon,
  ProductListGroup,
  QueryList,
  InputFormField,
  ScrollToTopButton,
  FilterButton,
  NoFiltersMatchMessage,
  ProductCharacteristicChoices,
} from "components";
import api, { querykeys } from "api";
import { useModalState, useProductFilters, useScreenBreakPoint } from "hooks";
import { atoms } from "atoms";
import { getSendMethodType, makeBEM } from "utils";
import models from "models";

import { deafultAutoGiftEditContextValue, AutoGiftEditContext } from "features/auto-gifts/contexts";
import { PaymentMethodModal } from "features/auto-gifts/components";
import { RequiredSettingsWrapper } from "features/common/components";
import { MONTH_DAY_FORMAT } from "app-constants";
import React from "react";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

const bem = makeBEM("auto-gifts-edit");

export interface AutoGiftFormProps {
  titlePrefix: string;
  data?: models.AutoGift;
  apiCall: (payload: models.AutoGiftPatch) => Promise<models.AutoGift>;
}

const userContactTypes: models.UserContactType[] = ["clients", "employees"];
const UserContactTypeParam = withDefault(createEnumParam(userContactTypes), "employees");
const defaultUserContactType: models.UserContactType = "employees";

export const AutoGiftsForm = ({ titlePrefix, apiCall, data }: AutoGiftFormProps) => {
  const [queryContactType] = useQueryParam("contact_type", UserContactTypeParam);
  const contactType = data?.contact_type || queryContactType || defaultUserContactType;

  const pmModal = useModalState(atoms.paymentMethod);
  const productFilters = useModalState(atoms.productFilters);
  const characteristicsModal = useModalState<models.Product>(atoms.characteristics);

  const [addForm] = useForm();

  const onTablet = useScreenBreakPoint("tablet");

  const [contextValue, setContextValue] = useSetState({
    ...deafultAutoGiftEditContextValue,
    contactType,
    sendMethodType: getSendMethodType(contactType),
    form: addForm,
  });

  React.useEffect(() => {
    setContextValue({
      contactType: data?.contact_type || defaultUserContactType,
      sendMethodType: getSendMethodType(data?.contact_type || defaultUserContactType),
    });
    addForm.setFieldsValue({ ...data, timer: dateFNS.format(new Date(data?.timer || 0), MONTH_DAY_FORMAT) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const productsQuery = useQuery(querykeys.products.many(), () => api.companies.products.grouped());

  const { productFilterProps, filteredProducts } = useProductFilters(productsQuery.data, productFilters.close);

  const isCustomAutoGift = !data || data?.trigger === models.AutoGiftTrigger.custom;

  const scrollToTop = () => {
    const y = window.scrollY;
    window.setTimeout(() => {
      if (y === window.scrollY)
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    }, 750);
  };

  return (
    <RequiredSettingsWrapper>
      <Container mauto size={970}>
        <WhiteSpace v="2rem" />
        <AutoGiftEditContext.Provider value={{ ...contextValue, setContextValue }}>
          <Drawer open={productFilters.isOpen} onClose={productFilters.close}>
            <ProductFilters {...productFilterProps} />
          </Drawer>
          <Form form={addForm}>
            <Flex align="center" justify="space-between">
              <h1 className={bem("header")}>
                {titlePrefix} automated gift for {contactType === "employees" ? "employees" : "clients and customers"}
              </h1>
              <Form.Field className={bem("save-button-field")} name={[]}>
                {(_, __, form) => {
                  const values = Object.values(form.getFieldsValue() || {});
                  const disabled = !values.length || !values.every((_) => _) || !contextValue.selectedProduct;

                  return (
                    <Button
                      onClick={pmModal.open}
                      disabled={disabled}
                      size="md"
                      auto={onTablet}
                      icon={<Icon type="edit" />}
                      className={bem("save-button")}
                      type="button"
                    >
                      Save
                    </Button>
                  );
                }}
              </Form.Field>
            </Flex>
            <WhiteSpace v="1rem" />
            <div className={bem("form-wrapper")}>
              {!isCustomAutoGift && (
                <>
                  <Flex gap="0.5rem">
                    <Icon type="info" /> This is a builtin automated gift
                  </Flex>
                  <WhiteSpace v="1rem" />
                </>
              )}
              <WhiteSpace v="1rem" />
              {isCustomAutoGift ? (
                <div>Select the date you want the gift to be sent</div>
              ) : (
                <div>Date the gift will be sent</div>
              )}
              <Form.Field
                name="timer"
                // initialValue={dateFNS.format(new Date(), MONTH_DAY_FORMAT)}
                initialValue={" "}
                rules={[{ required: true }]}
                label="Date"
                hideLabel
              >
                <DatePicker
                  className="hello"
                  showYearDropdown={false}
                  disabled={!isCustomAutoGift}
                  dateFormatCalendar="LLLL"
                  dateFormat={MONTH_DAY_FORMAT}
                />
              </Form.Field>
              <div>Title</div>
              <InputFormField disabled={!isCustomAutoGift} name="title" label="Title" required />
              <div>Message</div>
              <InputFormField name="message" textarea label="Message" required />
              <WhiteSpace v="0.5rem" />
            </div>
          </Form>
          <WhiteSpace v="4rem" />
          <div className={bem("header")}>
            <Flex gap="0.5rem">
              <Icon type="info" />
              <div>Select only one product to be sent automatically then click the save button</div>
            </Flex>
          </div>
          <WhiteSpace v="2rem" />
          <Flex className={bem("filter-toggle")} justify="end">
            <FilterButton onClick={productFilters.open} />
          </Flex>
          <QueryList
            query={productsQuery}
            emptyText={<NoFiltersMatchMessage />}
            getItems={() => filteredProducts}
            renderItems={(items) => (
              <>
                <Flex direction="column" gap="3.5rem" align="stretch">
                  {items.map((category) => (
                    <ProductListGroup
                      data={category}
                      key={category.id}
                      getProductProps={(product) => ({
                        selectable: true,
                        selected: contextValue.selectedProduct?.product?.id === product.id,
                        choice:
                          contextValue.selectedProduct?.product.id === product.id
                            ? contextValue.selectedProduct?.product_characteristic_choices
                            : undefined,
                        onClick: () => {
                          if (product.characteristic_types?.length) {
                            characteristicsModal.openWith(product);
                          } else {
                            setContextValue({ selectedProduct: { product } });
                            scrollToTop();
                          }
                        },
                      })}
                    />
                  ))}
                </Flex>
              </>
            )}
          />
          <PaymentMethodModal
            apiCall={apiCall}
            isCustomAutoGift={isCustomAutoGift}
            open={pmModal.isOpen}
            onClose={pmModal.close}
          />
          <Modal size="small" open={characteristicsModal.isOpen} onClose={characteristicsModal.close}>
            <Modal.Content>
              <ProductCharacteristicChoices
                characteristics={characteristicsModal.data?.characteristic_types || []}
                onChoiceClick={(choice) => {
                  setContextValue({
                    selectedProduct: { product: characteristicsModal.data!, product_characteristic_choices: choice },
                  });
                  characteristicsModal.close();
                  scrollToTop();
                }}
              />
            </Modal.Content>
          </Modal>
          <ScrollToTopButton offset={300} />
        </AutoGiftEditContext.Provider>
      </Container>
    </RequiredSettingsWrapper>
  );
};
