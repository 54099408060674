import React from "react";

import models from "models";
import { Button } from "./Button";
import { WhiteSpace } from "./WhiteSpace";

export interface ProductCharacteristicChoicesProps {
  characteristics: models.ProductCharacteristic[];
  onChoiceClick: React.Dispatch<models.ProductCharacteristicChoice>;
}

export const ProductCharacteristicChoices: React.FC<ProductCharacteristicChoicesProps> = ({
  characteristics,
  onChoiceClick,
}) => {
  return (
    <>
      {characteristics.map((characteristic, i) => (
        <div key={i}>
          <h4 style={{ paddingLeft: "0.75rem" }}>{characteristic.characteristic_title}</h4>
          <WhiteSpace v="0.5rem" />

          {characteristic.choices?.map((choice, i, arr) => (
            <React.Fragment key={i}>
              <Button color="secondary" align="left" light sharp block key={i} onClick={() => onChoiceClick(choice)}>
                {choice.value}
              </Button>
              {i < arr.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
      ))}
    </>
  );
};
