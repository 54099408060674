import React from "react";

export const Flag: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M16.9844 0.00927734H0V16.9937H16.9844V0.00927734Z"
        fill="#F5F5F5"
      />
      <path
        d="M15.6938 0.00927734H1.30621C0.58481 0.00927734 0 0.594088 0 1.31549H17C17 0.594088 16.4152 0.00927734 15.6938 0.00927734Z"
        fill="#FF4B55"
      />
      <path d="M17 5.23389H0V6.5401H17V5.23389Z" fill="#FF4B55" />
      <path d="M17 2.62109H0V3.9273H17V2.62109Z" fill="#FF4B55" />
      <path d="M17 7.84668H0V9.15289H17V7.84668Z" fill="#FF4B55" />
      <path d="M17 13.0723H0V14.3785H17V13.0723Z" fill="#FF4B55" />
      <path
        d="M17 15.6855H0C0 16.4069 0.584812 16.9918 1.30621 16.9918H15.6938C16.4152 16.9918 17 16.4069 17 15.6855Z"
        fill="#FF4B55"
      />
      <path d="M17 10.46H0V11.7662H17V10.46Z" fill="#FF4B55" />
      <path
        d="M9.14547 0.00927734H1C0.447715 0.00927734 0 0.456991 0 1.00928V9.15475H9.14547V0.00927734Z"
        fill="#41479B"
      />
      <path
        d="M1.16912 0.691418L1.23958 0.902723L1.4623 0.904449C1.49112 0.904682 1.50311 0.941471 1.4799 0.958604L1.30074 1.09092L1.36791 1.30329C1.37661 1.33078 1.34533 1.35352 1.32182 1.33675L1.1406 1.20723L0.959376 1.33675C0.935935 1.35356 0.904624 1.33078 0.91329 1.30329L0.98046 1.09092L0.801296 0.958604C0.778121 0.941471 0.79004 0.904682 0.818894 0.904449L1.04162 0.902723L1.11208 0.691418C1.12131 0.664059 1.16002 0.664059 1.16912 0.691418Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.16912 2.45414L1.23958 2.66544L1.4623 2.66717C1.49112 2.6674 1.50311 2.70419 1.4799 2.72132L1.30074 2.85364L1.36791 3.06601C1.37661 3.0935 1.34533 3.11624 1.32182 3.09947L1.1406 2.96995L0.959376 3.09947C0.935935 3.11627 0.904624 3.0935 0.91329 3.06601L0.98046 2.85364L0.801296 2.72132C0.778121 2.70419 0.79004 2.6674 0.818894 2.66717L1.04162 2.66544L1.11208 2.45414C1.12131 2.42675 1.16002 2.42675 1.16912 2.45414Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.16912 4.40919L1.23958 4.6205L1.4623 4.62222C1.49112 4.62246 1.50311 4.65924 1.4799 4.67641L1.30074 4.80873L1.36791 5.02109C1.37661 5.04859 1.34533 5.07133 1.32182 5.05456L1.1406 4.92504L0.959376 5.05456C0.935935 5.07136 0.904624 5.04859 0.91329 5.02109L0.98046 4.80873L0.801296 4.67641C0.778121 4.65928 0.79004 4.62246 0.818894 4.62222L1.04162 4.6205L1.11208 4.40919C1.12131 4.38183 1.16002 4.38183 1.16912 4.40919Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.16912 6.20509L1.23958 6.41639L1.4623 6.41812C1.49112 6.41835 1.50311 6.45514 1.4799 6.47228L1.30074 6.60459L1.36791 6.81696C1.37661 6.84445 1.34533 6.86719 1.32182 6.85043L1.1406 6.7209L0.959376 6.85043C0.935935 6.86723 0.904624 6.84445 0.91329 6.81696L0.98046 6.60459L0.801296 6.47228C0.778121 6.45514 0.79004 6.41835 0.818894 6.41812L1.04162 6.41639L1.11208 6.20509C1.12131 6.17773 1.16002 6.17773 1.16912 6.20509Z"
        fill="#F5F5F5"
      />
      <path
        d="M1.16912 7.83986L1.23958 8.05116L1.4623 8.05289C1.49112 8.05312 1.50311 8.08991 1.4799 8.10704L1.30074 8.23936L1.36791 8.45172C1.37661 8.47922 1.34533 8.50196 1.32182 8.48519L1.1406 8.35567L0.959376 8.48519C0.935935 8.50199 0.904624 8.47922 0.91329 8.45172L0.98046 8.23936L0.801296 8.10704C0.778121 8.08991 0.79004 8.05309 0.818894 8.05289L1.04162 8.05116L1.11208 7.83986C1.12131 7.8125 1.16002 7.8125 1.16912 7.83986Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.02752 1.52589L2.09798 1.7372L2.3207 1.73892C2.34952 1.73915 2.36151 1.77594 2.3383 1.79308L2.15914 1.92539L2.22631 2.13776C2.23501 2.16525 2.20373 2.18799 2.18022 2.17123L1.999 2.0417L1.81777 2.17123C1.79433 2.18803 1.76302 2.16525 1.77169 2.13776L1.83886 1.92539L1.65969 1.79308C1.63652 1.77594 1.64844 1.73915 1.67729 1.73892L1.90002 1.7372L1.97048 1.52589C1.97971 1.49853 2.01842 1.49853 2.02752 1.52589Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.02752 3.48097L2.09798 3.69227L2.3207 3.694C2.34952 3.69423 2.36151 3.73102 2.3383 3.74815L2.15914 3.88047L2.22631 4.09283C2.23501 4.12033 2.20373 4.14307 2.18022 4.1263L1.999 3.99678L1.81777 4.1263C1.79433 4.1431 1.76302 4.12033 1.77169 4.09283L1.83886 3.88047L1.65969 3.74815C1.63652 3.73102 1.64844 3.69423 1.67729 3.694L1.90002 3.69227L1.97048 3.48097C1.97971 3.45361 2.01842 3.45361 2.02752 3.48097Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.02752 5.33837L2.09798 5.54967L2.3207 5.5514C2.34952 5.55163 2.36151 5.58842 2.3383 5.60555L2.15914 5.73787L2.22631 5.95023C2.23501 5.97773 2.20373 6.00047 2.18022 5.9837L1.999 5.85418L1.81777 5.9837C1.79433 6.0005 1.76302 5.97773 1.77169 5.95023L1.83886 5.73787L1.65969 5.60555C1.63652 5.58842 1.64844 5.55163 1.67729 5.5514L1.90002 5.54967L1.97048 5.33837C1.97971 5.31104 2.01842 5.31104 2.02752 5.33837Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.02752 7.00638L2.09798 7.21769L2.3207 7.21942C2.34952 7.21965 2.36151 7.25644 2.3383 7.27357L2.15914 7.40589L2.22631 7.61825C2.23501 7.64574 2.20373 7.66849 2.18022 7.65172L1.999 7.5222L1.81777 7.65172C1.79433 7.66852 1.76302 7.64574 1.77169 7.61825L1.83886 7.40589L1.65969 7.27357C1.63652 7.25644 1.64844 7.21965 1.67729 7.21942L1.90002 7.21769L1.97048 7.00638C1.97971 6.97899 2.01842 6.97899 2.02752 7.00638Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.88494 0.691418L2.9554 0.902723L3.17812 0.904449C3.20695 0.904682 3.21893 0.941471 3.19572 0.958604L3.01656 1.09092L3.08373 1.30329C3.09243 1.33078 3.06115 1.35352 3.03764 1.33675L2.85642 1.20723L2.6752 1.33675C2.65176 1.35356 2.62044 1.33078 2.62911 1.30329L2.69628 1.09092L2.51712 0.958604C2.49394 0.941471 2.50586 0.904682 2.53471 0.904449L2.75744 0.902723L2.8279 0.691418C2.83713 0.664059 2.87581 0.664059 2.88494 0.691418Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.88494 2.45414L2.9554 2.66544L3.17812 2.66717C3.20695 2.6674 3.21893 2.70419 3.19572 2.72132L3.01656 2.85364L3.08373 3.06601C3.09243 3.0935 3.06115 3.11624 3.03764 3.09947L2.85642 2.96995L2.6752 3.09947C2.65176 3.11627 2.62044 3.0935 2.62911 3.06601L2.69628 2.85364L2.51712 2.72132C2.49394 2.70419 2.50586 2.6674 2.53471 2.66717L2.75744 2.66544L2.8279 2.45414C2.83713 2.42675 2.87581 2.42675 2.88494 2.45414Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.88494 4.40919L2.9554 4.6205L3.17812 4.62222C3.20695 4.62246 3.21893 4.65924 3.19572 4.67641L3.01656 4.80873L3.08373 5.02109C3.09243 5.04859 3.06115 5.07133 3.03764 5.05456L2.85642 4.92504L2.6752 5.05456C2.65176 5.07136 2.62044 5.04859 2.62911 5.02109L2.69628 4.80873L2.51712 4.67641C2.49394 4.65928 2.50586 4.62246 2.53471 4.62222L2.75744 4.6205L2.8279 4.40919C2.83713 4.38183 2.87581 4.38183 2.88494 4.40919Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.88487 6.20509L2.95533 6.4164L3.17806 6.41813C3.20688 6.41836 3.21886 6.45515 3.19566 6.47228L3.01649 6.60459L3.08366 6.81696C3.09236 6.84445 3.06108 6.8672 3.03758 6.85043L2.85642 6.72087L2.6752 6.8504C2.65176 6.8672 2.62044 6.84442 2.62911 6.81693L2.69628 6.60456L2.51712 6.47225C2.49394 6.45511 2.50586 6.41832 2.53471 6.41809L2.75744 6.41637L2.8279 6.20506C2.83706 6.17773 2.87574 6.17773 2.88487 6.20509Z"
        fill="#F5F5F5"
      />
      <path
        d="M2.88494 7.83986L2.9554 8.05116L3.17812 8.05289C3.20695 8.05312 3.21893 8.08991 3.19572 8.10704L3.01656 8.23936L3.08373 8.45172C3.09243 8.47922 3.06115 8.50196 3.03764 8.48519L2.85642 8.35567L2.6752 8.48519C2.65176 8.50199 2.62044 8.47922 2.62911 8.45172L2.69628 8.23936L2.51712 8.10704C2.49394 8.08991 2.50586 8.05309 2.53471 8.05289L2.75744 8.05116L2.8279 7.83986C2.83713 7.8125 2.87581 7.8125 2.88494 7.83986Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.74334 1.52589L3.8138 1.7372L4.03652 1.73892C4.06534 1.73915 4.07733 1.77594 4.05412 1.79308L3.87496 1.92539L3.94213 2.13776C3.95083 2.16525 3.91955 2.18799 3.89604 2.17123L3.71482 2.0417L3.5336 2.17123C3.51015 2.18803 3.47884 2.16525 3.48751 2.13776L3.55468 1.92539L3.37551 1.79308C3.35234 1.77594 3.36426 1.73915 3.39311 1.73892L3.61594 1.7372L3.6864 1.52589C3.69553 1.49853 3.73421 1.49853 3.74334 1.52589Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.74334 3.48097L3.8138 3.69227L4.03652 3.694C4.06534 3.69423 4.07733 3.73102 4.05412 3.74815L3.87496 3.88047L3.94213 4.09283C3.95083 4.12033 3.91955 4.14307 3.89604 4.1263L3.71482 3.99678L3.5336 4.1263C3.51015 4.1431 3.47884 4.12033 3.48751 4.09283L3.55468 3.88047L3.37551 3.74815C3.35234 3.73102 3.36426 3.69423 3.39311 3.694L3.61584 3.69227L3.6863 3.48097C3.69553 3.45361 3.73421 3.45361 3.74334 3.48097Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.74334 5.33837L3.8138 5.54967L4.03652 5.5514C4.06534 5.55163 4.07733 5.58842 4.05412 5.60555L3.87496 5.73787L3.94213 5.95023C3.95083 5.97773 3.91955 6.00047 3.89604 5.9837L3.71482 5.85418L3.5336 5.9837C3.51015 6.0005 3.47884 5.97773 3.48751 5.95023L3.55468 5.73787L3.37551 5.60555C3.35234 5.58842 3.36426 5.55163 3.39311 5.5514L3.61584 5.54967L3.6863 5.33837C3.69553 5.31104 3.73421 5.31104 3.74334 5.33837Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.74334 7.00638L3.8138 7.21769L4.03652 7.21942C4.06534 7.21965 4.07733 7.25644 4.05412 7.27357L3.87496 7.40589L3.94213 7.61825C3.95083 7.64574 3.91955 7.66849 3.89604 7.65172L3.71482 7.5222L3.5336 7.65172C3.51015 7.66852 3.47884 7.64574 3.48751 7.61825L3.55468 7.40589L3.37551 7.27357C3.35234 7.25644 3.36426 7.21965 3.39311 7.21942L3.61584 7.21769L3.6863 7.00638C3.69553 6.97899 3.73421 6.97899 3.74334 7.00638Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.60076 0.691418L4.67122 0.902723L4.89395 0.904449C4.92277 0.904682 4.93475 0.941471 4.91154 0.958604L4.73238 1.09092L4.79955 1.30329C4.80825 1.33078 4.77697 1.35352 4.75346 1.33675L4.57224 1.20723L4.39102 1.33675C4.36758 1.35356 4.33627 1.33078 4.34493 1.30329L4.4121 1.09092L4.23294 0.958604C4.20976 0.941471 4.22168 0.904682 4.25053 0.904449L4.47326 0.902723L4.54372 0.691418C4.55295 0.664059 4.59163 0.664059 4.60076 0.691418Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.60076 2.45414L4.67122 2.66544L4.89395 2.66717C4.92277 2.6674 4.93475 2.70419 4.91154 2.72132L4.73238 2.85364L4.79955 3.06601C4.80825 3.0935 4.77697 3.11624 4.75346 3.09947L4.57224 2.96995L4.39102 3.09947C4.36758 3.11627 4.33627 3.0935 4.34493 3.06601L4.4121 2.85364L4.23294 2.72132C4.20976 2.70419 4.22168 2.6674 4.25053 2.66717L4.47326 2.66544L4.54372 2.45414C4.55295 2.42675 4.59163 2.42675 4.60076 2.45414Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.60076 4.40919L4.67122 4.6205L4.89395 4.62222C4.92277 4.62246 4.93475 4.65924 4.91154 4.67641L4.73238 4.80873L4.79955 5.02109C4.80825 5.04859 4.77697 5.07133 4.75346 5.05456L4.57224 4.92504L4.39102 5.05456C4.36758 5.07136 4.33627 5.04859 4.34493 5.02109L4.4121 4.80873L4.23294 4.67641C4.20976 4.65928 4.22168 4.62246 4.25053 4.62222L4.47326 4.6205L4.54372 4.40919C4.55295 4.38183 4.59163 4.38183 4.60076 4.40919Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.60076 6.20509L4.67122 6.41639L4.89395 6.41812C4.92277 6.41835 4.93475 6.45514 4.91154 6.47228L4.73238 6.60459L4.79955 6.81696C4.80825 6.84445 4.77697 6.86719 4.75346 6.85043L4.57224 6.7209L4.39102 6.85043C4.36758 6.86723 4.33627 6.84445 4.34493 6.81696L4.4121 6.60459L4.23294 6.47228C4.20976 6.45514 4.22168 6.41835 4.25053 6.41812L4.47326 6.41639L4.54372 6.20509C4.55295 6.17773 4.59163 6.17773 4.60076 6.20509Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.60076 7.83986L4.67122 8.05116L4.89395 8.05289C4.92277 8.05312 4.93475 8.08991 4.91154 8.10704L4.73238 8.23936L4.79955 8.45172C4.80825 8.47922 4.77697 8.50196 4.75346 8.48519L4.57224 8.35567L4.39102 8.48519C4.36758 8.50199 4.33627 8.47922 4.34493 8.45172L4.4121 8.23936L4.23294 8.10704C4.20976 8.08991 4.22168 8.05309 4.25053 8.05289L4.47326 8.05116L4.54372 7.83986C4.55295 7.8125 4.59163 7.8125 4.60076 7.83986Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.45916 1.52589L5.52962 1.7372L5.75234 1.73892C5.78116 1.73915 5.79315 1.77594 5.76994 1.79308L5.59078 1.92539L5.65795 2.13776C5.66665 2.16525 5.63537 2.18799 5.61186 2.17123L5.43064 2.0417L5.24942 2.17123C5.22598 2.18803 5.19466 2.16525 5.20333 2.13776L5.2705 1.92539L5.09134 1.79308C5.06816 1.77594 5.08008 1.73915 5.1089 1.73892L5.33163 1.7372L5.40208 1.52589C5.41135 1.49853 5.45003 1.49853 5.45916 1.52589Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.45916 3.48097L5.52962 3.69227L5.75234 3.694C5.78116 3.69423 5.79315 3.73102 5.76994 3.74815L5.59078 3.88047L5.65795 4.09283C5.66665 4.12033 5.63537 4.14307 5.61186 4.1263L5.43064 3.99678L5.24942 4.1263C5.22598 4.1431 5.19466 4.12033 5.20333 4.09283L5.2705 3.88047L5.09134 3.74815C5.06816 3.73102 5.08008 3.69423 5.1089 3.694L5.33163 3.69227L5.40208 3.48097C5.41135 3.45361 5.45003 3.45361 5.45916 3.48097Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.45916 5.33837L5.52962 5.54967L5.75234 5.5514C5.78116 5.55163 5.79315 5.58842 5.76994 5.60555L5.59078 5.73787L5.65795 5.95023C5.66665 5.97773 5.63537 6.00047 5.61186 5.9837L5.43064 5.85418L5.24942 5.9837C5.22598 6.0005 5.19466 5.97773 5.20333 5.95023L5.2705 5.73787L5.09134 5.60555C5.06816 5.58842 5.08008 5.55163 5.1089 5.5514L5.33163 5.54967L5.40208 5.33837C5.41135 5.31104 5.45003 5.31104 5.45916 5.33837Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.45916 7.00638L5.52962 7.21769L5.75234 7.21942C5.78116 7.21965 5.79315 7.25644 5.76994 7.27357L5.59078 7.40589L5.65795 7.61825C5.66665 7.64574 5.63537 7.66849 5.61186 7.65172L5.43064 7.5222L5.24942 7.65172C5.22598 7.66852 5.19466 7.64574 5.20333 7.61825L5.2705 7.40589L5.09134 7.27357C5.06816 7.25644 5.08008 7.21965 5.1089 7.21942L5.33163 7.21769L5.40208 7.00638C5.41135 6.97899 5.45003 6.97899 5.45916 7.00638Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.31658 0.691418L6.38704 0.902723L6.60976 0.904449C6.63858 0.904682 6.65057 0.941471 6.62736 0.958604L6.4482 1.09092L6.51537 1.30329C6.52407 1.33078 6.49279 1.35352 6.46928 1.33675L6.28806 1.20723L6.10684 1.33675C6.0834 1.35356 6.05209 1.33078 6.06075 1.30329L6.12792 1.09092L5.94876 0.958604C5.92558 0.941471 5.9375 0.904682 5.96635 0.904449L6.18908 0.902723L6.25954 0.691418C6.26877 0.664059 6.30745 0.664059 6.31658 0.691418Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.31658 2.45414L6.38704 2.66544L6.60976 2.66717C6.63858 2.6674 6.65057 2.70419 6.62736 2.72132L6.4482 2.85364L6.51537 3.06601C6.52407 3.0935 6.49279 3.11624 6.46928 3.09947L6.28806 2.96995L6.10684 3.09947C6.0834 3.11627 6.05209 3.0935 6.06075 3.06601L6.12792 2.85364L5.94876 2.72132C5.92558 2.70419 5.9375 2.6674 5.96635 2.66717L6.18908 2.66544L6.25954 2.45414C6.26877 2.42675 6.30745 2.42675 6.31658 2.45414Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.31658 4.40919L6.38704 4.6205L6.60976 4.62222C6.63858 4.62246 6.65057 4.65924 6.62736 4.67641L6.4482 4.80873L6.51537 5.02109C6.52407 5.04859 6.49279 5.07133 6.46928 5.05456L6.28806 4.92504L6.10684 5.05456C6.0834 5.07136 6.05209 5.04859 6.06075 5.02109L6.12792 4.80873L5.94876 4.67641C5.92558 4.65928 5.9375 4.62246 5.96635 4.62222L6.18908 4.6205L6.25954 4.40919C6.26877 4.38183 6.30745 4.38183 6.31658 4.40919Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.31658 6.20509L6.38704 6.41639L6.60976 6.41812C6.63858 6.41835 6.65057 6.45514 6.62736 6.47228L6.4482 6.60459L6.51537 6.81696C6.52407 6.84445 6.49279 6.86719 6.46928 6.85043L6.28806 6.7209L6.10684 6.85043C6.0834 6.86723 6.05209 6.84445 6.06075 6.81696L6.12792 6.60459L5.94876 6.47228C5.92558 6.45514 5.9375 6.41835 5.96635 6.41812L6.18908 6.41639L6.25954 6.20509C6.26877 6.17773 6.30745 6.17773 6.31658 6.20509Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.31658 7.83986L6.38704 8.05116L6.60976 8.05289C6.63858 8.05312 6.65057 8.08991 6.62736 8.10704L6.4482 8.23936L6.51537 8.45172C6.52407 8.47922 6.49279 8.50196 6.46928 8.48519L6.28806 8.35567L6.10684 8.48519C6.0834 8.50199 6.05209 8.47922 6.06075 8.45172L6.12792 8.23936L5.94876 8.10704C5.92558 8.08991 5.9375 8.05309 5.96635 8.05289L6.18908 8.05116L6.25954 7.83986C6.26877 7.8125 6.30745 7.8125 6.31658 7.83986Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.17498 1.52589L7.24544 1.7372L7.46816 1.73892C7.49698 1.73915 7.50897 1.77594 7.48576 1.79308L7.3066 1.92539L7.37377 2.13776C7.38247 2.16525 7.35119 2.18799 7.32768 2.17123L7.14646 2.0417L6.96524 2.17123C6.94179 2.18803 6.91048 2.16525 6.91915 2.13776L6.98632 1.92539L6.80716 1.79308C6.78398 1.77594 6.7959 1.73915 6.82475 1.73892L7.04748 1.7372L7.11794 1.52589C7.12713 1.49853 7.16585 1.49853 7.17498 1.52589Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.17498 3.48097L7.24544 3.69227L7.46816 3.694C7.49698 3.69423 7.50897 3.73102 7.48576 3.74815L7.3066 3.88047L7.37377 4.09283C7.38247 4.12033 7.35119 4.14307 7.32768 4.1263L7.14646 3.99678L6.96524 4.1263C6.94179 4.1431 6.91048 4.12033 6.91915 4.09283L6.98632 3.88047L6.80716 3.74815C6.78398 3.73102 6.7959 3.69423 6.82475 3.694L7.04748 3.69227L7.11794 3.48097C7.12713 3.45361 7.16585 3.45361 7.17498 3.48097Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.17498 5.33837L7.24544 5.54967L7.46816 5.5514C7.49698 5.55163 7.50897 5.58842 7.48576 5.60555L7.3066 5.73787L7.37377 5.95023C7.38247 5.97773 7.35119 6.00047 7.32768 5.9837L7.14646 5.85418L6.96524 5.9837C6.94179 6.0005 6.91048 5.97773 6.91915 5.95023L6.98632 5.73787L6.80716 5.60555C6.78398 5.58842 6.7959 5.55163 6.82475 5.5514L7.04748 5.54967L7.11794 5.33837C7.12713 5.31104 7.16585 5.31104 7.17498 5.33837Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.17498 7.00638L7.24544 7.21769L7.46816 7.21942C7.49698 7.21965 7.50897 7.25644 7.48576 7.27357L7.3066 7.40589L7.37377 7.61825C7.38247 7.64574 7.35119 7.66849 7.32768 7.65172L7.14646 7.5222L6.96524 7.65172C6.94179 7.66852 6.91048 7.64574 6.91915 7.61825L6.98632 7.40589L6.80716 7.27357C6.78398 7.25644 6.7959 7.21965 6.82475 7.21942L7.04748 7.21769L7.11794 7.00638C7.12713 6.97899 7.16585 6.97899 7.17498 7.00638Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.0324 0.691418L8.10286 0.902723L8.32559 0.904449C8.35441 0.904682 8.36639 0.941471 8.34318 0.958604L8.16402 1.09092L8.23119 1.30329C8.23989 1.33078 8.20861 1.35352 8.1851 1.33675L8.00388 1.20723L7.82266 1.33675C7.79922 1.35356 7.76791 1.33078 7.77657 1.30329L7.84374 1.09092L7.66458 0.958604C7.6414 0.941471 7.65332 0.904682 7.68218 0.904449L7.9049 0.902723L7.97536 0.691418C7.98456 0.664059 8.02327 0.664059 8.0324 0.691418Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.0324 2.45414L8.10286 2.66544L8.32559 2.66717C8.35441 2.6674 8.36639 2.70419 8.34318 2.72132L8.16402 2.85364L8.23119 3.06601C8.23989 3.0935 8.20861 3.11624 8.1851 3.09947L8.00388 2.96995L7.82266 3.09947C7.79922 3.11627 7.76791 3.0935 7.77657 3.06601L7.84374 2.85364L7.66458 2.72132C7.6414 2.70419 7.65332 2.6674 7.68218 2.66717L7.9049 2.66544L7.97536 2.45414C7.98456 2.42675 8.02327 2.42675 8.0324 2.45414Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.0324 4.40919L8.10286 4.6205L8.32559 4.62222C8.35441 4.62246 8.36639 4.65924 8.34318 4.67641L8.16402 4.80873L8.23119 5.02109C8.23989 5.04859 8.20861 5.07133 8.1851 5.05456L8.00388 4.92504L7.82266 5.05456C7.79922 5.07136 7.76791 5.04859 7.77657 5.02109L7.84374 4.80873L7.66458 4.67641C7.6414 4.65928 7.65332 4.62246 7.68218 4.62222L7.9049 4.6205L7.97536 4.40919C7.98456 4.38183 8.02327 4.38183 8.0324 4.40919Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.0324 6.20509L8.10286 6.41639L8.32559 6.41812C8.35441 6.41835 8.36639 6.45514 8.34318 6.47228L8.16402 6.60459L8.23119 6.81696C8.23989 6.84445 8.20861 6.86719 8.1851 6.85043L8.00388 6.7209L7.82266 6.85043C7.79922 6.86723 7.76791 6.84445 7.77657 6.81696L7.84374 6.60459L7.66458 6.47228C7.6414 6.45514 7.65332 6.41835 7.68218 6.41812L7.9049 6.41639L7.97536 6.20509C7.98456 6.17773 8.02327 6.17773 8.0324 6.20509Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.0324 7.83986L8.10286 8.05116L8.32559 8.05289C8.35441 8.05312 8.36639 8.08991 8.34318 8.10704L8.16402 8.23936L8.23119 8.45172C8.23989 8.47922 8.20861 8.50196 8.1851 8.48519L8.00388 8.35567L7.82266 8.48519C7.79922 8.50199 7.76791 8.47922 7.77657 8.45172L7.84374 8.23936L7.66458 8.10704C7.6414 8.08991 7.65332 8.05309 7.68218 8.05289L7.9049 8.05116L7.97536 7.83986C7.98456 7.8125 8.02327 7.8125 8.0324 7.83986Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
