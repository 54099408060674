import React from "react";

import models from "models";

import { Button } from "./Button";
import { CategoryCard } from "./CategoryCard";
import { FilterCard, FilterCardProps } from "./FilterCard";
import { Flex } from "./Flex";
import { Grid } from "./Grid";
import { Icon } from "./Icon";
import { WhiteSpace } from "./WhiteSpace";
import { DebouncedInput } from "./DebouncedInput";

export interface ProductFiltersProps {
  onClose: () => void;

  search: string;
  onSearchChange: React.Dispatch<string>;

  categories: models.Category[];
  activeCategories: number[];
  onCategoryClick: React.Dispatch<number>;

  activeRedeemTypes: models.ProductReedemType[];
  onRedeemTypeClick: React.Dispatch<models.ProductReedemType>;

  activePriceRanges: number[];
  onPriceRangeClick: React.Dispatch<number>;

  onFiltersClear: () => void;
}

export const ProductFilters: React.FC<ProductFiltersProps> = React.memo(
  ({
    search,
    onSearchChange,
    categories,
    onClose,
    activeCategories,
    onCategoryClick,
    activeRedeemTypes,
    onRedeemTypeClick,
    activePriceRanges,
    onPriceRangeClick,
    onFiltersClear,
  }) => {
    const getReedemTypeCardProps = (type: models.ProductReedemType): FilterCardProps => ({
      active: activeRedeemTypes.includes(type),
      onClick: () => onRedeemTypeClick(type),
    });
    const getPriceRangeCardProps = (range: number): FilterCardProps => ({
      active: activePriceRanges.includes(range),
      onClick: () => onPriceRangeClick(range),
    });

    const areAnyFilters = !!(search || activeCategories.length || activeRedeemTypes.length || activePriceRanges.length);

    return (
      <>
        <Flex gap="0.25rem" align="center">
          <Button
            light
            color="secondary"
            icon={<Icon type="back" />}
            auto
            onClick={onClose}
            style={{ marginLeft: "-1rem" }}
          />
          <h2 className="flex-1">Filters</h2>
          {areAnyFilters && (
            <Button
              subtle
              color="secondary"
              icon={<Icon type="cross" />}
              iconRight
              auto
              onClick={onFiltersClear}
              rounded
            >
              Clear filters
            </Button>
          )}
        </Flex>
        <WhiteSpace v="2rem" />

        <DebouncedInput value={search} onChange={onSearchChange} prefix={<Icon type="search" />} placeholder="Search" />
        <WhiteSpace v="3rem" />

        <h3>Category</h3>
        <WhiteSpace v="0.5rem" />

        <Grid cols="1fr 1fr" gap="1rem">
          {categories.map((category, i) => (
            <CategoryCard
              active={activeCategories.includes(category.id || 0)}
              onClick={() => onCategoryClick(category.id || 0)}
              key={i}
              data={category}
            />
          ))}
        </Grid>

        <WhiteSpace v="3rem" />

        <h3>Redeem</h3>
        <WhiteSpace v="0.5rem" />
        <Grid cols="1fr 1fr" gap="1rem">
          <FilterCard
            {...getReedemTypeCardProps(models.ProductReedemType.store)}
            align="left"
            icon={<Icon type="store" />}
          >
            In store
          </FilterCard>
          <FilterCard
            {...getReedemTypeCardProps(models.ProductReedemType.delivery)}
            align="left"
            icon={<Icon type="delivery" />}
          >
            Delivery
          </FilterCard>
        </Grid>

        <WhiteSpace v="2rem" />
        <h3>Price range</h3>
        <WhiteSpace v="0.5rem" />
        <Grid cols="1fr 1fr 1fr" gap="1rem">
          <FilterCard {...getPriceRangeCardProps(1)}>£</FilterCard>
          <FilterCard {...getPriceRangeCardProps(2)}>££</FilterCard>
          <FilterCard {...getPriceRangeCardProps(3)}>£££</FilterCard>
          {/* <FilterCard {...getPriceRangeCardProps(4)}>£££</FilterCard> */}
        </Grid>
      </>
    );
  }
);
