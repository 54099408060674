import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Modal, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, MutationConfirmModal, QueryTable, Text, WhiteSpace, Button, Icon } from "components";
import { capitalized, notifyErrors } from "utils";
import { useModalState, usePagination } from "hooks";
import models from "models";
import { atoms } from "atoms";

import { groupsTableColumns } from "features/users/config";
import { usePageTemplate } from "features/users/hooks";

import { AddGroupModal, EditGroupModal } from ".";

export interface GroupsModalProps extends ModalProps {}

export const GroupsModal = ({ ...props }: GroupsModalProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const addModal = useModalState(atoms.addGroup);
  const editModal = useModalState<models.Group>(atoms.editGroup);
  const deleteModal = useModalState<models.Group>(atoms.deleteGroup);
  const template = usePageTemplate();

  const pagination = usePagination({ defaultPerPage: 5 });

  const queryParams = {
    page: pagination.page,
    per_page: pagination.perPage,
  };

  const query = useQuery(template.querykeys.groups.many(queryParams), () => template.usersApi.groups.get(queryParams));

  const deleteMutation = useMutation(() => template.usersApi.groups.delete(deleteModal.data?.id || 0), {
    onSuccess: () => {
      queryClient.invalidateQueries(template.querykeys.groups.many());
      notify.success({
        title: "",
        description: capitalized(template.groupName.singular) + " deleted successfully",
      });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const tableColumns = React.useMemo(
    () =>
      groupsTableColumns({
        onEditClick: editModal.openWith,
        onDeleteClick: deleteModal.openWith,
        groupName: capitalized(template.groupName.singular),
      }),
    [deleteModal.openWith, editModal.openWith, template.groupName.singular]
  );

  return (
    <>
      <Modal size="small" {...props}>
        <Modal.Content>
          <Flex align="center" justify="space-between">
            <h2>{capitalized(template.groupName.plural)}</h2>
            <Button onClick={addModal.open} icon={<Icon type="plus" />}>
              Add new
            </Button>
          </Flex>
          <WhiteSpace v="1rem" />
          <Text color="gray">
            Add {template.groupName.plural} that the recipients work for here, so you can create cohorts at the same
            company if required
          </Text>

          <WhiteSpace v="2rem" />
          <QueryTable
            perPageChoice={[5, 10, 15]}
            pagination={pagination}
            columns={tableColumns}
            query={query}
            variants={["dense"]}
          />
        </Modal.Content>
      </Modal>
      <AddGroupModal open={addModal.isOpen} onClose={addModal.close} />
      <EditGroupModal open={editModal.isOpen} data={editModal.data} onClose={editModal.close} />
      <MutationConfirmModal open={deleteModal.isOpen} onClose={deleteModal.close} mutation={deleteMutation}>
        <h3>Are you sure to delete {deleteModal.data?.name}?</h3>
      </MutationConfirmModal>
    </>
  );
};
