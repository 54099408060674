import { Form, Modal } from "ebs-design";

import {
  Flex,
  InputFormField,
  InputPhoneFormField,
  WhiteSpace,
  Button,
  Icon,
  QueryContent,
  Image,
  Upload,
} from "components";
import { useModalState } from "hooks";
import { makeBEM } from "utils";
import { atoms } from "atoms";
import models from "models";
import api from "api";

import { useProfileSettings } from "features/settings/hooks";
import { useAuth } from "features/auth/hooks";

const c = makeBEM("settings");

export const AccountForm = () => {
  const { form, submitHandler, query, mutation } = useProfileSettings();
  const auth = useAuth();

  const logoutModal = useModalState(atoms.logout);

  return (
    <Form form={form} onFinish={submitHandler}>
      <QueryContent query={query}>
        <h3>Please complete your account before you send a prsnt!</h3>
        <h3>Enter Your Account Details</h3>
        <WhiteSpace v="2rem" />
        <Form.Field name="photo" label="Photo">
          {(control, _, form) => {
            const value = control.value as models.Attachment | undefined;

            if (!value)
              return (
                <>
                  <Upload
                    accept="image/*"
                    onSuccess={(item) => {
                      form.setFieldsValue({ photo: item?.data });
                    }}
                    name="files"
                    customRequest={(o) =>
                      api.attachments.post(o, {
                        attachmentType: models.AttachmentType.IMAGE,
                      })
                    }
                  >
                    <Button type="button" color="secondary">
                      Upload
                    </Button>
                  </Upload>
                </>
              );
            return (
              <Flex gap="1rem" align="center">
                <Image style={{ maxWidth: "100px" }} src={value.url} />
                <Button
                  type="button"
                  color="secondary"
                  auto
                  light
                  icon={<Icon type="cross" />}
                  onClick={() => form.setFieldsValue({ photo: null })}
                >
                  Remove
                </Button>
              </Flex>
            );
          }}
        </Form.Field>
        <WhiteSpace v="2rem" />
        <div>First name</div>
        <InputFormField name="first_name" label="First name" required />
        <div>Last name</div>
        <InputFormField name="last_name" label="Last name" required />

        <div className={c("responsive-grid-2")}>
          <div>
            <div>Phone</div>
            <InputPhoneFormField name="phone" label="Phone" />
          </div>
          <div>
            <div>Email</div>
            <InputFormField name="email" type="email" label="Email" required />
          </div>
        </div>
        <WhiteSpace v="3rem" />
        <hr />
        <WhiteSpace v="1rem" />
        <Flex justify="space-between" gap="1rem">
          <Button color="danger" icon={<Icon type="logout" />} auto iconRight onClick={logoutModal.open} type="button">
            Log out
          </Button>
          <Button icon={<Icon type="edit" />} loading={mutation.isLoading}>
            Save
          </Button>
        </Flex>
        <Modal open={logoutModal.isOpen} onClose={logoutModal.close}>
          <Modal.Content>
            <Flex align="center" justify="end" wrap gap="2rem">
              <h4 className="flex-1 nowrap">Are you sure you want to log out?</h4>
              <Flex gap="1rem">
                <Button auto color="secondary" bordered onClick={logoutModal.close}>
                  Close
                </Button>
                <Button color="danger" icon={<Icon type="logout" />} auto iconRight onClick={() => auth.logout()}>
                  Log out
                </Button>
              </Flex>
            </Flex>
          </Modal.Content>
        </Modal>
      </QueryContent>
    </Form>
  );
};
