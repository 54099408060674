import models from "models";

import { apiAxios } from "api/axios";

import { products } from "./products";
import { monthlyInvoice } from "./monthlyInvoice";
import { createUsersApi } from "./createUsersApi";

export const companies = {
  patch: async (id: number, company: models.Company) => {
    const { data } = await apiAxios.patch<models.Company>(`/companies/${id}/`, company);
    return data;
  },
  monthlyInvoice,
  products,
  createUsersApi,
};
