import React from "react";

export const Moreactions: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <ellipse cx="16" cy="7.99992" fill={fill} rx="2.66667" ry="2.66667" />
      <ellipse cx="16" cy="23.9999" fill={fill} rx="2.66667" ry="2.66667" />
      <circle cx="16" cy="15.9999" fill={fill} r="2.66667" />
    </svg>
  );
};
