import React from "react";
import { UseMutationResult } from "react-query";

import { Modal, Form, useForm } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Button, Flex, Icon, WhiteSpace } from "components";
import { useModalState, useScreenBreakPoint } from "hooks";
import models from "models";
import { atoms } from "atoms";

import { usePageTemplate } from "features/users/hooks";

import { AddGroupModal, UserFormFields } from ".";

export interface EditUserModalProps extends ModalProps {
  data?: models.UserContact;
  mutation: UseMutationResult<any, any, any>;
}

export const EditUserModal = ({ data, mutation, ...props }: EditUserModalProps) => {
  const [form] = useForm();

  const addGroupModal = useModalState<models.Group>(atoms.addGroup);
  const template = usePageTemplate();

  const [justAddedGroup, setJustAddedGroup] = React.useState<models.Group>();

  React.useEffect(() => {
    form.setFieldsValue({ ...data, group: data?.group?.name, phone: data?.phone?.replace?.("+", "") });
  }, [data, form]);

  const submitHandler = (values: models.UserContact) => {
    mutation.mutate(
      { ...values, phone: values.phone ? "+" + values.phone : undefined },
      { onSuccess: () => form.resetFields() }
    );
  };

  const onTablet = useScreenBreakPoint("tablet");

  return (
    <>
      <Modal size="small" {...props}>
        <Modal.Content>
          <Form form={form} onFinish={submitHandler}>
            <h1>Edit {template.name.singular}</h1>

            <WhiteSpace v="1rem" />
            <hr />
            <WhiteSpace v="2rem" />

            <UserFormFields
              {...{ justAddedGroup }}
              user={data}
              onAddGroupClick={(s) => addGroupModal.openWith({ name: s })}
            />

            <WhiteSpace v="4rem" />
            <hr />
            <WhiteSpace v="1rem" />

            <Flex justify="end" gap="1rem">
              <Button auto={onTablet} bordered color="secondary" onClick={props.onClose} type="button">
                Cancel
              </Button>
              <Button auto={onTablet} loading={mutation.isLoading} icon={<Icon type="edit" />}>
                Save
              </Button>
            </Flex>
          </Form>
        </Modal.Content>
      </Modal>
      <AddGroupModal
        data={addGroupModal.data}
        open={addGroupModal.isOpen}
        onClose={addGroupModal.close}
        onSuccess={(group) => {
          form.setFieldsValue({ group: group?.id });
          setJustAddedGroup(group);
        }}
      />
    </>
  );
};
