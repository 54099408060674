import React from "react";
import { DividerWithText } from "components";

export interface ORDividerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "wrap"> {}

export const ORDivider: React.FC<ORDividerProps> = (_props) => {
  return (
    <DividerWithText>
      <h2>OR</h2>
    </DividerWithText>
  );
};
