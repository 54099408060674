import { NO_AVAILABLE_ERRORS_MESSAGE } from "app-constants";
import { UseNotifyResult } from "types/ebs-design";

import { extractErrorMessages, capitalized } from ".";

export function notifyErrors(notify: UseNotifyResult, error: any) {
  const errorMessages = extractErrorMessages(error);
  if (errorMessages.length)
    errorMessages.forEach((e) =>
      notify.error({
        title: e.title ? capitalized(e.title) : "",
        description: e.content,
      })
    );
  else if (error.message) notify.error({ title: "", description: error.message });
  else notify.error({ title: "", description: NO_AVAILABLE_ERRORS_MESSAGE });
}
