import { useMutation, useQuery, useQueryClient } from "react-query";

import { useNotify, FormInstance } from "ebs-design";

import api, { querykeys } from "api";
import models from "models";
import { notifyErrors } from "utils";

import { useAuth } from "features/auth/hooks";

export function useCompanySettings(
  form: FormInstance
) {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const auth = useAuth();

  const mutation = useMutation(
    (company: models.Company) =>
      api.companies.patch(auth.user?.company?.id || 0, company),
    {
      onError: (error) => notifyErrors(notify, error),
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.loggedUser);
        notify.success({
          title: "",
          description: "Settings updated successfully",
        });
      }
    }
  );

  const setFormValue = (company?: models.Company) => {
    form.setFieldsValue(company);
  };

  const profileQuery = useQuery(querykeys.profile, api.users.profile.get, {
    onSuccess: (u) => setFormValue(u.company),
  });

  const submitHandler = (values: models.Company) => {
    mutation.mutate(
      values,
      {
        onSuccess: setFormValue,
      }
    );
  };

  return {
    profileQuery,
    mutation,
    submitHandler,
  };
}
