import { Switch, Tooltip } from "ebs-design";

import { Flex, Icon, Button, LinkButton } from "components";
import models from "models";
import { TableColumnBuilder } from "utils";

interface Args {
  onActiveSwitch: React.Dispatch<models.AutoGift>;
  onDeleteClick: React.Dispatch<models.AutoGift>;
}

export const autoGiftTableColumns = ({ onActiveSwitch, onDeleteClick }: Args) =>
  new TableColumnBuilder<models.AutoGift>()
    .add("Day", "title", (value) => <b>{value}</b>, { width: "20%" })
    .add(
      "Selected product",
      "",
      (valu_, record) =>
        record.product ? (
          <Tooltip
            inline
            className="d-flex"
            trigger="hover"
            tooltip={<div style={{ maxWidth: 400 }}>{record.product.description}</div>}
            title="Product description"
            delayHide={200}
          >
            <Flex inline align="center" gap="1rem">
              <div>{record.product.title}</div>
              <Icon size="1.2rem" type="info" color="primary" />{" "}
            </Flex>
          </Tooltip>
        ) : (
          <div className="color-gray">Select a product by clicking edit</div>
        ),
      { width: "60%" }
    )
    .add(
      "Edit",
      "",
      (_, record) => (
        <>
          <LinkButton
            to={`/auto-gifts/${record.id}/edit`}
            size="sm"
            color="secondary"
            light
            auto
            icon={<Icon type="edit" size="1.75rem" />}
          />
        </>
      ),
      { onHeaderCell: () => ({ className: "color-gray text-center" }) }
    )
    .add(
      "Active",
      "active",
      (value, record) =>
        record.payment_method && record.product ? (
          <Switch
            // disabled={!(record.payment_method && record.product)}
            checked={!!value}
            onChange={() => onActiveSwitch(record)}
          />
        ) : (
          <Tooltip
            tooltip={
              <Flex gap="1rem">
                <Icon type="info" color="danger" /> Before activating this automated gift
                <br /> select a product by clicking edit
              </Flex>
            }
            trigger="hover"
          >
            <Switch disabled />
          </Tooltip>
        ),
      { onHeaderCell: () => ({ className: "color-gray" }) }
    )
    .add(
      "Delete",
      "id",
      (id, record) => (
        <div className="text-center">
          {record.trigger === models.AutoGiftTrigger.custom ? (
            <Button
              onClick={() => onDeleteClick(record)}
              light
              color="secondary"
              auto
              size="sm"
              icon={<Icon type="cross" color="danger" className="cursor-pointer" size="1.75rem" strokeWidth={4} />}
            />
          ) : (
            <Tooltip
              tooltip={
                <>
                  This is a builtin (required) automated gift
                  <br />
                  You are not able delete it
                </>
              }
            >
              <h3 style={{ padding: "0 1rem" }}>?</h3>
            </Tooltip>
          )}
        </div>
      ),
      { width: "10%", onHeaderCell: () => ({ className: "color-gray" }) }
    )
    .getColumns();
