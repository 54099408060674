import { WhiteSpace } from "components";

export const AuthLinks = () => {
  return (
    <>
      <a href="https://prsnt.co.uk/privacy-policy/">Privacy Policy</a> <WhiteSpace inline h="1em" />
      <a href="https://prsnt.co.uk/terms-conditions/">Terms and conditions</a> <WhiteSpace inline h="1em" />
      <a href="mailto:support@prsnt.co.uk">Contact us</a>
    </>
  );
};
