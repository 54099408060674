import React from "react";
import { makeBEM } from "utils";
import layoutImageBgSrc from "features/auth/assets/layout-bg.png";
import { AuthLinks } from "./AuthLinks";

const c = makeBEM("auth-layout");

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className={c("container")}>
      <div className={c("image")}>
        <img src={layoutImageBgSrc} alt="" />
        <p>Rewards, incentives and gifts for employees or customers.</p>
      </div>
      <div className={c("content")}>
        <div className={c("content-container")}>
          <div className={c("content-body")}>{children}</div>
          <div className={c("content-footer")}>
            <AuthLinks />
          </div>
        </div>
      </div>
    </div>
  );
};
