import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import api, { querykeys } from "api";
import { useModalState, useProductFilters, useScreenBreakPoint } from "hooks";
import { atoms } from "atoms";
import {
  Drawer,
  FilterButton,
  Flex,
  NoFiltersMatchMessage,
  PageContainer,
  ProductCharacteristicChoices,
  ProductFilters,
  ProductListGroup,
  QueryList,
  WhiteSpace,
} from "components";
import models from "models";
import { makeBEM } from "utils";
import React from "react";
import { Modal } from "ebs-design";
import { NextButton } from "features/send-gift/components";
import { useSendGiftInitialState } from "features/send-gift/hooks";
import { createProductPropsGetter } from "features/send-gift/utils";

const bem = makeBEM("catalogue");

export const Catalogue = () => {
  const history = useHistory();
  const [selectedProducts, setSelectedProducts] = React.useState<models.ProductSendConfig[]>([]);
  const [, setInitialState] = useSendGiftInitialState();
  const characteristicsModal = useModalState<models.Product>(atoms.characteristics);
  const productFilters = useModalState(atoms.productFilters);

  const onMobile = useScreenBreakPoint("laptop");

  const query = useQuery(querykeys.products.many(), () => api.companies.products.grouped());

  const { productFilterProps, filteredProducts } = useProductFilters(query.data, productFilters.close);

  const onNext = () => {
    setInitialState({ selectedProducts });
    history.push("/send-gift");
  };

  return (
    <PageContainer padded={false} color="white">
      <WhiteSpace v="1rem" />
      <Flex className={bem("filter-toggle")} justify="end">
        <FilterButton onClick={productFilters.open} />
      </Flex>
      <Drawer open={productFilters.isOpen} onClose={productFilters.close}>
        <ProductFilters {...productFilterProps} />
      </Drawer>
      <WhiteSpace v="1rem" />

      <QueryList
        emptyText={<NoFiltersMatchMessage />}
        query={query}
        getItems={() => filteredProducts}
        renderItems={(items: models.GroupedProductListCategory[]) => (
          <Flex direction="column" gap="3.5rem" align="stretch">
            {items.map((category) => (
              <ProductListGroup
                getProductProps={createProductPropsGetter(
                  selectedProducts,
                  setSelectedProducts,
                  characteristicsModal.openWith
                )}
                data={category}
              />
            ))}
          </Flex>
        )}
      />
      {selectedProducts.length > 0 && <NextButton position={onMobile ? "bottom" : "top"} onClick={onNext} />}
      <Modal size="small" open={characteristicsModal.isOpen} onClose={characteristicsModal.close}>
        <Modal.Content>
          <ProductCharacteristicChoices
            characteristics={characteristicsModal.data?.characteristic_types || []}
            onChoiceClick={(choice) => {
              setSelectedProducts((prev) => [
                ...prev,
                { product: characteristicsModal.data!, product_characteristic_choices: choice },
              ]);
              characteristicsModal.close();
            }}
          />
        </Modal.Content>
      </Modal>
    </PageContainer>
  );
};
