import React from "react";

export const CreditCard: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        height="14"
        width="20"
        fill={fill}
        opacity="0.3"
        rx="2"
        x="2"
        y="5"
      />
      <rect height="3" width="20" fill={fill} x="2" y="8" />
      <rect
        height="2"
        width="4"
        fill={fill}
        opacity="0.3"
        rx="1"
        x="16"
        y="14"
      />
    </svg>
  );
};
