import React from "react";

export const Library: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M5 3C4.44772 3 4 3.44772 4 4V20C4 20.5523 4.44772 21 5 21H6C6.55228 21 7 20.5523 7 20V4C7 3.44772 6.55228 3 6 3H5ZM10 3C9.44772 3 9 3.44772 9 4V20C9 20.5523 9.44772 21 10 21H11C11.5523 21 12 20.5523 12 20V4C12 3.44772 11.5523 3 11 3H10Z"
        fill={fill}
        fillRule="evenodd"
      />
      <rect
        height="18"
        width="3"
        fill={fill}
        opacity="0.3"
        rx="1"
        transform="rotate(-19 13.4771 3.92432)"
        x="13.4771"
        y="3.92432"
      />
    </svg>
  );
};
