import React from "react";

export const Energy: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M15 20.0229C15 20.765 15.9624 21.0564 16.374 20.4389L22.2227 11.666C22.5549 11.1676 22.1976 10.5 21.5986 10.5H17V4.97708C17 4.23503 16.0376 3.94363 15.626 4.56106L9.77735 13.334C9.44507 13.8324 9.80237 14.5 10.4014 14.5H15V20.0229Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        d="M3 7C3 6.17157 3.67157 5.5 4.5 5.5H9.5C10.3284 5.5 11 6.17157 11 7C11 7.82843 10.3284 8.5 9.5 8.5H4.5C3.67157 8.5 3 7.82843 3 7ZM3 19C3 18.1716 3.67157 17.5 4.5 17.5H9.5C10.3284 17.5 11 18.1716 11 19C11 19.8284 10.3284 20.5 9.5 20.5H4.5C3.67157 20.5 3 19.8284 3 19ZM2.5 11.5C1.67157 11.5 1 12.1716 1 13C1 13.8284 1.67157 14.5 2.5 14.5H6.5C7.32843 14.5 8 13.8284 8 13C8 12.1716 7.32843 11.5 6.5 11.5H2.5Z"
        fill={fill}
        fillRule="evenodd"
        opacity="0.3"
      />
    </svg>
  );
};
