import { Redirect, Route, Switch } from "react-router-dom";

import { Loader } from "components";
import { authRoutes, appRoutes } from "routes";

import { useAuth } from "features/auth/hooks";

import { AppLayout } from ".";

export const AppRouter = () => {
  const { isLoading, user } = useAuth();

  return (
    <>
      {isLoading ? (
        <Loader size="lg" spread />
      ) : (
        <Switch>
          {user &&
            authRoutes.map((route, i) => (
              // ? What if route.path is Array ?
              <Redirect key={i} from={route.path instanceof Array ? route.path[0] : route.path} to="/" />
            ))}
          {user ? (
            <Route>
              <AppLayout>
                <Switch>
                  {appRoutes.map((route, i) => (
                    <Route key={i} {...route} />
                  ))}
                </Switch>
              </AppLayout>
            </Route>
          ) : (
            authRoutes.map((route, i) => <Route key={i} {...route} />)
          )}
          <Redirect to="/register" />
        </Switch>
      )}
    </>
  );
};
