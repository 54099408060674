import models from "models";
import { apiAxios } from "./axios";

export const paymentMethods = {
  getList: async () => {
    const { data } = await apiAxios.get<models.PaymentMethod[]>("/payment-methods/");
    return data;
  },
  delete: async (id: string) => {
    await apiAxios.delete(`/payment-methods/${id}/`);
  },
};
