import React from "react";

export const Text: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M5.33337 10.6667H26.6667"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path d="M5.33337 16H26.6667" stroke={stroke} strokeLinecap="round" />
      <path d="M5.33337 21.3333H16" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};
